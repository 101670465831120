const BaseFooter = () => {
  return (
    <footer
      id="sticky-footer"
      className="flex-shrink-0 py-4 bg-white text-dark-50 h-footer"
    >
      <div className="container">
        <div className="d-flex justify-content-center flex-column flex-md-row gap-3 gap-md-5">
          <div className="col-12 d-flex justify-content-center w-auto h-50 order-md-1 order-0 font-14">
            Copyright © 2023 DevInterface SRL. All rights reserved.
          </div>

          <div className="text-center pb-3 pb-sm-0 w-auto order-md-0">
            <span>Made with love by </span>

            <a
              href="https://www.devinterface.com"
              title="DevInterface Digital Agency Verona"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/images/logo-devinterface.svg"
                alt="Logo DevInterface"
              />
            </a>
          </div>

          <div className="text-center pb-3 pb-sm-0 w-auto order-md-2 ">
            <span>Powered by </span>
            <a
              href="https://www.startersaas.com"
              title="The Go / Node + React powered SaaS Template"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/logo-startersaas.svg" alt="Logo StarterSaas" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default BaseFooter;
