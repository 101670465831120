import { isAccountActive } from "libs/utils";
import { Redirect, Route } from "react-router-dom";
import confirmAlert from "libs/confirmAlert";

const PrivateActiveRoute = ({
  user,
  isAuthenticated,
  component: Component,
  layout: Layout,
  container,
  allowedRoles,
  planType,
  ...rest
}) => {
  let authorized =
    isAuthenticated &&
    user &&
    allowedRoles.includes(user.role) &&
    isAccountActive(user.account);

  if (planType && planType.length > 0) {
    if (
      !planType.includes(user.account.planType) &&
      user.account.planType != "enterprise"
    ) {
      confirmAlert.error(
        "Your current plan doesn't include this feature. Please upgrade it."
      );
    }
    authorized = authorized && planType.includes(user.account.planType);
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          // eslint-disable-next-line no-nested-ternary
          !isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/auth/login",
                state: { from: props.location },
              }}
            />
          ) : // eslint-disable-next-line no-nested-ternary
          authorized ? (
            Layout === undefined ? (
              <Component user={user} {...props} />
            ) : (
              <Layout container={container} user={user}>
                <Component user={user} {...props} />
              </Layout>
            )
          ) : (
            <Redirect
              to={{
                pathname: `${
                  user.role === "admin"
                    ? "/plan"
                    : isAccountActive(user.account)
                    ? "/dashboard"
                    : "/user/edit"
                }`,
                state: { from: props.location },
              }}
            />
          )
        );
      }}
    />
  );
};

export default PrivateActiveRoute;
