import {
  CreateProject,
  DeleteProject,
  ProjectsExternalUsers,
  UpdateProject,
  ChangeProjectStatus
} from "api/mutations";
import confirmAlert from "libs/confirmAlert";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BackOfficeProjects, Projects } from "api/queries";
import { useState } from "react";

const useProjectQuery = (user) => {
  const { t } = useTranslation();

  const history = useHistory();

  const queryClient = useQueryClient();

  const [externalprojects, setExternalprojects] = useState();

  // delete Project
  const deleteProjectMutation = useMutation(DeleteProject);

  const deleteProject = async (id) => {
    try {
      const response = await deleteProjectMutation.mutateAsync(id);
      if (response) {
        confirmAlert.success("Project deleted");
      }
      history.push("/projects");
    } catch (error) {
      if (error.response && error.response.data) {
        confirmAlert.error(error.response.data);
        return;
      }
    }
  };

  // Change Project Status
  const changeProjectStatusMutation = useMutation(ChangeProjectStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries(["backofficeProject", user.currentAccount])
    }
  })

  const ChangeStatus = async (id) => {
    try {
      const response = await changeProjectStatusMutation.mutateAsync(id)
    } catch (error) {
      if (error.response && error.response.data) {
        confirmAlert.error(error.response.data);
        return;
      }
    }
  }

  // Update Project
  const updateProjectMutation = useMutation(UpdateProject, {
    onSuccess: () => {
      queryClient.invalidateQueries(["backofficeProject", user.currentAccount]);
    },
  });

  const updateProject = async (data, id) => {
    try {
      const response = await updateProjectMutation.mutateAsync({ id, data });
      if (response) {
        confirmAlert.success("Project updated");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        confirmAlert.error(error.response.data);
        return;
      }
    }
  };

  //Create Project
  const createProjetMutation = useMutation(CreateProject, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        "backofficeProjects",
        user.currentAccount,
      ]);
    },
  });

  const createProject = async (data) => {
    try {
      const response = await createProjetMutation.mutateAsync(data);
      if (response) {
        confirmAlert.success(t("projects.projectCreated"));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        confirmAlert.error(error.response.data);
        return;
      }
    }
  };

  // Get Projects for External Usrs
  const external = user.role === "external";

  const {
    isLoading,
    error,
    data: projectExternal,
  } = useQuery(["projects", user.currentAccount], Projects, {
    enabled: external,
    onSuccess: async (data) => {
      const req = { projects: data.data };
      const resp = await getProjectsExternal(req);
      setExternalprojects(resp);
    },
  });

  const getProjectsForExternalMutation = useMutation(ProjectsExternalUsers);

  const getProjectsExternal = async (data) => {
    try {
      const resp = await getProjectsForExternalMutation.mutateAsync(data);
      return resp;
    } catch (error) {
      if (error.response && error.response.data) {
        confirmAlert.error(error.response.data);
        return;
      }
    }
  };

  // Get projects admin / collaborator users
  const { isLoading: loadingProjects, data: projects } = useQuery(
    ["backofficeProjects", user.currentAccount],
    BackOfficeProjects,
    {
      enabled: !external,
    }
  );

  return {
    deleteProject,
    updateProject,
    createProject,
    projects,
    externalprojects,
    isLoading,
    loadingProjects,
    ChangeStatus,
  };
};

export default useProjectQuery;
