class Account {
	constructor(userData) {
		this.account = userData.account;
		this.accounts = userData.accounts;
		this.createdAt = userData.createdAt;
		this.currentAccount = userData.currentAccount;
		this.email = userData.email;
		this.emailConfirmed = userData.emailConfirmed;
		this.id = userData.id;
		this.language = userData.language;
		this.mobileTokens = userData.mobileTokens;
		this.name = userData.name;
		this.onboardHelp = userData.onboardHelp;
		this.pushRead = userData.pushRead;
		this.pushSent = userData.pushSent;
		this.surname = userData.surname;
		this.updatedAt = userData.updatedAt;

		userData.accounts.forEach((account) => {
			if (account.accountId === userData.currentAccount) {
				this.role = account.role;
				this.notificationsEnabled = account.notificationsEnabled;
				this.accountName = account.accountName;
				this.owner = account.accountOwner;
				this.image = account.image;
				this.active = account.active;
			}
		});
	}

	static fromResponse = (userData) => {
		return new Account(userData);
	};
}

export default Account;
