import BaseFooter from "app/components/layout/BaseFooter";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import "../../../css/authLayout/auth.css";
import { ENTERPRISE_IMAGE } from "config";
import { ENTERPRISE } from "config";
import { ENTERPRISE_NAME } from "config";

const AuthLayout = ({ children }) => {
  return (
    <div className="d-flex flex-column" id="h100">
      <div id="page-content" className="auth-content">
        <Container>
          <Card className="card-auth mx-auto my-5 shadow-box p-4">
            <Row>
              <Col lg={5} xl={5}>
                <div className="text-center">
                  <Image
                    rounded="true"
                    src="/images/copertina.svg"
                    alt="EveryLog Subscription"
                    className="img-responsive img-none"
                  />
                </div>
              </Col>
              <Col lg={7} md={12} xl={6}>
                <div className="card-body text-center">
                  <Image
                    className="logo m-b-40 img-responsive col-sm-w-50 d-flex mx-auto"
                    src={`${
                      ENTERPRISE_IMAGE
                        ? ENTERPRISE_IMAGE
                        : "/images/everylog-logo.svg"
                    }`}
                    alt={`${
                      ENTERPRISE ? ENTERPRISE_NAME + " Logo" : "EveryLog Logo"
                    }`}
                  />
                  {children}
                </div>
              </Col>
            </Row>
          </Card>
        </Container>
      </div>
      <BaseFooter />
    </div>
  );
};

export default AuthLayout;
