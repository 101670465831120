import React, { useState, useEffect, useRef } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import confirmAlert from 'libs/confirmAlert';
import { BackOfficeProjects, Channels, Groups, Users } from 'api/queries';
import { useQuery } from 'react-query';
import {
	faXmark,
	faFaceSmile,
	faCode,
	faPaperPlane,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LOG_ENTRY_CREATE_URL } from 'config';
import useOnClickOutside from 'hoc/onClickOutsude';
import Picker from '@emoji-mart/react';
import { activeMenuState } from 'libs/atoms';
import { useRecoilState } from 'recoil';
import Loader from 'app/components/Loader';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import {
	Button as ButtonUI,
	Stack,
	Switch,
	Select,
	InputLabel,
	FormControl,
	Typography,
} from '@mui/material';
import 'quill/dist/quill.bubble.css';

import useUserQuery from 'hoc/useUserQuery';

const schema = yup.object().shape({
	projectId: yup.string().required(),
	title: yup.string().required(),
	summary: yup.string().required(),
	body: yup.string(),
	link: yup.string(),
	push: yup.boolean().default(false),
});

export default function NewLogEntryPage({ user }) {
	const [activeMenu, setActiveMenu] = useRecoilState(activeMenuState);

	useEffect(() => {
		setActiveMenu('sendNotification');
	}, []);

	const { t } = useTranslation();

	const [tagArr, setTagArr] = useState([]);
	const [groupsArr, setGroupsArr] = useState([]);
	const [channelsArr, setChannelsArr] = useState([]);
	const [usersArr, setUsersArr] = useState([]);
	const [rolesArr, setRolesArr] = useState([]);

	const [selectEmoji, setSelectEmoji] = useState('');
	const [displayPicker, setDisplayPicker] = useState(false);

	const [properties, setProperties] = useState({});
	const [propertiesValue, setPropertiesValue] = useState(null);
	const [propertiesKey, setPropertiesKey] = useState(null);
	const [propKeyError, setPropKeyError] = useState(false);
	const [propValueError, setPropValueError] = useState(false);
	const [activeUsers, setActiveUsers] = useState([]);
	const [activeProjects, setActiveProjects] = useState([]);

	const iconRef = useRef();

	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
	} = useForm({ resolver: yupResolver(schema) });

	const { isLoading, error, data } = useQuery(
		['projects', user.currentAccount],
		BackOfficeProjects,
		{
			onSuccess: (data) => {
				data.data.forEach((proj) => {
					if (proj.status) {
						setActiveProjects((projects) => [...projects, proj]);
					}
				});
			},
		}
	);

	const { data: groups } = useQuery(['groups', user.currentAccount], Groups);

	const { data: channels } = useQuery(
		['channels', user.currentAccount],
		Channels
	);

	const { users } = useUserQuery({ user: user });
	const { data: usersData, isLoading: loadingUsers } = users;

	useEffect(() => {
		if (!loadingUsers) {
			const arr = [];
			usersData.data.forEach((element) => {
				if (element.active && element.notificationsEnabled) {
					arr.push(element);
				}
			});
			setActiveUsers(arr);
		}
	}, [loadingUsers]);

	const removeProp = (key) => {
		const copy = { ...properties };
		delete copy[key];
		setProperties(copy);
	};

	const onSubmit = async (data) => {
		const headers = { Authorization: `Bearer ${user.account.apiToken}` };

		if (!user.active) {
			confirmAlert.error('You are not authorized to perform this action');
			return;
		}

		const everylog_url = LOG_ENTRY_CREATE_URL;

		const sendData = {
			projectId: data.projectId,
			title: data.title,
			summary: data.summary,
			body: data.body,
			link: data.link,
			push: data.push,
			...(tagArr.length !== 0 ? { tags: tagArr } : null),
			...(groupsArr.length !== 0 ? { groups: groupsArr } : null),
			...(usersArr.length !== 0 ? { users: usersArr } : null),
			...(rolesArr.length !== 0 ? { roles: rolesArr } : null),
			...(channelsArr.length !== 0 ? { externalChannels: channelsArr } : null),
			...(selectEmoji.length !== 0 ? { icon: selectEmoji } : null),
			...(Object.keys(properties).length !== 0
				? { properties: properties }
				: null),
		};

		try {
			const resp = await axios.post(everylog_url, sendData, {
				headers: headers,
			});
			if (resp.statusText === 'OK') {
				confirmAlert.success(t('notifyPage.notificationSent'));
			}
		} catch (error) {
			if (error.response?.data) {
				confirmAlert.error(error.response.data);
			}
		}
	};

	useOnClickOutside(iconRef, () => {
		setDisplayPicker(false);
	});

	if (isLoading) {
		return <Loader />;
	}

	return (
		<div className='max-width m-0-auto'>
			<h1 className='title-font '>{t('notifyPage.pageTitle')}</h1>
			<div
				className='dashboard-box mt-4 py-4 px-5'
				onClick={() => {}}
				style={{ overflow: 'unset' }}
			>
				<form onSubmit={handleSubmit((data) => onSubmit(data))}>
					<div className='d-flex flex-column flex-md-row w-100-perc gap-4 justify-content-between mb-4'>
						<Controller
							name='projectId'
							control={control}
							render={({ field }) => (
								<FormControl fullWidth>
									<InputLabel id='demo-simple-select-label'>
										ProjectId
									</InputLabel>
									<Select
										{...field}
										required
										labelId='demo-simple-select-label'
										fullWidth
										error={errors.projectId ? true : false}
										label={t('notifyPage.projectId')}
									>
										{activeProjects && activeProjects.length != 0 ? (
											activeProjects.map((project, i) => (
												<MenuItem key={project.value} value={project.projectId}>
													{project.projectId}
												</MenuItem>
											))
										) : (
											<MenuItem>{t('notifyPage.noActiveProjects')}</MenuItem>
										)}
									</Select>
								</FormControl>
							)}
						/>
						<TextField
							id='title'
							name='title'
							label={t('notifyPage.title')}
							fullWidth
							required
							{...register('title')}
							error={errors.title ? true : false}
						/>
					</div>
					<div className='d-flex flex-column flex-md-row w-100-perc gap-4 justify-content-between mb-4'>
						<TextField
							label='Summary'
							multiline
							rows={3}
							required
							variant='outlined'
							className='input-width'
							{...register('summary')}
							error={errors.summary ? true : false}
						/>
						<TextField
							multiline
							rows={3}
							label={t('notifyPage.body')}
							variant='outlined'
							className='input-width'
							{...register('body')}
							// error={errors.body ? true : false}
						/>
					</div>
					<div className='d-flex flex-column flex-md-row w-100-perc gap-4 justify-content-between mb-4'>
						<TextField
							label='Link'
							variant='outlined'
							className='input-width'
							{...register('link')}
							error={errors.link ? true : false}
						/>
						<Autocomplete
							className='input-width'
							multiple
							id='prova-autocomplete'
							options={[]}
							freeSolo
							renderInput={(params) => (
								<TextField {...params} variant='outlined' label='Tag' />
							)}
							onChange={(e, value) => {
								setTagArr(value);
							}}
						/>
					</div>
					<div className='d-flex flex-column flex-md-row w-100-perc gap-4 justify-content-between mb-4'>
						{groups && groups.data && (
							<Autocomplete
								className='w-100-perc'
								multiple
								id='prova-autocomplete'
								options={groups.data.map((group) => group.code)}
								renderInput={(params) => (
									<TextField
										{...params}
										variant='outlined'
										label={t('notifyPage.groups')}
									/>
								)}
								onChange={(e, value) => {
									setGroupsArr(value);
								}}
							/>
						)}
						{channels && channels.data && (
							<Autocomplete
								className='w-100-perc'
								multiple
								options={channels.data.map((channel) => channel.code)}
								renderInput={(params) => (
									<TextField
										{...params}
										variant='outlined'
										label={t('notifyPage.channels')}
									/>
								)}
								onChange={(e, value) => {
									setChannelsArr(value);
								}}
							/>
						)}
					</div>
					<div className='d-flex flex-column flex-md-row w-100-perc gap-4 justify-content-between mb-4'>
						{!loadingUsers && (
							<Autocomplete
								className='w-100-perc'
								multiple
								options={activeUsers.map((user) => user.email)}
								renderInput={(params) => {
									return <TextField {...params} label='Users' />;
								}}
								onChange={(e, value) => {
									setUsersArr(value);
								}}
							/>
						)}
						<Autocomplete
							className='w-100-perc'
							multiple
							options={['admin', 'collaborator', 'external']}
							renderInput={(params) => <TextField {...params} label='Roles' />}
							onChange={(e, value) => {
								setRolesArr(value);
							}}
						/>
					</div>
					<div className='d-flex flex-column flex-md-row w-100-perc gap-4 justify-content-between mb-4'>
						<Stack sx={{ width: '100%' }}>
							<TextField
								fullWidth
								variant='outlined'
								label='Emoji'
								value={selectEmoji}
								onChange={(e) => setSelectEmoji(e.target.value)}
								InputProps={{
									startAdornment: (
										<div
											disabled={displayPicker}
											onClick={(e) => {
												e.preventDefault();
												setDisplayPicker(!displayPicker);
											}}
											className='me-2'
										>
											<FontAwesomeIcon
												icon={faFaceSmile}
												className={` ${
													displayPicker ? 'text-primary' : 'text-secondary'
												} fs-3`}
											/>
										</div>
									),
								}}
								inputProps={{ maxLength: 1 }}
							/>
							<div
								className={`${
									displayPicker ? 'd-inline' : 'd-none'
								} d-flex justify-content-start mt-1`}
								ref={iconRef}
							>
								<Picker
									onEmojiSelect={(data) => {
										setSelectEmoji(data.native);
										setDisplayPicker(false);
									}}
								/>
							</div>
						</Stack>
						<Stack sx={{ width: '100%' }} gap={1}>
							<div className='d-flex gap-2 w-100-perc align-items-center'>
								<TextField
									className='flex-grow-1'
									variant='outlined'
									label={t('channelPage.key')}
									value={propertiesKey}
									onChange={(e) => setPropertiesKey(e.target.value)}
									error={propKeyError}
								/>
								<TextField
									className='flex-grow-1'
									variant='outlined'
									label={t('channelPage.value')}
									value={propertiesValue}
									onChange={(e) => setPropertiesValue(e.target.value)}
									error={propValueError}
								/>
								<ButtonUI
									onClick={() => {
										/* handle error key */
										if (!propertiesKey) {
											setPropKeyError(true);
											setTimeout(() => {
												setPropKeyError(false);
											}, 3000);
										}
										/* handle error value */
										if (!propertiesValue) {
											setPropValueError(true);
											setTimeout(() => {
												setPropValueError(false);
											}, 3000);
										}
										if (propertiesKey && propertiesValue) {
											setProperties({
												...properties,
												[propertiesKey]: propertiesValue,
											});
											setPropertiesKey('');
											setPropertiesValue('');
										}
									}}
									variant='outlined'
								>
									{t('userEdit.add')}
								</ButtonUI>
							</div>
							<div>
								{properties &&
									Object.keys(properties).length != 0 &&
									Object.keys(properties).map((key, i) => (
										<div
											key={i}
											className={`d-none d-lg-flex gap-1 ${
												i === 0 ? 'mt-2' : null
											}`}
										>
											<div className='p-1 send-log-properties mb-1 w-100-perc '>
												{key}
											</div>
											<div className='p-1 send-log-properties mb-1 w-100-perc '>
												{properties[key]}
											</div>
											<div
												onClick={() => removeProp(key)}
												className='d-flex justify-content-center align-items-center text-danger mx-4 mb-1 pointer-hover'
											>
												<FontAwesomeIcon icon={faXmark} />
											</div>
										</div>
									))}
							</div>
							{/* mobile properties list */}
							<div className='d-inline-block d-lg-none mt-2 w-100-perc'>
								{properties &&
									Object.keys(properties).length != 0 &&
									Object.keys(properties).map((key, i) => (
										<div
											key={`prop - ${i}`}
											className='d-flex flex-row flex-column gap-1 flex-md-row justify-content-between mb-3 w-100-perc'
										>
											<div className='p-1 send-log-properties mb-1 w-100-perc overflow-scroll'>
												{key}
											</div>
											<div className='d-flex w-100-perc overflow-auto'>
												<div className='p-1 send-log-properties mb-1 w-100-perc overflow-scroll'>
													{properties[key]}
												</div>
												<div
													onClick={() => removeProp(key)}
													className='d-flex justify-content-center align-items-center text-danger mx-2 mb-1 pointer-hover'
												>
													<FontAwesomeIcon icon={faXmark} />
												</div>
											</div>
										</div>
									))}
							</div>
						</Stack>
					</div>
					<div className='d-flex flex-column flex-md-row w-100-perc gap-4 justify-content-between mb-4'>
						<Stack
							sx={{ width: '100%' }}
							direction={'row'}
							alignItems={'center'}
							gap={2}
							marginLeft={0.3}
						>
							<Typography color={'gray'} fontSize={18}>
								Push:
							</Typography>
							<Controller
								name='push'
								control={control}
								render={({ field }) => (
									<Switch
										defaultValue={false}
										checked={field.value}
										onChange={(e) => field.onChange(e.target.checked)}
									/>
								)}
							/>
						</Stack>
					</div>
					<ButtonUI
						variant='contained'
						type='submit'
						startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
						sx={{ width: '100px' }}
					>
						{t('notifyPage.send')}
					</ButtonUI>
				</form>
				<div className='w-100-perc' style={{ height: '20px' }} />
			</div>
		</div>
	);
}
