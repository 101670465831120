import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import useProjectQuery from './useProjectQuery';
import { useTranslation } from 'react-i18next';
import { ChromePicker } from 'react-color';
import { TextField } from '@mui/material';
import { Button as ButtonMUI } from '@mui/material';

export default function ModalEditProject({
	projectID,
	user,
	show,
	setShow,
	projectData,
}) {
	const [project, setProject] = useState({
		name: projectData.name,
		description: projectData.description,
		color: projectData.color,
	});
	const { t } = useTranslation();

	const { updateProject } = useProjectQuery(user);
	return (
		<Modal
			show={show}
			onHide={() => {
				setProject({
					name: projectData.name,
					description: projectData.description,
					color: projectData.color,
				});
				setShow();
			}}
			centered
		>
			<Form
				onReset={(e) => {
					setProject({
						name: projectData.name,
						description: projectData.description,
						color: projectData.color,
					});
					setShow();
				}}
				onSubmit={(e) => {
					e.preventDefault();
					updateProject(project, projectID);
					setShow();
				}}
			>
				<div className='popup-crea-gruppo p-4'>
					<TextField
						inputProps={{ maxLength: 20 }}
						variant='outlined'
						fullWidth
						required
						value={project.name}
						sx={{ mt: 2 }}
						label={t('projects.name')}
						onChange={(event) => {
							setProject({ ...project, name: event.target.value });
						}}
					/>
					{/* <div>
            <div className="fw-bold mb-2">{t("projects.name")}</div>
            <Form.Control
              maxlength="20"
              onChange={(event) => {
                setProject({ ...project, name: event.target.value });
              }}
              placeholder={t("projects.name")}
              value={project.name}
              required
            />
          </div> */}

					<div>
						<div className='fw-bold mb-2'>{t('projects.color')}</div>
						<div className='d-flex gap-2'>
							<ChromePicker
								onChange={(color) => {
									setProject({ ...project, color: color.hex });
								}}
								color={project.color}
								disableAlpha
							/>
							<div
								className={`d-flex justify-content-center align-items-center ms-5 me-4 text-white `}
								style={{
									backgroundColor: `${project.color}`,
									width: '50px',
									height: '50px',
									borderRadius: '50%',
									fontSize: '20px',
								}}
							>
								{project.name.charAt(0)}
							</div>
						</div>
					</div>
					<TextField
						fullWidth
						variant='outlined'
						label={t('projects.description')}
						inputProps={{ maxLength: 150 }}
						multiline
						rows={3}
						value={project.description}
						onChange={(event) => {
							setProject({
								...project,
								description: event.target.value,
							});
						}}
					/>
					{/* <div>
						<div className='fw-bold mb-2'>{t('projects.description')}</div>
						<Form.Control
							onChange={(event) => {
								setProject({
									...project,
									description: event.target.value,
								});
							}}
							as='textarea'
							rows={3}
							type='text'
							placeholder={t(' projects.description')}
							value={project.description}
						/>
					</div> */}
					<div className='d-flex justify-content-center gap-5'>
						<ButtonMUI color='error' variant='contained' type='reset'>
							{t('createUsersPage.cancel')}
						</ButtonMUI>
						{/* <Button
							type='reset'
							className='group-cancel-button d-flex justify-content-center align-items-center'
						>
							{t('createUsersPage.cancel')}
						</Button> */}
						<ButtonMUI
							type='submit'
							color='primary'
							variant='contained'
							sx={{ width: '100px' }}
						>
							{t('createUsersPage.save')}
						</ButtonMUI>
						{/* <Button
							type='submit'
							className='create-group-button d-flex justify-content-center align-items-center'
						>
							{t('createUsersPage.save')}
						</Button> */}
					</div>
				</div>
			</Form>
		</Modal>
	);
}
