import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import ApplicationRouter from './ApplicationRouter';
import { ThemeProvider } from '@mui/material';
import { theme } from 'libs/theme';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

const App = () => {
	return (
		<RecoilRoot>
			<QueryClientProvider client={queryClient}>
				<BrowserRouter>
					<ThemeProvider theme={theme}>
						<ApplicationRouter />
					</ThemeProvider>
				</BrowserRouter>
			</QueryClientProvider>
		</RecoilRoot>
	);
};

export default App;
