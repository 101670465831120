import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function ModalConfirm({ show, setShow, message, onConfirm }) {
	const { t } = useTranslation();

	return (
		<Modal
			show={show}
			centered
			onHide={() => {
				setShow(false);
			}}
		>
			<div className='delete-popup font-18'>
				<div className='text-center m-3'>{message}</div>
				<div className='d-flex justify-content-center gap-5 mb-3'>
					<div
						onClick={() => {
							onConfirm();
							setShow(false);
						}}
						className='group-cancel-button d-flex justify-content-center align-items-center'
					>
						{t('groupsPage.yes')}
					</div>
					<div
						onClick={() => {
							setShow(false);
						}}
						className='group-cancel-button d-flex justify-content-center align-items-center'
					>
						{t('groupsPage.no')}
					</div>
				</div>
			</div>
		</Modal>
	);
}
