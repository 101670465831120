class UserClass {
	constructor(userData, currentAccountId) {
		this.createdAt = userData.createdAt;
		this.email = userData.email;
		this.id = userData.id;
		this.language = userData.language;
		this.name = userData.name;
		this.surname = userData.surname;

		userData.accounts.forEach((account) => {
			if (account.accountId === currentAccountId) {
				this.role = account.role;
				this.notificationsEnabled = account.notificationsEnabled;
				this.owner = account.accountOwner;
				this.active = account.active;
				// this.projects = account.projects;
			}
		});
	}

	static fromResponse = (userData) => {
		return new UserClass(userData);
	};
}

export default UserClass;
