export default {
	loginPage: {
		access: 'Accedi',
		register: 'Registrati',
		forgotPassword: 'Password dimenticata?',
		didntReceivedActivationEmail: 'Non hai ricevuto la mail di attivazione?',
		confirm: 'Conferma',
		emailPasswordInvalid: 'Email o password non sono valide',
	},
	registerPage: {
		invalidSubdomain: 'Formato sottodominio invalido',
		termsAccepted: 'I termini e condizioni devono essere accettati.',
		confirmEmailSent:
			'Una email con il codice di attivazione è stata inviata al tuo indirizzo. Controlla la posta in arrivo.',
		emailPasswordInvalid: 'Email o password non sono valide',
		subdomain: 'Sottodominio',
		registerConsent:
			"Cliccando il bottone 'Registrati' acconsento ai termini e condizioni di utilizzo e accetto che le mie informazioni e dati saranno usati in conformità alla privacy policy.",
		terms: 'Temini e condizioni',
		privacy: 'Privacy e Policy',
		marketingConsent:
			'EveryLog si impegna a proteggere e rispettare la tua privacy e utilizzerà le tue informazioni personali solo per gestire il tuo account e fornire il servizio da te richiesto. Ogni tanto ci piacerebbe comunque tenerti aggiornato sui nostri prodotti e servizi ed altri contenuti che potrebbero interessarti.',
		alreadyRegistered: 'Già registrato?',
		didntReceivedActivationEmail: 'Non hai ricevuto la mail di attivazione?',
		confirm: 'Conferma',
		register: 'Registrati',
		betaTestingMessage:
			'Siamo ancora nella fase di beta testing, per cui non permettiamo al momento l\'auto registrazione. Puoi però iscriverti alla mailing list <strong><a href="https://www.everylog.io/#form" target="_blank">QUI</a></strong> per poter essere aggiunto al programma di beta testing.',
	},
	forgotPasswordPage: {
		passwordEmailSent:
			'Una email con il codice di reset password è stata inviata al tuo indirizzo. Controlla la posta in arrivo.',
		resetPasswordFailure:
			'Il ripristino della password non è andato a buon fine, sei pregato di riprovare',
		sendCode: 'Invia il codice',
		back: 'Torna alla pagina di login',
		passwordReset: 'Ripristina password',
	},
	resendActivationPage: {
		emailSent:
			'Una email con il codice di attivazione è stata inviata al tuo indirizzo. Controlla la posta in arrivo.',
		invalidEmail: 'Email non valida',
		resendActivationCode: 'Invia il codice',
		back: 'Torna alla pagina di login',
		confirm: 'Conferma',
	},
	resetPasswordPage: {
		passwordUpdated: 'La tua password è stata aggiornata con successo',
		resetPasswordFailure:
			'Il ripristino della password non è andato a buon fine, sei pregato di riprovare',
		changePassword: 'Cambia password',
		updatePassword: 'Aggiorna password',
		back: 'Torna alla pagina di login',
	},
	activateAccountPage: {
		accountActivated: 'Il tuo account è stato attivato',
		activationFailure:
			"L'attivazione non è andata a buon fine, sei pregato di riprovare",
		activateAccount: 'Attiva account',
		activate: 'Attiva',
		back: 'Torna alla pagina di login',
	},
	accountForm: {
		companyName: 'Nome azienda',
		phoneNumber: 'Numero di telefono',
		billingAddress: 'Indirizzo di fatturazione',
		vatNumber: 'P.IVA',
		update: 'Aggiorna dettagli di fatturazione',
		companyCountry: 'Stato',
		selectOne: 'Seleziona',
		pec: 'PEC',
		sdi: 'SDI',
		onlyForItalians: ' solo per aziende italiane',
	},
	addCardPage: {
		creditCard: 'Dati carta di credito',
	},
	editAccountPage: {
		detailsUpdated: 'I dettagli di fatturazione sono stati aggiornati',
		details: 'Dettagli di fatturazione',
	},
	editUserPage: {
		passwordRequired: 'La password è richiesta',
		passwordUpdated: 'La tua password è stata aggiornata con successo',
		changePassword: 'Cambia password',
		insertNewPassword: 'Inserisci una nuova password',
		updatePassword: 'Aggiorna password',
		userData: 'Dati Utente',
		name: 'Nome',
		surname: 'Cognome',
		language: 'Lingua',
		removeAccount: 'Rimuovi Account',
		addAccount: 'Aggiungi Account',
		notifications: 'Notifiche',
		role: 'Ruolo',
		accountRemoved: 'Account Rimosso',
		accountAdded: 'Account Aggiunto',
		accountUpdated: 'Account Aggiornato',
		accounts: 'Account',
		userUpdated: 'Utente Aggiornato',
		accountName: 'Nome Account',
		accountImage: 'Immagine Account',
		uploadImage: 'Carica Immagine',
		selectImage: 'Seleziona Immagine',
		accountNotActive: 'Account non attivo',
		tokenAuth: 'Token',
		getToken: 'Ottieni Token',
		newToken: 'Nuovo Token',
		getNewToken: 'Ottenere Nuovo Token?',
		imageUploaded: 'Immagine Aggiornata',
		projects: 'Progetti',
		addNewAccount: 'Aggiungi Nuovo Account',
		active: 'Attivo',
	},
	stripeCCForm: {
		cardAdded: 'Carta di credito aggiunta con successo',
		cardOwner: 'Titolare carta di credito',
		addCard: 'Aggiungi questa carta',
		addCardFailed:
			'La carta di credito non è stata aggiunta a causa di questi errori:',
	},
	planCard: {
		updateSubscription: "Aggiorna l'abbonamento",
		areYouSure: 'Sei sicuro di aggiornare il piano?',
		yes: 'Si',
		no: 'No',
		planUpdated: 'Piano aggiornato con successo',
		month: '/mese',
		year: '/anno',
		changePlan: 'Cambia piano',
		currentPlan: 'Il tuo piano attuale',
		selectPlan: 'Seleziona questo piano',
		toPay: 'Da pagare',
		plusVat: '+ IVA',
	},
	planPage: {
		selectaPlan: 'Seleziona un piano',
		monthly: 'Piano mensile',
		yearly: 'Piano annuale',
		deactivatedAccountNotice:
			'Il tuo account non è attivo. Sei pregato di sottoscrivere un abbonamento per attivarlo.',
	},
	stripeForm: {
		paymentFailed: 'Il pagamento è fallito a causa di questi errori:',
		paymentCompleted: 'Pagamento completato con successo',
		cardOwner: 'Titolare carta di credito',
		subscribe: 'Abbonati',
	},
	subscribePlanPage: {
		billingDetails: 'Dettagli di fatturazione',
		yourOrder: 'Il tuo ordine',
		plan: 'Piano',
		invoicing: 'Fatturazione',
		price: 'Prezzo',
		monthly: 'Piano mensile',
		yearly: 'Piano annuale',
		creditCard: 'Dati carta di credito',
		plusVat: '+ IVA',
		subscribe: 'Abbonati',
	},
	trialComponent: {
		youAreOnTrial:
			'Stai attualmente utilizzando la versione di prova di EveryLog al piano "{{planType}}".',
		trialEndsAt: 'Il periodo di prova termina il',
		goToPlans: 'Va ai piani',
	},
	dashboardPage: {
		unsubscribe: 'Cancella abbonamento',
		areYouSureToUnsubscribe:
			"Sei sicuro di cancellare l'abbonamento? In ogni caso, l'abbonamento rimarrà attivo fino alla fine del periodo corrente.",
		yes: 'Si',
		no: 'No',
		removeCard: 'Rimuovi la carta',
		areYouSureToRemoveCard: 'Sei sicuro di rimuovere questa carta?',
		makeDefault: 'Rendi principale',
		areYouSureMakeDefault: 'Sei sicuro di rendere questa carta la principale?',
		manageSubscription:
			"In questo pannello puoi gestire l'abbonamento e controllare il tuo storico pagamenti.",
		failedPaymentAt: 'ATTENZIONE! Hai un pagamento fallito il',
		checkYourPayments:
			'Prego controlla i tuoi pagamenti in sospeso nello storico pagamenti o nella tua casella email.',
		subscriptionDeactivateOn:
			'Il tuo abbonamento si disattiverà automaticamente il',
		subscriptionRenewOn: 'Il tuo abbonamento si rinnoverà automaticamente il',
		yourSubscription: 'Il tuo abbonamento',
		plan: 'Piano',
		price: 'Prezzo',
		canceledAt: 'Cancellato il',
		willDeactivateAt: 'Si disattiverà il',
		willRenewOn: 'Si rinnoverà il',
		paymentFailedAt: 'Pagamento fallito il',
		paymentMethods: 'I tuoi metodi di pagamento',
		default: 'principale',
		remove: 'rimuovi',
		addCreditCard: 'Aggiungi carta di credito',
		changePlan: 'Cambia piano',
		deleteSubscription: 'Cancella abbonamento',
		paymentHistory: 'Storico pagamenti',
		paymentId: 'ID pagamento',
		status: 'Stato',
		date: 'Data',
		total: 'Totale',
		paid: 'Pagato',
		toPay: 'Da pagare',
		actions: 'Azioni',
		plusVat: '+ IVA',
		confirmCopy: 'Copiato negli appunti',
		noNotifications: 'Nessuna notifica trovata',
	},
	dashboardPageNew: {
		guide: 'Scopri come integrare facilmente EveryLog nel tuo progetto',
		guideButton: 'Vai alla guida',
		latestNotifications: 'Ultime notifiche',
		project: 'Progetto',
		tags: 'Tags',
		title: 'Titolo',
		summary: 'Riepilogo',
		trialMsg:
			'Stai attualmente utilizzando la versione di prova di EveryLog al piano Premium',
		trialPeriodEndAt: 'Il periodo di prova termina il ',
		planBtn: 'Vai ai piani',
		copyKeyAlert: 'Copiato negli appunti',
		showKey: 'Mostra Api Key',
		hideKey: 'Nascondi Api Key',
		copyKey: 'Copia Api Key',
	},
	privateLayout: {
		billingDetails: 'Dettagli fatturazione',
		editUser: 'Modifica utente',
		logout: 'Logout',
		toggle: 'Attiva',
		apiKey: 'API KEY',
		users: 'Gestisci Utenti',
		webSite: 'Vai al sito web',
		manageUser: 'Dati utente',
		managePayment: 'Dati di fatturazione',
		managePlan: 'Gestisci abbonamento',
		codeApiKey: 'CODICE API KEY',
		usersMenu: 'UTENTI',
		projects: 'PROGETTI',
		groups: 'GRUPPI',
		channels: 'CANALI',
		invitations: 'INVITI',
		notification: 'INVIA NOTIFICA',
	},
	apiKeyComponent: {
		apiKey: 'API KEY',
	},
	indexUsersPage: {
		users: 'Utenti',
		deleteUser: 'Cancella utente',
		areYouSureToDelete: 'Sei sicuro di cancellare questo utente?',
		yes: 'Si',
		no: 'No',
		userCreated: 'Utente creato',
		userUpdated: 'Utente aggiornato',
		name: 'Nome',
		surname: 'Cognome',
		email: 'Email',
		role: 'Ruolo',
		actions: 'Azioni',
		editUser: 'Modifica utente',
		deleteUser: 'Cancella utente',
		addUser: 'Aggiungi utente',
		addGroup: 'Aggiungi/Rimuovi gruppi',
		userDeleted: 'Utente cancellato',
		inviteUser: 'Invita Utente',
	},
	createUsersPage: {
		createUser: 'Crea un nuovo utente',
		userCreated: 'Utente creato',
		name: 'Nome',
		surname: 'Cognome',
		email: 'Email',
		cancel: 'Annulla',
		save: 'Salva',
	},
	updateUsersPage: {
		updatedUser: 'Aggiorna utente',
		userUpdated: 'utente aggiornato',
	},
	usersForm: {
		name: 'Nome',
		surname: 'Cognome',
		email: 'Email',
		language: 'Lingua',
		role: 'Ruolo',
		user: 'Utente',
		admin: 'Amministratore',
		save: 'Salva',
	},
	usersAddGroup: {
		titleWithName: 'Aggiungi/Rimuovi gruppi a ',
		title: 'Aggiungi/Rimuovi gruppo',
		groupName: 'Nome gruppo',
		nrUsers: 'Utenti',
		code: 'Code',
		actions: 'Azioni',
		addGroup: 'Aggiungi gruppo',
		removeGroup: 'Rimuovi gruppo',
	},
	groupsPage: {
		title: 'Gruppi',
		name: 'Nome Gruppo',
		nrUsers: 'Utenti',
		code: 'Code',
		actions: 'Azioni',
		button: 'Crea Gruppo',
		delete: 'Elimina Gruppo',
		searchInput: 'Cerca',
		deleteGroup: 'Cancellare gruppo',
		yes: 'si',
		no: 'no',
		create: 'Crea gruppo',
		noGroup: 'Crea primo gruppo',
		userWithNoGroup: 'Non sei stato inserito in alcun gruppo',
		groupCreated: 'Gruppo creato con successo',
		groupDeleted: 'Gruppo cancellato con successo',
	},
	invitationsPage: {
		invitations: 'Inviti',
		role: 'Ruolo',
		sent: 'Inviato',
		actions: 'Azioni',
		createInvitation: 'Crea Invito',
		send: 'Invia',
		delete: 'Cancella',
		deleteInvitation: 'Cancellare Invito',
		invitationCreated: 'Invito Creato',
		invitationSent: 'Invito Inviato',
		invitationDeleted: 'Invito Cancellato',
		invitationProjects: 'Progetti',
		add: 'Aggiungi',
	},
	createGroup: {
		groupName: 'Nome gruppo',
		code: 'Code',
		cancel: 'Annulla',
		save: 'Salva',
	},
	groupPage: {
		name: 'Nome',
		surname: 'Cognome',
		email: 'Email',
		actions: 'Azioni',
		button: 'Aggiungi Utente',
		delete: 'Elimina Gruppo',
		user: 'Utente',
		save: 'Salva',
		search: 'Cerca',
		changesSaved: 'Modifiche salvate',
		goGroupsPage: 'Vai ai gruppi',
		groupCreated: 'Gruppo creato con successo',
		groupDeleted: 'Gruppo cancellato con successo',
		groupUpdated: 'Group aggiornato con successo',
	},
	addGroupUser: {
		title: 'Aggiungi utente',
		name: 'Nome',
		surname: 'Surname',
		email: 'Email',
		role: 'Ruolo',
		actions: 'Azioni',
		addUser: 'Aggiungi utente al gruppo',
	},
	createGroup: {
		title: 'Crea nuovo gruppo',
		code: 'Code',
		groupName: 'Nome Gruppo',
		save: 'Salva',
	},
	projectsPage: {
		title: 'Progetti',
		projectTitle: 'Titolo',
		tags: 'Tags',
		summary: 'Riepilogo',
		actions: 'Dettagli',
		searchButton: 'Cerca',
		searchInSummary: 'Cerca in Riepilogo',
		searchByProperties: 'Cerca nelle properties',
		properties: 'Proprietà',
		star: 'Star',
		unstar: 'Unstar',
		delete: 'Cancella notifica',
		modalDelete: 'Cancellare notifica',
		info: 'Info',
		all: 'Tutti',
		starred: 'Preferiti',
		display: 'Mostra',
		time: 'Tempo',
		timeSince: {
			yearsAgo: ' anni fa',
			monthsAgo: ' mesi fa',
			daysAgo: ' giorni fa',
			hoursAgo: ' ore fa',
			minutesAgo: ' minuti fa',
			secondsAgo: ' secondi fa',
		},
		noProject: 'Nessun progetto trovato',
		noAccess: 'Non puoi accedere a questa pagina',
		projectActive: 'Progetto attivo',
		projectNotActive: 'Progetto non attivo',
	},
	guideButtons: {
		next: 'Successivo',
		prev: 'Precendente',
		done: 'Fine',
	},
	onboardGuide: {
		dashboardIntroTitle: 'Benvenuto su EveryLog!',
		dashboardApiKeyTitle: 'Codice API KEY',
		dashboardDocs: 'DOCS',
		dashboardManageUsersTitle: 'Utenti',
		dashboardProjectsTitle: 'Progetto',
		dashboardManageGroupsTitle: 'Gruppi ',
		dashboardManageAccountTitle: 'Account',
		dashboardGuideTitle: 'Guida',
		dashboardIntro:
			'Ti forniamo una panoramica per scoprire tutte le funzionalità dell’app.',
		dashboardApiKey:
			'In questa sezione trovi una guida su come utilizzare la API KEY nel caso dei vari linguaggi.',
		dashboardManageUsers:
			'In questa sezione puoi creare, modificare e cancellare gli utenti che usano EveryLog.',
		dashboardProjects:
			'Qui puoi scegliere i tuoi progetti e visualizzarne tutte le notifiche ricevute. ',
		dashboardManageGroups:
			'In questa sezione puoi creare, modificare e cancellare i gruppi che usano EveryLog.',
		dashboardManageAccount:
			'Qui puoi modificare la tua password, i dati di fatturazione e gestire l’abbonamento EveryLog.',
		dashboardGuide:
			'Se hai bisogno di una mano puoi sempre consultare la nostra guida cliccando qui',
	},
	usersGuide: {
		inviteNewUserStepTitle: 'Crea utente',
		editUserStepTitle: 'Modifica utente',
		deleteUserStepTitle: 'Cancella utente',
		inviteNewUserStep: 'Invita una persona ad aggiungersi al tuo account.',
		editUserStep:
			'Modifica le informazioni che hai inserito sull’utente e i gruppi a cui lavora',
		deleteUserStep:
			'Cancella l’utente dalla lista degli utenti che utilizzano EveryLog.',
	},
	groupGuide: {
		firstStepTitle: 'Crea gruppo',
		secondStepTitle: 'Imposta gruppo',
		thirdStepTitle: 'Modifica gruppo',
		fourthStepTitle: 'Cancella gruppo',
		firstStep:
			'Quando vuoi aggiungere un nuovo gruppo ad EveryLog clicca su Crea gruppo.',
		secondStep: 'Inserisci il nome, il codice del gruppo e salva.',
		thirdStep:
			'Clicca su modifica e cambia gli utenti presenti nei tuoi gruppi.',
		fourthStep:
			'Cancella il gruppo dalla lista dei tuoi gruppi presenti su EveryLog. ',
	},
	channelGuide: {
		firstStepTitle: 'Crea un canale',
		secondStepTitle: 'Imposta canale',
		thirdStepTitle: 'Modifica canale',
		fourthStepTitle: 'Cancella canale',
		firstStep: 'Clicca su “Crea canale” per aggiungere un canale',
		secondStep: 'Inserisci il nome, il codice e tipo di canale e salva.',
		thirdStep: 'Clicca su modifica e cambia le impostazioni del canale.',
		fourthStep:
			'Cancella il canale dalla lista dei tuoi canali presenti su EveryLog. ',
	},
	accountsGuide: {
		addAccountStepTitle: 'Aggiungi Nuovo Account',
		editNotificationsStepTitle: 'Attiva Notifiche',
		accountNameStepTitle: 'Nome Account',
		accountProjectsStepTitle: 'Progetti Account',
		authTokenStepTitle: 'X-Auth-Token',
		accountRemoveStepTitle: 'Rimuovi Account',
		accountImageStepTitle: 'Aggiorna Immagine',
		addAccountStep:
			"Usa il token della mail d'invito per aggiungere un nuovo account",
		editNotificationsStep: 'Attiva/Disattiva lo Notifiche',
		accountNameStep: 'Nome Account',
		accountProjectsStep: 'Progetti account',
		authTokenStep: 'Ottieni token/ nuovo token',
		accountRemoveStep: 'Rimuovi Account',
		accountImageStep: 'Aggiorna immagine account',
	},
	invitationGuide: {
		firstStepTitle: 'Crea Invito',
		secondStepTitle: 'Invita Utente',
		thirdStepTitle: 'Cancella Invito',
		firstStep: 'Crea nuovo invito....',
		secondStep: "Invia Invito... Puoi inviare l'invito una volta al giorno",
		thirdStep: 'Cancella Invito...',
	},
	search: {
		placeholder: 'Cerca',
	},
	buttonUsers: {
		createUser: 'Crea utente',
		edit: 'Modifica',
		delete: 'Cancella',
	},
	userEdit: {
		user: 'Utente',
		editUser: 'Modifica utente',
		group: 'Gruppo',
		users: 'Utenti',
		name: 'Nome',
		surname: 'Cognome',
		language: 'Lingua',
		role: 'Ruolo',
		email: 'Email',
		save: 'Salva',
		confirmSave: 'Salvare modifiche utente?',
		projects: 'Progetti',
		add: 'Aggiungi',
	},
	notifyPage: {
		pageTitle: 'Invia notifica',
		projectId: 'Progetto',
		title: 'Titolo',
		summary: 'Descrizione',
		body: 'Messaggio',
		groups: 'Gruppi',
		channels: 'Canali',
		send: 'Invia',
		notificationSent: 'Notifica inviata',
		projectIdPlaceholder: 'Inserisci nuovo progetto o scegline uno esistente',
		titlePlaceholder: 'Inserisci titolo',
		summaryPlaceholder: 'Inserisci descrizione',
		bodyPlaceholder: 'Inserisci messaggio',
		linkPlaceholder: 'Inserisci link',
		tagsPlaceholder: 'Inserisci tags separati da una virgola',
		addGroup: 'Aggiungi gruppo',
		addChannel: 'Aggiungi canale',
		addEmoji: 'Inserisci Emoji',
		keyRequired: 'Chiave richiesta',
		valueRequired: 'Valore richiesto',
		users: 'Utenti',
		addUsers: 'Aggiungi utente',
		availableHtmlTags: 'Tag html disponibili',
		noActiveProjects: 'Nessun progetto attivo',
	},
	channelsPage: {
		pageTitle: 'Canali',
		channelName: 'Nome canale',
		channelCode: 'Codice canale',
		namePlaceholder: 'Inserisci nome canale ',
		codePlaceholder: 'Inserisci codice canale',
		channelCreated: 'Creato nuovo canale',
		name: 'Nome',
		code: 'Codice',
		type: 'Tipo',
		enable: 'Attivo',
		action: 'Azione',
		createChannel: 'Crea canale',
		searchChannel: 'Cerca canale',
		deleteChannel: 'Cancellare canale?',
		channelDeleted: 'Canale cancellato',
		noChannel: 'Crea primo canale',
		edit: 'Modifica',
		delete: 'Cancella',
		enabled: 'Attivo',
		disabled: 'Disattivo',
	},
	channelPage: {
		editChannel: 'Modifica Canale',
		pageSubTitle: 'Canale',
		save: 'Salva',
		name: 'Nome',
		code: 'Codice',
		enable: 'Attivo',
		type: 'Tipo',
		chatIdMsg: 'Inserisci chat separandole da una virgola',
		username: 'Nome utente',
		emailFrom: 'Mittente',
		emailTo: 'Destinatari',
		basicAccess: 'Autenticazione base',
		bearerAccess: 'Autenticazione Bearer-Token',
		customAccess: 'Autenticazione personalizzata',
		yourHeaders: 'I tuoi headers',
		key: 'Chiave',
		value: 'Valore',
		add: 'Aggiungi',
		state: 'Stato',
	},
	projects: {
		projectCreated: 'Progetto Creato',
		name: 'Nome',
		color: 'Colore',
		description: 'Descrizione',
		createProject: 'Crea Progetto',
		confirmDeleteProject: 'Rimuovere Progetto?',
		editProject: 'Modifica Progetto',
		deleteProject: 'Elimina Progetto',
		projectUpdated: 'Progetto modificato',
		projectActive: 'Progetto attivo',
	},
};
