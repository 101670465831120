import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack, TextField } from '@mui/material';
import { RegisterWithinvitation } from 'api/mutations';
import { ENABLE_SIGNUP, SIGNUP_WITH_ACTIVATE } from 'config';
import ConfirmAlert from 'libs/confirmAlert';
import i18next from 'libs/i18n';

import { Col, Form, FormGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';

const RegisterWithInvitationPage = (props) => {
	const mutation = useMutation(RegisterWithinvitation);
	const { t } = useTranslation();

	const schema = yup.object().shape({
		// email: yup.string().lowercase().email().required(),
		password: yup.string().min(8).required(),
		name: yup.string().required(),
		surname: yup.string().required(),
		// accountName: yup.string().required(),
		token: yup.string().required(),
		privacyAccepted: yup
			.boolean()
			.required(t('registerPage.termsAccepted'))
			.oneOf([true], t('registerPage.termsAccepted')),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm({
		resolver: yupResolver(schema),
	});

	const onSubmit = async (data) => {
		try {
			data.language = i18next.language;
			data.subdomain = uuidv4();
			const response = await mutation.mutateAsync(data);
			if (response) {
				props.history.push('/dashboard');
			}
		} catch (error) {
			if (error.response && error.response.data) {
				ConfirmAlert.error(error.response.data.message);
			}
		}
	};

	const betaTestingMessage = t('registerPage.betaTestingMessage');

	return (
		<div>
			<h3 className='m-20 m-b-30'>{t('registerPage.register')}</h3>
			{ENABLE_SIGNUP ? (
				<Form
					id='email-form'
					name='email-form'
					data-name='Email Form'
					className='form'
					onSubmit={handleSubmit(onSubmit)}
				>
					<Stack gap={2}>
						{/* <FormGroup>
						<small id="nameHelp" className="form-text text-muted">
						{errors.name?.message}
						</small>
						<input
						type="string"
						className="form-control custom-input mt-3"
						maxLength="256"
						name="name"
						data-name="Name"
						placeholder="Name"
						id="name"
						{...register("name", { required: true })}
						/>
					</FormGroup> */}
						<TextField
							type='string'
							name='name'
							id='name'
							{...register('name', { required: true })}
							helperText={errors.name?.message}
							fullWidth
							inputProps={{ maxLength: 256 }}
							label='Name'
						/>
						{/* <FormGroup>
						<small id='surnameHelp' className='form-text text-muted'>
							{errors.surname?.message}
						</small>
						<input
							type='string'
							className='form-control custom-input mt-3'
							maxLength='256'
							name='surname'
							data-name='Surname'
							placeholder='Surname'
							id='surname'
							{...register('surname', { required: true })}
						/>
					</FormGroup> */}
						<TextField
							type='string'
							name='surname'
							id='surname'
							{...register('surname', { required: true })}
							helperText={errors.surname?.message}
							fullWidth
							inputProps={{ maxLength: 256 }}
							label='Surname'
						/>
						{/* <FormGroup>
							<small id='passwordHelp' className='form-text text-muted'>
								{errors.password?.message}
							</small>
							<input
								type='password'
								className='form-control custom-input mt-3'
								maxLength='256'
								name='password'
								data-name='Password'
								placeholder='Password'
								id='password'
								required=''
								{...register('password', { required: true })}
							/>
						</FormGroup> */}
						<TextField
							type='password'
							name='password'
							id='password'
							{...register('password', { required: true })}
							helperText={errors.password?.message}
							fullWidth
							inputProps={{ maxLength: 256 }}
							label='Password'
						/>
						{/* <FormGroup>
							<small id='token' className='form-text text-muted'>
								{errors.token?.message}
							</small>
							<input
								type='token'
								className='form-control custom-input mt-3'
								maxLength='256'
								name='token'
								data-name='token'
								placeholder='Invitation Token'
								id='token'
								required=''
								{...register('token', { required: true })}
							/>
						</FormGroup> */}
						<TextField
							type='token'
							name='token'
							id='token'
							{...register('token', { required: true })}
							helperText={errors.token?.message}
							fullWidth
							inputProps={{ maxLength: 256 }}
							label='Token'
						/>
						<FormGroup>
							<small id='privacyAcceptedHelp' className='form-text text-muted'>
								{errors.privacyAccepted?.message}
							</small>
							<input
								type='checkbox'
								id='privacyAccepted'
								name='policy'
								data-name='Checkbox'
								className='w-checkbox-input checkbox mt-3'
								{...register('privacyAccepted', { required: true })}
								aria-describedby='privacyAcceptedHelp'
							/>
							<span className='checkbox-label w-form-label text-justify'>
								&nbsp;{t('registerPage.registerConsent')}
							</span>
							<br />
							<a
								href='https://www.everylog.io/privacy-policy'
								target='_blank'
								rel='noreferrer'
							>
								{t('registerPage.privacy')}
							</a>
						</FormGroup>
						<FormGroup>
							<small
								id='marketingAcceptedHelp'
								className='form-text text-muted'
							>
								{errors.marketingAccepted?.message}
							</small>
							<input
								type='checkbox'
								id='marketingAccepted'
								name='policy'
								data-name='Checkbox'
								className='w-checkbox-input checkbox'
								{...register('marketingAccepted')}
								aria-describedby='marketingAcceptedHelp'
							/>
							<span className='checkbox-label w-form-label text-justify'>
								&nbsp;{t('registerPage.marketingConsent')}
							</span>
						</FormGroup>
						<Button type='submit' variant='contained'>
							{t('registerPage.confirm')}
						</Button>
						{/* <input
							type='submit'
							value={t('registerPage.confirm')}
							className='btn btn-primary m-t-20'
						/> */}
					</Stack>
				</Form>
			) : (
				<div dangerouslySetInnerHTML={{ __html: betaTestingMessage }} />
			)}
			<Col sm={12} className='text-justify m-t-20'>
				<Link to='/auth/login'>{t('registerPage.alreadyRegistered')}</Link>
			</Col>
		</div>
	);
};
export default RegisterWithInvitationPage;
