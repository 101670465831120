import { yupResolver } from '@hookform/resolvers/yup';
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
} from '@mui/material';
import { Button as ButtonMUI } from '@mui/material';
import { countries } from 'libs/countries';

import { Button, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const schema = yup.object().shape({
	companyName: yup.string().required(),
	companyPhone: yup.string().required(),
	companyBillingAddress: yup.string().required(),
	companyCountry: yup.string().required(),
	companyVat: yup.string(),
	companyEmail: yup.string().lowercase().email().required(),
	companySdi: yup.string(),
	companyPec: yup.string(),
});

const AccountForm = ({ user, onSubmit }) => {
	const { t } = useTranslation();

	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			companyName: user.account.companyName,
			companyPhone: user.account.companyPhone,
			companyBillingAddress: user.account.companyBillingAddress,
			companyVat: user.account.companyVat,
			companySdi: user.account.companySdi,
			companyPec: user.account.companyPec,
			companyEmail: user.account.companyEmail,
			companyCountry: user.account.companyCountry,
		},
	});

	return (
		<Form
			id='email-form'
			name='email-form'
			data-name='Email Form'
			onSubmit={handleSubmit((data) => {
				console.log(data);
				onSubmit(data);
			})}
		>
			<Stack gap={2}>
				{/* <Form.Group controlId="formCompanyName">
        <Form.Label>{t("accountForm.companyName")}</Form.Label>
        <Form.Control
          type="text"
          maxLength="256"
          name="companyName"
          data-name="Compnay Name"
          placeholder=""
          id="companyName"
          {...register("companyName", { required: true })}
        />
        <span className="text-muted">{errors.companyName?.message}</span>
      </Form.Group> */}
				<TextField
					label={t('accountForm.companyName')}
					fullWidth
					type='text'
					maxLength='256'
					name='companyName'
					data-name='Compnay Name'
					placeholder=''
					id='companyName'
					{...register('companyName', { required: true })}
				/>
				{/* <Form.Group controlId='formCompanyPhone'>
				<Form.Label>{t('accountForm.phoneNumber')}</Form.Label>
				<Form.Control
					type='text'
					maxLength='256'
					name='companyPhone'
					data-name='Company Phone'
					placeholder=''
					id='companyPhone'
					{...register('companyPhone', { required: true })}
				/>
				<span className='text-muted'>{errors.companyPhone?.message}</span>
			</Form.Group> */}
				<TextField
					label={t('accountForm.phoneNumber')}
					fullWidth
					type='text'
					maxLength='256'
					name='companyPhone'
					data-name='Company Phone'
					placeholder=''
					id='companyPhone'
					{...register('companyPhone', { required: true })}
				/>
				{/* <Form.Group controlId='formcompanyBillingAddress'>
					<Form.Label>{t('accountForm.billingAddress')}</Form.Label>
					<Form.Control
						type='text'
						maxLength='256'
						name='companyBillingAddress'
						data-name='Company Billing Address'
						placeholder=''
						id='companyBillingAddress'
						{...register('companyBillingAddress', { required: true })}
					/>
					<span className='text-muted'>
						{errors.companyBillingAddress?.message}
					</span>
				</Form.Group> */}
				<TextField
					label={t('accountForm.billingAddress')}
					fullWidth
					type='text'
					maxLength='256'
					name='companyBillingAddress'
					data-name='Company Billing Address'
					placeholder=''
					id='companyBillingAddress'
					{...register('companyBillingAddress', { required: true })}
				/>
				{/* <Form.Group controlId='formcompanyEmail'>
					<Form.Label>{t('Email')}</Form.Label>
					<Form.Control
						type='text'
						maxLength='256'
						name='companyEmail'
						data-name='Email'
						placeholder=''
						id='companyEmail'
						{...register('companyEmail', { required: true })}
					/>
					<span className='text-muted'>{errors.companyEmail?.message}</span>
				</Form.Group> */}
				<TextField
					label={t('Email')}
					fullWidth
					type='text'
					maxLength='256'
					name='companyEmail'
					data-name='Email'
					placeholder=''
					id='companyEmail'
					{...register('companyEmail', { required: true })}
				/>
				{/* <Form.Group controlId='formcompanyCountry'>
					<Form.Label>{t('accountForm.companyCountry')}</Form.Label>
					<Form.Control
						as='select'
						id='companyCountry'
						{...register('companyCountry', { required: true })}
					>
						<option>{t('accountForm.selectOne')}</option>
						{countries.map((value, index) => {
							return (
								<option key={index} value={value.code}>
									{value.name} {value.flag}
								</option>
							);
						})}
					</Form.Control>
					<span className='text-muted'>{errors.companyCountry?.message}</span>
				</Form.Group> */}
				<Controller
					control={control}
					name='companyCountry'
					render={({ field }) => (
						<FormControl>
							<InputLabel id='demo-simple-select-label'>
								{t('accountForm.companyCountry')}
							</InputLabel>
							<Select
								{...field}
								required
								labelId='demo-simple-select-label'
								fullWidth
								error={errors.companyCountry ? true : false}
								label={t('accountForm.companyCountry')}
								MenuProps={{ PaperProps: { style: { height: '300px' } } }}
							>
								{countries.map((value, i) => (
									<MenuItem key={i} value={value.code}>
										{value.name}
										{value.flag}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
				/>
				{/* <Form.Group controlId='formcompanyVat'>
					<Form.Label>{t('accountForm.vatNumber')}</Form.Label>
					<Form.Control
						type='text'
						maxLength='256'
						name='companyVat'
						data-name='Company Vat'
						placeholder=''
						id='companyVat'
						{...register('companyVat')}
					/>
					<span className='text-muted'>{errors.companyVat?.message}</span>
				</Form.Group> */}
				<TextField
					label={t('accountForm.vatNumber')}
					fullWidth
					type='text'
					maxLength='256'
					name='companyVat'
					data-name='Company Vat'
					placeholder=''
					id='companyVat'
					{...register('companyVat')}
				/>

				{/* <Form.Group controlId='formcompanyPec'>
					<Form.Label>
						{t('accountForm.pec')}
						<small>
							<i>{t('accountForm.onlyForItalians')}</i>
						</small>
					</Form.Label>
					<Form.Control
						type='text'
						maxLength='256'
						name='companyPec'
						data-name='Company Pec'
						placeholder=''
						id='companyPec'
						{...register('companyPec')}
					/>
					<span className='text-muted'>{errors.companyPec?.message}</span>
				</Form.Group> */}
				<TextField
					label={
						<>
							{t('accountForm.pec')}
							<small>
								<i>{t('accountForm.onlyForItalians')}</i>
							</small>
						</>
					}
					fullWidth
					type='text'
					maxLength='256'
					name='companyPec'
					data-name='Company Pec'
					placeholder=''
					id='companyPec'
					{...register('companyPec')}
				/>

				{/* <Form.Group controlId='formcompanySdi'>
					<Form.Label>
						{t('accountForm.sdi')}
						<small>
							<i>{t('accountForm.onlyForItalians')}</i>
						</small>
					</Form.Label>
					<Form.Control
						type='text'
						maxLength='256'
						name='companySdi'
						data-name='Company SDI'
						placeholder=''
						id='companySdi'
						{...register('companySdi')}
					/>
					<span className='text-muted'>{errors.companySdi?.message}</span>
				</Form.Group> */}
				<TextField
					label={
						<>
							{t('accountForm.sdi')}
							<small>
								<i>{t('accountForm.onlyForItalians')}</i>
							</small>
						</>
					}
					fullWidth
					type='text'
					maxLength='256'
					name='companySdi'
					data-name='Company SDI'
					placeholder=''
					id='companySdi'
					{...register('companySdi')}
				/>

				{/* <Button
					type='submit'
					className='custom-btn green w-100-perc'
					data-wait='Please wait...'
				>
					{t('accountForm.update')}
				</Button> */}
				<ButtonMUI type='submit' variant='contained' fullWidth>
					{t('accountForm.update')}
				</ButtonMUI>
			</Stack>
		</Form>
	);
};

export default AccountForm;
