import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Plans } from "api/queries";
import Box from "app/components/dashboard/Box";
import moment from "moment";

import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import PlanCard from "../plan/PlanCard";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { theme } from "libs/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCircleCheck,
  faCircleXmark,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const TrialComponent = ({ user }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [planNr, setPlanNr] = useState(0);
  const { isLoading: plansLoading, data: plansData } = useQuery(
    "Plans",
    Plans,
    {
      onSuccess: (data) => {
        if (user.account.planType == "premium") {
          setPlanNr(3);
        }
      },
      retry: false,
    }
  );

  return (
    <Row>
      <Box
        color="white"
        image={<img src="/images/articoliesocial-2.svg" />}
        header={
          <Typography color={"black"} fontSize={26}>
            {user.email}
          </Typography>
        }
        body={
          <div className="text-dark">
            <p>
              {t("trialComponent.youAreOnTrial", {
                planType: user.account.planType,
              })}
            </p>
            {user.account.planType != "starter" && user.role === "admin" && (
              <strong>
                {t("trialComponent.trialEndsAt")}
                {moment(user.account.trialPeriodEndsAt).format("DD/MM/YYYY")}
              </strong>
            )}

            <Card
              elevation={8}
              sx={{ width: "300px", mt: "20px", borderRadius: "10px" }}
            >
              <CardActionArea onClick={() => history.push("/plan")}>
                <CardHeader
                  sx={{ backgroundColor: theme.palette.secondary.main }}
                  title={
                    <Typography
                      fontSize={20}
                      color={"white"}
                      textAlign={"center"}
                    >
                      {user.account.planType == "premium"
                        ? "ELITE"
                        : user.account.planType.toUpperCase()}
                    </Typography>
                  }
                />
                <CardContent>
                  <Stack gap={1} ml={"10px"}>
                    {!plansLoading &&
                      plansData.data.plans[planNr].features.map((el) => (
                        <Stack direction={"row"} alignItems={"center"} gap={3}>
                          <IconButton size="small" sx={{ width: "20px" }}>
                            <FontAwesomeIcon
                              color={
                                el[1]
                                  ? theme.palette.primary.main
                                  : theme.palette.error.main
                              }
                              icon={el[1] ? faCheck : faXmark}
                            />
                          </IconButton>
                          <Typography>{el[0]}</Typography>
                        </Stack>
                      ))}
                  </Stack>
                </CardContent>
                <CardActionArea>
                  <Button
                    variant="contained"
                    onClick={() => history.push("/plan")}
                    fullWidth
                  >
                    {t("trialComponent.goToPlans")}
                  </Button>
                </CardActionArea>
              </CardActionArea>
            </Card>
          </div>
        }
      />
    </Row>
  );
};

export default TrialComponent;
