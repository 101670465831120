import ModalCreateNewAccount from 'app/components/ModalCreateNewAccount';
import { useDialog } from 'hoc/useDialog';
import React from 'react';
import { Button } from '@mui/material';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQueryClient } from 'react-query';
import { CreateNewAccount } from 'api/mutations';
import { useTranslation } from 'react-i18next';

const CreateNewAccountButton = () => {
	const { t } = useTranslation();
	const dialog = useDialog();

	const queryClient = useQueryClient();
	const createNewAccountMutation = useMutation(CreateNewAccount, {
		onSuccess: () => {
			queryClient.invalidateQueries(['Me']);
		},
	});
	const createNewAccount = async (data) => {
		const payload = {
			accountName: data,
		};
		try {
			const resp = await createNewAccountMutation.mutateAsync(payload);
			console.log(resp);
		} catch (error) {
			console.log(error);
		}
	};
	return (
		<>
			<ModalCreateNewAccount
				onConfirm={(data) => createNewAccount(data)}
				show={dialog.open}
				setShow={dialog.handleClose}
			/>
			<Button
				variant='contained'
				startIcon={<FontAwesomeIcon icon={faPlus} />}
				onClick={dialog.handleOpen}
			>
				{t('editUserPage.addNewAccount')}
			</Button>
		</>
	);
};

export default CreateNewAccountButton;
