import { Groups } from 'api/queries';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from 'react-query';
import 'intro.js/introjs.css';
import { useTranslation } from 'react-i18next';
import confirmAlert from 'libs/confirmAlert';
import { activeMenuState } from 'libs/atoms';
import { useRecoilState } from 'recoil';
import { Steps } from 'intro.js-react';
import Loader from 'app/components/Loader';
import useGroupQuery from 'hoc/useGroupQuery';
import ModalConfirm from 'hoc/ModalConfirm';
import { TextField } from '@mui/material';
import { Button as ButtonMUI } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function GroupsPage({ user }) {
	const [activeMenu, setActiveMenu] = useRecoilState(activeMenuState);
	const history = useHistory();
	useEffect(() => {
		setActiveMenu('groups');
	}, []);

	const { t } = useTranslation();

	const [groupData, setGroupData] = useState({
		code: undefined,
		name: undefined,
	});
	const [show, setShow] = useState(false);
	const [deletePopup, setDeletePopup] = useState(false);
	const [groupId, setGroupId] = useState(undefined);
	const [help, setHelp] = useState(false);
	const [sortbyList, setSortbyList] = useState({
		name: true,
		code: true,
		users: true,
	});

	const { deleteGroup, createGroup, groupsList } = useGroupQuery(user);

	const { isLoading, data } = groupsList;

	const handleGroupNameInput = (event) => {
		setGroupData({ ...groupData, name: event.target.value });
	};

	const handleGroupCodeInput = (event) => {
		setGroupData({ ...groupData, code: event.target.value });
	};

	const options = {
		nextLabel: t('guideButtons.next'),
		prevLabel: t('guideButtons.prev'),
		doneLabel: t('guideButtons.done'),
	};

	let stepsUsers = [
		{
			element: '#create-group-button',
			intro: (
				<div>
					<h5 style={{ fontSize: '20px', fontWeight: '700' }}>
						{t('groupGuide.firstStepTitle')}
					</h5>
					<div className='pb-0'>{t('groupGuide.firstStep')}</div>
				</div>
			),
		},
		{
			intro: (
				<div>
					<h5 style={{ fontSize: '20px', fontWeight: '700' }}>
						{t('groupGuide.secondStepTitle')}
					</h5>
					<div className='pb-0'>{t('groupGuide.secondStep')}</div>
				</div>
			),
		},
		{
			element: '#edit-group-button',
			intro: (
				<div>
					<h5 style={{ fontSize: '20px', fontWeight: '700' }}>
						{t('groupGuide.thirdStepTitle')}
					</h5>
					<div className='pb-0'>{t('groupGuide.thirdStep')}</div>
				</div>
			),
		},
		{
			element: '#delete-group-button',
			intro: (
				<div>
					<h5 style={{ fontSize: '20px', fontWeight: '700' }}>
						{t('groupGuide.fourthStepTitle')}
					</h5>
					<div className='pb-0'>{t('groupGuide.fourthStep')}</div>
				</div>
			),
		},
	];

	const onExit = () => {
		setHelp(false);
	};

	const sortBy = (by) => {
		switch (by) {
			case 'name':
				if (sortbyList.name) {
					data.data.sort((a, b) => {
						const titleA = a.name.toUpperCase();
						const titleB = b.name.toUpperCase();
						if (titleA < titleB) {
							return -1;
						}
						if (titleA > titleB) {
							return 1;
						}
						return 0;
					});
				} else {
					data.data.sort((a, b) => {
						const titleA = a.name.toUpperCase();
						const titleB = b.name.toUpperCase();
						if (titleA > titleB) {
							return -1;
						}
						if (titleA < titleB) {
							return 1;
						}
						return 0;
					});
				}
				setSortbyList({ ...sortbyList, name: !sortbyList.name });
				break;
			case 'code':
				if (sortbyList.code) {
					data.data.sort((a, b) => {
						const titleA = a.code.toUpperCase();
						const titleB = b.code.toUpperCase();
						if (titleA < titleB) {
							return -1;
						}
						if (titleA > titleB) {
							return 1;
						}
						return 0;
					});
				} else {
					data.data.sort((a, b) => {
						const titleA = a.code.toUpperCase();
						const titleB = b.code.toUpperCase();
						if (titleA > titleB) {
							return -1;
						}
						if (titleA < titleB) {
							return 1;
						}
						return 0;
					});
				}
				setSortbyList({ ...sortbyList, code: !sortbyList.code });
				break;

			case 'users':
				if (sortbyList.users) {
					data.data.sort((a, b) => {
						const titleA = a.users.length;
						const titleB = b.users.length;
						if (titleA < titleB) {
							return -1;
						}
						if (titleA > titleB) {
							return 1;
						}
						return 0;
					});
				} else {
					data.data.sort((a, b) => {
						const titleA = a.users.length;
						const titleB = b.users.length;
						if (titleA > titleB) {
							return -1;
						}
						if (titleA < titleB) {
							return 1;
						}
						return 0;
					});
				}
				setSortbyList({ ...sortbyList, users: !sortbyList.users });
				break;

			default:
				break;
		}
	};

	if (isLoading) {
		return <Loader />;
	}

	return (
		<>
			<Steps
				enabled={help}
				steps={stepsUsers}
				onExit={onExit}
				initialStep={0}
				options={options}
			/>
			<Modal
				show={show}
				centered
				onHide={() => {
					setGroupData({ code: null, name: null });
					setShow(false);
				}}
			>
				<Form
					onReset={(e) => {
						e.preventDefault();
						setShow(false);
						setGroupData({ code: null, name: null });
					}}
					onSubmit={async (e) => {
						e.preventDefault();
						const resp = await createGroup(groupData);
						if (resp) {
							confirmAlert.success(t('groupsPage.groupCreated'));
							setGroupData({ code: null, name: null });
						}
						setShow(false);
					}}
				>
					<div className='popup-crea-gruppo p-4'>
						<div>
							<TextField
								variant='outlined'
								fullWidth
								label={t('createGroup.groupName')}
								onChange={(event) => handleGroupNameInput(event)}
								type={'text'}
								value={groupData.name}
								required
							/>
						</div>
						<div>
							<TextField
								variant='outlined'
								fullWidth
								label='Code'
								onChange={(event) => handleGroupCodeInput(event)}
								value={groupData.code}
								required
							/>
						</div>
						<div className='d-flex justify-content-center gap-5'>
							<ButtonMUI variant='contained' color='error' type='reset'>
								{t('createUsersPage.cancel')}
							</ButtonMUI>
							{/* <Button
								type='reset'
								className='group-cancel-button d-flex justify-content-center align-items-center'
							>
								{t('createUsersPage.cancel')}
							</Button> */}
							<ButtonMUI
								variant='contained'
								sx={{ width: '100px' }}
								type='submit'
							>
								{t('createUsersPage.save')}
							</ButtonMUI>
							{/* <Button
								type='submit'
								className='create-group-button d-flex justify-content-center align-items-center'
							>
								{t('createUsersPage.save')}
							</Button> */}
						</div>
					</div>
				</Form>
			</Modal>
			<ModalConfirm
				message={`${t('groupsPage.deleteGroup')}?`}
				onConfirm={() => {
					deleteGroup(groupId);
					setDeletePopup(false);
				}}
				show={deletePopup}
				setShow={setDeletePopup}
			/>
			<div className='m-0-auto max-width'>
				<div className='d-flex gap-2'>
					<h1 className='title-font'>{t('groupsPage.title')}</h1>
					<FontAwesomeIcon
						icon={faCircleQuestion}
						className='text-blue pointer-hover fs-5'
						onClick={() => setHelp(true)}
					/>
				</div>
				<div className='dashboard-box mt-4 py-4 px-5'>
					<div className='d-flex justify-content-between align-items-center mb-4'>
						<div>
							{data && data.data.length === 0 && (
								<h2 className='font-20 fw-bold'>{t('groupsPage.noGroup')}</h2>
							)}
						</div>
						<ButtonMUI
							id='create-group-button'
							variant='contained'
							onClick={() => setShow(true)}
						>
							{t('groupsPage.create')}
						</ButtonMUI>
						{/* <div
							id='create-group-button'
							style={{ color: 'white', backgroundColor: '#72d830' }}
							className='table-row-color mt-3 create-group-button d-flex justify-content-center align-items-center'
							onClick={() => setShow(true)}
						>
							{t('groupsPage.create')}
						</div> */}
					</div>
					{data && data.data.length !== 0 && (
						<Table responsive bordered>
							<thead>
								<tr className='d-none d-md-table-row'>
									<th className='col-1 text-start font-20'>
										<span
											className='pointer-hover'
											onClick={() => sortBy('name')}
										>
											{t('groupsPage.name')}
										</span>
									</th>
									<th className='col-1 text-start font-20'>
										<span
											className='pointer-hover'
											onClick={() => sortBy('users')}
										>
											{t('groupsPage.nrUsers')}
										</span>
									</th>
									<th className='col-1 text-start font-20' id='code-group'>
										<span
											className='pointer-hover'
											onClick={() => sortBy('code')}
										>
											{t('groupsPage.code')}
										</span>
									</th>
									<th className='col-1 text-start font-20' id='delete-group'>
										{t('groupsPage.actions')}
									</th>
								</tr>
							</thead>
							<tbody>
								{data && (
									<>
										{data.data.map((element, i) => (
											<tr
												key={`element.name-${i}`}
												className='table-row-color font-14 d-flex flex-column d-md-table-row'
											>
												<div className='d-md-none font-16 fw-bold pb-0'>
													{t('groupsPage.name')}:
												</div>
												<td className='text-start vertical-align border-0 text-break'>
													{element.name}
												</td>
												<div className='d-md-none font-16 fw-bold pb-0'>
													{t('groupsPage.nrUsers')}:
												</div>
												<td className='text-start vertical-align border-0 text-break'>
													{element.users.length}
												</td>
												<div className='d-md-none font-16 fw-bold pb-0'>
													{t('groupsPage.code')}:
												</div>
												<td className='text-start vertical-align border-0 text-break'>
													{element.code}
												</td>
												<div className='d-md-none font-16 fw-bold pb-0'>
													{t('groupsPage.actions')}:
												</div>
												<td className='border-0'>
													<div className='d-flex justify-content-start gap-3'>
														<ButtonMUI
															id={i === 0 ? 'edit-group-button' : `${i}-group`}
															onClick={() =>
																history.push(`/groups/${element.id}`)
															}
															variant='contained'
															color='secondary'
														>
															Edit
														</ButtonMUI>
														{/* {i === 0 ? (
															<Link
																id='edit-group-button'
																className='me-2 group-button-edit'
																to={`/groups/${element.id}`}
															>
																Edit
															</Link>
														) : (
															<Link
																className='me-2 group-button-edit'
																to={`/groups/${element.id}`}
															>
																Edit
															</Link>
														)} */}
														<ButtonMUI
															variant='contained'
															color='error'
															onClick={() => {
																setGroupId(element.id);
																setDeletePopup(true);
															}}
															id={
																i === 0
																	? 'delete-group-button'
																	: `${i}-group-delete`
															}
														>
															Delete
														</ButtonMUI>
														{/* {i === 0 ? (
															<div
																id='delete-group-button'
																className='group-button-delete'
																onClick={() => {
																	setGroupId(element.id);
																	setDeletePopup(true);
																}}
															>
																Delete
															</div>
														) : (
															<div
																className='group-button-delete'
																onClick={() => {
																	setGroupId(element.id);
																	setDeletePopup(true);
																}}
															>
																Delete
															</div>
														)} */}
													</div>
												</td>
											</tr>
										))}
									</>
								)}
							</tbody>
						</Table>
					)}
				</div>
			</div>
		</>
	);
}
