import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack, TextField } from '@mui/material';
import { Login } from 'api/mutations';
import { ENTERPRISE } from 'config';
import ConfirmAlert from 'libs/confirmAlert';

import { Col, Form, FormGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

const schema = yup.object().shape({
	email: yup.string().email().required(),
	password: yup.string().min(8).required(),
});

const LoginPage = (props) => {
	const { t } = useTranslation();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});
	const mutation = useMutation(Login);

	const onSubmit = async (data) => {
		try {
			await mutation.mutateAsync(data);
		} catch (error) {
			if (error.response && error.response.data) {
				ConfirmAlert.error(error.response.data);
				return;
			}
		}
		props.history.push('/dashboard');
	};

	return (
		<div>
			<h3 className='m-20 m-b-30'>{t('loginPage.access')}</h3>
			<Form
				id='email-form'
				name='email-form'
				data-name='Email Form'
				className='form'
				onSubmit={handleSubmit(onSubmit)}
			>
				<Stack gap={2}>
					{/* <FormGroup>
					<small id='emailHelp' className='form-text text-muted'>
          {errors.email?.message}
					</small>
					<input
          className='form-control custom-input'
          type='text'
          name='email'
          aria-describedby='emailHelp'
          placeholder='Email'
          maxLength='256'
          data-name='Email'
          id='email'
          {...register('email', { required: true })}
					/>
				</FormGroup> */}
					<TextField
						label='Email'
						fullWidth
						type='text'
						name='email'
						helperText={errors.email?.message}
						id='email'
						inputProps={{ maxLength: 256 }}
						{...register('email', { required: true })}
					/>
					{/* <FormGroup>
					<small id='passwordHelp' className='form-text text-muted'>
          {errors.password?.message}
					</small>
					<input
          className='form-control custom-input mt-3'
          type='password'
          maxLength='256'
          name='password'
          data-name='Password'
          placeholder='Password'
          id='password'
          required=''
          {...register('password', { required: true })}
					/>
				</FormGroup> */}
					<TextField
						label='Password'
						fullWidth
						type='password'
						name='password'
						helperText={errors.password?.message}
						id='password'
						inputProps={{ maxLength: 256 }}
						{...register('password', { required: true })}
					/>
					<Button type='submit' variant='contained'>
						{t('loginPage.confirm')}
					</Button>
				</Stack>
			</Form>
			<Col sm={12} className='text-center m-t-20'>
				<Link
					to={{
						pathname: `${
							ENTERPRISE ? '/auth/register-with-invitation' : '/auth/register'
						}`,
					}}
				>
					{t('loginPage.register')}
				</Link>
				<br />
				<Link to={{ pathname: '/auth/forgot-password' }}>
					{t('loginPage.forgotPassword')}
				</Link>
				<br />
				{!ENTERPRISE && (
					<Link to={{ pathname: '/auth/resend-activation' }}>
						{t('loginPage.didntReceivedActivationEmail')}
					</Link>
				)}
				<br />
			</Col>
		</div>
	);
};
export default LoginPage;
