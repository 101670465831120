import {
	faUsers,
	faUser,
	faBriefcase,
	faHouse,
	faCircleUser,
	faXmark,
	faCircleQuestion,
	faShareNodes,
	faCircleInfo,
	faEnvelope,
	faBell,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeCurrentAccount, Logout, UpdateMe } from 'api/mutations';
import BaseFooter from 'app/components/layout/BaseFooter';
import { activeMenuState, selectedPlanState } from 'libs/atoms';
import { Container, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import '../../../css/privateLayout/private.css';
import React, { useEffect, useRef, useState } from 'react';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import confirmAlert from 'libs/confirmAlert';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import useOnClickOutside from 'hoc/onClickOutsude';
import { ENTERPRISE } from 'config';
import { ENTERPRISE_IMAGE } from 'config';
import { ENTERPRISE_IMAGE_MOBILE } from 'config';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { theme } from 'libs/theme';
import { grey } from '@mui/material/colors';

const PrivateLayout = ({ children, user }) => {
	const { t } = useTranslation();

	const refUser = useRef();

	const refMenu = useRef();

	const queryClient = useQueryClient();

	const [selectedPlan, setSelectedPlan] = useRecoilState(selectedPlanState);

	const history = useHistory();

	const [userIcon, setUserIcon] = useState('d-none');

	const [open, setOpen] = useState(false);

	const val = user.onboardHelp;

	const [bgMobileMenu, setBgMobileMenu] = useState('invisible');

	const activeMenu = useRecoilValue(activeMenuState);

	const [activeUser, setActiveUser] = useRecoilState(activeMenuState);

	const [selectedAccount, setSelectedAccount] = useState(user.currentAccount);

	useEffect(() => {
		if (open) {
			setBgMobileMenu('visible');
		} else {
			setBgMobileMenu('invisible');
		}
	}, [open]);

	const goToHome = () => {
		setSelectedPlan(undefined);
		history.push('/dashboard');
	};

	const logout = () => {
		Logout();
		window.location.href = '/auth/login';
	};

	const mutation = useMutation(UpdateMe, {
		onSuccess: () => {
			queryClient.invalidateQueries(['Me']);
		},
	});

	const changeCurrentAccountMutate = useMutation(ChangeCurrentAccount, {
		onSuccess: (data) => {
			queryClient.invalidateQueries(['Me']);
			setSelectedAccount(data.data.currentAccount);
		},
	});

	const changeCurrentAccount = (id) => {
		try {
			const result = changeCurrentAccountMutate.mutateAsync(id);
			if (result) {
				history.push('/dashboard');
			}
		} catch (error) {
			if (error.response && error.response.data) {
				confirmAlert.error(error.response.data);
				return;
			}
		}
	};

	const openInNewTab = (url) => {
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
		if (newWindow) newWindow.opener = null;
	};

	const [enabled, setEnabled] = useState(() => {
		if (val === 'dashboardHelp') {
			return false;
		}
		return true;
	});

	const onExit = async () => {
		setEnabled(false);
		if (user.onboardHelp !== 'dashboardHelp') {
			try {
				const data = { onboardHelp: 'dashboardHelp' };
				await mutation.mutateAsync(data);
			} catch (error) {
				if (error.response && error.response.data) {
					confirmAlert.error(error.response.data);
					return;
				}
			}
		}
	};

	let stepsDashboard = [
		{
			intro: (
				<div>
					<h5 style={{ fontSize: '20px', fontWeight: '700' }}>
						{t('onboardGuide.dashboardIntroTitle')}
					</h5>
					<div className='pb-0'>{t('onboardGuide.dashboardIntro')}</div>
				</div>
			),
		},
		{
			element: '#docs',
			intro: (
				<div>
					<h5 style={{ fontSize: '20px', fontWeight: '700' }}>
						{t('onboardGuide.dashboardDocs')}
					</h5>
					<div className='pb-0'>{t('onboardGuide.dashboardApiKey')}</div>
				</div>
			),
		},

		{
			element: '#users-edit',
			intro: (
				<div>
					<h5 style={{ fontSize: '20px', fontWeight: '700' }}>
						{t('onboardGuide.dashboardManageUsersTitle')}
					</h5>
					<div className='pb-0'>{t('onboardGuide.dashboardManageUsers')}</div>
				</div>
			),
		},
		{
			element: '#projects',
			intro: (
				<div>
					<h5 style={{ fontSize: '20px', fontWeight: '700' }}>
						{t('onboardGuide.dashboardProjectsTitle')}
					</h5>
					<div className='pb-0'>{t('onboardGuide.dashboardProjects')}</div>
				</div>
			),
		},
		{
			element: '#groups',
			intro: (
				<div>
					<h5 style={{ fontSize: '20px', fontWeight: '700' }}>
						{t('onboardGuide.dashboardManageGroupsTitle')}
					</h5>
					<div className='pb-0'>{t('onboardGuide.dashboardManageGroups')}</div>
				</div>
			),
		},
		{
			element: '.user-icon',
			intro: (
				<div>
					<h5 style={{ fontSize: '20px', fontWeight: '700' }}>
						{t('onboardGuide.dashboardManageAccountTitle')}
					</h5>
					<div className='pb-0'>{t('onboardGuide.dashboardManageAccount')}</div>
				</div>
			),
		},
		{
			element: '#info-help',
			intro: (
				<div>
					<h5 style={{ fontSize: '20px', fontWeight: '700' }}>
						{t('onboardGuide.dashboardGuideTitle')}
					</h5>
					<div className='pb-0'>{t('onboardGuide.dashboardGuide')}</div>
				</div>
			),
		},
	];

	let pt = '';

	if (user.role === 'collaborator') {
		stepsDashboard = [];
		pt = 'mt-md-5';
	}

	const displayUserMenu = () => {
		if (userIcon === 'd-none') {
			setUserIcon('d-block');
		} else {
			setUserIcon('d-none');
		}
	};

	useOnClickOutside(refUser, () => setUserIcon('d-none'));

	useOnClickOutside(refMenu, () => setOpen(false));

	const isActive = (item) => {
		if (item === activeMenu) {
			return 'selected-menu';
		} else {
			return '';
		}
	};

	// console.log("User: ", user);
	// console.log(
	//   "External: ",
	//   user.account.planType == "premium" || user.account.planType == "enterprise",
	// );
	return (
		<div className='d-flex flex-column' id='h100'>
			<div className={`${bgMobileMenu} bg-navbarMenu-isOpen`}></div>
			<div id='page-content'>
				{user.role !== 'external' && (
					<Steps
						enabled={enabled}
						steps={stepsDashboard}
						onExit={onExit}
						initialStep={0}
					/>
				)}
				<header>
					<>
						{['md'].map((expand) => (
							/* top bar */
							<Navbar
								key={expand}
								bg='light'
								expand={'md'}
								expanded={open}
								ref={refMenu}
							>
								<Container fluid>
									<Navbar.Brand
										href='#'
										className='w-100-perc d-flex justify-content-between p-0'
									>
										<div className='d-flex'>
											<Navbar.Toggle
												aria-controls={`offcanvasNavbar-expand-${expand}`}
												className='m-auto h-75'
												onClick={() => setOpen(!open)}
											/>
											<a className='navbar-brand' onClick={goToHome}>
												<picture>
													<source
														media='(max-width: 768px)'
														srcSet={`${
															ENTERPRISE_IMAGE_MOBILE
																? ENTERPRISE_IMAGE_MOBILE
																: '/images/everylog-180.png'
														}`}
													/>
													<img
														className='logo-mediaq w-md-auto logo ms-2'
														src={`${
															ENTERPRISE_IMAGE
																? ENTERPRISE_IMAGE
																: '/images/everylog-logo.svg'
														}`}
													/>
												</picture>
											</a>
										</div>

										<ul className='navbar-nav d-flex flex-row justify-content-center d-md-flex justify-content-md-end flex-lg-grow-0 justify-content-xl-end'>
											{!ENTERPRISE && (
												<li className='me-4 m-auto d-none d-md-block'>
													<label
														style={{
															height: '50px',
															width: '50px',
															borderRadius: '50%',
														}}
													>
														<img
															alt='image'
															style={{
																objectFit: 'contain',
																width: '100%',
																height: '100%',
															}}
															src={
																user.image === ''
																	? '/images/everylog-180.png'
																	: user.image
															}
														/>
													</label>
												</li>
											)}
											{!ENTERPRISE && (
												<li className='me-3 m-auto'>
													<FormControl
														sx={{
															'& .MuiOutlinedInput-root': {
																'& > fieldset': {
																	border: `2px solid  ${
																		user.active
																			? user.notificationsEnabled
																				? theme.palette.primary.main
																				: theme.palette.error.main
																			: theme.palette.error.main
																	}`,
																},
															},
															'& label': {
																color: user.active
																	? user.notificationsEnabled
																		? theme.palette.primary.main
																		: theme.palette.error.main
																	: theme.palette.error.main,
															},
															'& label.Mui-focused': {
																color: user.active
																	? user.notificationsEnabled
																		? theme.palette.primary.main
																		: theme.palette.error.main
																	: theme.palette.error.main,
															},
															'& .MuiOutlinedInput-root: hover': {
																'& > fieldset': {
																	borderColor: user.active
																		? user.notificationsEnabled
																			? theme.palette.primary.main
																			: theme.palette.error.main
																		: theme.palette.error.main,
																},
															},
															'& .MuiOutlinedInput-root.Mui-focused': {
																'& > fieldset': {
																	borderColor: user.active
																		? user.notificationsEnabled
																			? theme.palette.primary.main
																			: theme.palette.error.main
																		: theme.palette.error.main,
																},
															},
														}}
													>
														<InputLabel shrink>Selected Account</InputLabel>
														<Select
															label='Selected Account'
															onChange={(e) =>
																changeCurrentAccount(e.target.value)
															}
															value={selectedAccount}
															sx={{
																width: {
																	xs: '130px',
																	sm: 'auto',
																},
																height: '45px',
																'& .MuiOutlinedInput-root': {
																	'& > fieldset': {
																		border: `2px solid  ${
																			user.active
																				? user.notificationsEnabled
																					? theme.palette.primary.main
																					: theme.palette.error.main
																				: theme.palette.error.main
																		}`,
																	},
																},
															}}
															MenuProps={{
																sx: {
																	'&& .Mui-selected': {
																		backgroundColor: user.active
																			? user.notificationsEnabled
																				? theme.palette.primary.light
																				: theme.palette.error.light
																			: theme.palette.error.light,
																	},
																	'&& .Mui-selected:hover': {
																		backgroundColor: user.active
																			? user.notificationsEnabled
																				? theme.palette.primary.light
																				: theme.palette.error.light
																			: theme.palette.error.light,
																	},
																},
															}}
														>
															{user.accounts.map((account, i) => (
																<MenuItem
																	sx={{
																		'&:hover': {
																			backgroundColor: account.active
																				? account.notificationsEnabled
																					? theme.palette.primary.light
																					: theme.palette.error.light
																				: theme.palette.error.light,
																		},
																	}}
																	selected={
																		account.accountId === user.currentAccount
																	}
																	key={i}
																	value={account.accountId}
																>
																	{account.accountName}
																</MenuItem>
															))}
														</Select>
													</FormControl>
													{/* <Form.Select
														className={`${
															user.notificationsEnabled
																? 'border-success'
																: 'border-danger'
														} ${user.owner && 'fw-bold'}`}
														onChange={(e) =>
															changeCurrentAccount(e.target.value)
														}
													>
														{user.accounts.map((account, i) => {
															if (account.accountId === user.currentAccount) {
																return (
																	<option
																		key={i}
																		selected
																		value={account.accountId}
																	>
																		{account.accountName}{' '}
																	</option>
																);
															} else {
																return (
																	<option key={i} value={account.accountId}>
																		{account.accountName}
																	</option>
																);
															}
														})}
													</Form.Select> */}
												</li>
											)}
											<li ref={refUser} className='m-auto position-relative'>
												<FontAwesomeIcon
													icon={faCircleUser}
													className='user-icon fs-2'
													onClick={() => {
														displayUserMenu();
														setOpen(false);
													}}
												/>
												<div
													className={`position-absolute status-circle ${
														user.notificationsEnabled ? 'green' : 'red'
													}`}
												></div>
												<div
													className={`${userIcon} usericon-drop position-absolute px-2`}
													ref={refUser}
												>
													<div className='usericon-items p-1 font-20 fw-bold'>
														{user.name}
													</div>
													<div className='usericon-items p-1'>
														<Link
															to='/user/edit'
															className='user-menu font-16'
															id='user-edit'
															title={t('privateLayout.editUser')}
															onClick={() => {
																setUserIcon('d-none');
																setActiveUser('');
															}}
														>
															{' '}
															{t('privateLayout.manageUser')}
														</Link>
													</div>
													{user.role === 'admin' && !ENTERPRISE && (
														<div className='usericon-items p-1'>
															<Link
																to='/account/edit'
																className='font-16 user-menu'
																id='account-edit'
																title={t('privateLayout.billingDetails')}
																onClick={() => {
																	setUserIcon('d-none');
																	setActiveUser('');
																}}
															>
																{' '}
																{t('privateLayout.managePayment')}
															</Link>
														</div>
													)}
													{user.role === 'admin' && !ENTERPRISE && (
														<div className='usericon-items p-1'>
															<Link
																to={'/subscription'}
																className='user-menu font-16'
																onClick={() => {
																	setUserIcon('d-none');
																	setActiveUser('');
																}}
															>
																{t('privateLayout.managePlan')}
															</Link>
														</div>
													)}
													<div className='usericon-items p-1'>
														<div
															href='#'
															onClick={logout}
															className='font-16 user-menu'
															id='logout'
															title={t('privateLayout.logout')}
														>
															{t('privateLayout.logout')}
														</div>
													</div>
												</div>
											</li>
										</ul>
									</Navbar.Brand>

									{/* mobile button - right bar */}
									<Navbar.Offcanvas
										id={`offcanvasNavbar-expand-${expand}`}
										aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
										placement='start'
										className='bg-dark text-white w-300'
									>
										<Offcanvas.Header>
											<div onClick={() => setOpen(false)}>
												<FontAwesomeIcon icon={faXmark} className='fs-2' />
											</div>
										</Offcanvas.Header>
										<Offcanvas.Body>
											<Nav>
												<div
													className={`d-flex flex-column justify-content-lg-start gap-4 ${pt} m-0-auto`}
												>
													{/* dashboard */}
													<div className={`d-flex align-items-baseline fs-4`}>
														<Link
															className='text-decoration-none text-white'
															style={{ width: '35px' }}
															to='/dashboard'
															title={t('privateLayout.projects')}
															onClick={() => setOpen(!open)}
														>
															<FontAwesomeIcon
																icon={faHouse}
																className='me-2 fs-5'
															/>
														</Link>
														<Link
															to='/dashboard'
															className={`font-20 d-md-none d-lg-block text-decoration-none text-white link-aside rounded-2 p-1 ${isActive(
																'dashboard'
															)}`}
															title={t('privateLayout.projects')}
															onClick={() => setOpen(false)}
														>
															DASHBOARD
														</Link>
													</div>

													{/* projects */}
													<div className={`d-flex align-items-baseline fs-4`}>
														<Link
															className='text-decoration-none text-white'
															style={{ width: '35px' }}
															to='/projects'
															title={t('privateLayout.projects')}
															onClick={() => setOpen(!open)}
														>
															<FontAwesomeIcon
																icon={faBriefcase}
																className='me-2 fs-5'
															/>
														</Link>
														<Link
															to='/projects'
															className={`font-20 d-md-none d-lg-block text-decoration-none text-white link-aside rounded-2 p-1 ${isActive(
																'projects'
															)}`}
															title={t('privateLayout.projects')}
															onClick={() => setOpen(false)}
														>
															{t('privateLayout.projects')}
														</Link>
													</div>

													{/* users */}
													{user.role !== 'external' &&
														user.account.planType !== 'starter' && (
															<div className='d-flex align-items-baseline fs-4'>
																<Link
																	className={`text-decoration-none ${
																		user.role === 'external'
																			? 'text-secondary'
																			: 'text-white'
																	}`}
																	style={{ width: '35px' }}
																	to='/users'
																	title={t('privateLayout.users')}
																	onClick={() => setOpen(!open)}
																>
																	<FontAwesomeIcon
																		icon={faUser}
																		className='me-2 fs-5'
																	/>
																</Link>
																<Link
																	to='/users'
																	className={`font-20 d-md-none d-lg-block text-decoration-none link-aside rounded-2 p-1 ${
																		user.role === 'external'
																			? 'text-secondary'
																			: 'text-white'
																	} ${isActive('users')}`}
																	title={t('privateLayout.users')}
																	onClick={() => setOpen(false)}
																>
																	{t('privateLayout.usersMenu')}
																</Link>
															</div>
														)}

													{/* groups */}
													{user.role !== 'external' &&
														user.account.planType !== 'starter' && (
															<div className='d-flex align-items-baseline fs-4'>
																<Link
																	className={`text-decoration-none ${
																		user.role === 'external'
																			? 'text-secondary'
																			: 'text-white'
																	}`}
																	style={{ width: '35px' }}
																	title={t('privateLayout.groups')}
																	onClick={() => setOpen(!open)}
																	to={() => {
																		switch (user.role) {
																			case 'collaborator':
																				return '/groups';
																			default:
																				return '/groups';
																		}
																	}}
																>
																	<FontAwesomeIcon
																		icon={faUsers}
																		className='me-2 fs-5'
																	/>
																</Link>
																<Link
																	to={() => {
																		switch (user.role) {
																			case 'collaborator':
																				return '/groups';
																			default:
																				return '/groups';
																		}
																	}}
																	className={`font-20 d-md-none d-lg-block text-decoration-none ${
																		user.role === 'external'
																			? 'text-secondary'
																			: 'text-white'
																	} link-aside rounded-2 p-1 ${isActive(
																		'groups'
																	)}`}
																	title={t('privateLayout.groups')}
																	onClick={() => setOpen(false)}
																>
																	{t('privateLayout.groups')}
																</Link>
															</div>
														)}

													{/* channels */}
													{user.role !== 'external' &&
														(user.account.planType == 'premium' ||
															user.account.planType == 'enterprise') && (
															<div className='d-flex align-items-baseline fs-4'>
																<Link
																	className={`text-decoration-none ${
																		user.role === 'external'
																			? 'text-secondary'
																			: 'text-white'
																	}`}
																	style={{ width: '35px' }}
																	to='/channels'
																	title='channels'
																	onClick={() => setOpen(!open)}
																>
																	<FontAwesomeIcon
																		icon={faShareNodes}
																		className='me-2 fs-5'
																	/>
																</Link>
																<Link
																	to='/channels'
																	className={`font-20 d-md-none d-lg-block text-decoration-none ${
																		user.role === 'external'
																			? 'text-secondary'
																			: 'text-white'
																	} link-aside rounded-2 p-1 ${isActive(
																		'channels'
																	)}`}
																	id='channels'
																	title='channel'
																>
																	{t('privateLayout.channels')}
																</Link>
															</div>
														)}

													{/* invitations */}
													{user.role === 'admin' &&
														user.account.planType != 'starter' && (
															<div className='d-flex align-items-baseline fs-4'>
																<Link
																	className={`text-decoration-none ${
																		user.role === 'external'
																			? 'text-secondary'
																			: 'text-white'
																	}`}
																	style={{ width: '35px' }}
																	to='/invitations'
																	title='INVITATIONS'
																	onClick={() => setOpen(!open)}
																>
																	<FontAwesomeIcon
																		icon={faEnvelope}
																		className='me-2 fs-5'
																	/>
																</Link>
																<Link
																	to='/invitations'
																	className={`font-20 d-md-none d-lg-block text-decoration-none ${
																		user.role === 'external'
																			? 'text-secondary'
																			: 'text-white'
																	} link-aside rounded-2 p-1 ${isActive(
																		'invitations'
																	)}`}
																	id='channels'
																	title='INVITATIONS'
																>
																	{t(
																		'invitationsPage.invitations'
																	).toUpperCase()}
																</Link>
															</div>
														)}

													{/* send notification */}
													{user.role != 'external' && (
														<div className='d-flex align-items-baseline fs-4'>
															<Link
																className={`text-decoration-none ${
																	user.role === 'external'
																		? 'text-secondary'
																		: 'text-white'
																}`}
																style={{ width: '35px' }}
																to='/send-notification'
																title='SEND NOTIFICATION'
																onClick={() => setOpen(!open)}
															>
																<FontAwesomeIcon
																	icon={faBell}
																	className='me-2 fs-5'
																/>
															</Link>
															<Link
																to='/send-notification'
																className={`font-20 d-md-none d-lg-block text-decoration-none ${
																	user.role === 'external'
																		? 'text-secondary'
																		: 'text-white'
																} link-aside rounded-2 p-1 ${isActive(
																	'sendNotification'
																)}`}
																id='channels'
																title='SEND NOTIFICATION'
															>
																{t('notifyPage.pageTitle').toUpperCase()}
															</Link>
														</div>
													)}

													{/* docs */}
													<div className='d-flex align-items-baseline fs-4'>
														<Link
															to='#'
															className='text-decoration-none text-white'
															style={{ width: '35px' }}
															onClick={() => {
																openInNewTab('https://docs.everylog.io/');
																setOpen(!open);
															}}
														>
															<FontAwesomeIcon
																icon={faCircleInfo}
																className='me-2 fs-5'
															/>
														</Link>
														<Link
															to='#'
															onClick={() =>
																openInNewTab('https://docs.everylog.io/')
															}
															className={`font-20 d-md-none d-lg-block text-decoration-none text-white link-aside rounded-2 p-1`}
														>
															DOCS
														</Link>
													</div>
												</div>
											</Nav>
										</Offcanvas.Body>
									</Navbar.Offcanvas>
								</Container>
							</Navbar>
						))}
					</>
				</header>
				<div className='d-flex flex-row h-100'>
					{/* left-side-menu */}
					<div
						className='ps-4 pt-3 text-white bg-dark d-md-flex flex-column justify-content-between hide-on-mobile mediaq-side'
						id='navcol-1'
					>
						<div
							className={`d-flex flex-column justify-content-lg-start  gap-4 ${pt} m-0-auto`}
						>
							{/* dashboard */}
							<div className={`d-flex align-items-center fs-4 mt-md-5`}>
								<Link
									className='text-decoration-none text-white'
									style={{ width: '35px' }}
									to='/dashboard'
									title='DASHBOARD'
								>
									<FontAwesomeIcon icon={faHouse} className='me-2 fs-5' />
								</Link>
								<Link
									to='/dashboard'
									className={`font-20 d-md-none d-lg-block text-decoration-none text-white link-aside rounded-2 p-1 ${isActive(
										'dashboard'
									)}`}
									id='dashboard'
									title='DASHBOARD'
								>
									DASHBOARD
								</Link>
							</div>

							{/* projects */}
							<div className={`d-flex align-items-center fs-4 `}>
								<Link
									className='text-decoration-none text-white'
									style={{ width: '35px' }}
									to='/projects'
									title={t('privateLayout.projects')}
								>
									<FontAwesomeIcon icon={faBriefcase} className='me-2 fs-5' />
								</Link>
								<Link
									to='/projects'
									className={`font-20 d-md-none d-lg-block text-decoration-none text-white link-aside rounded-2 p-1 ${isActive(
										'projects'
									)}`}
									id='projects'
									title={t('privateLayout.projects')}
								>
									{t('privateLayout.projects')}
								</Link>
							</div>

							{/* users */}
							{user.role !== 'external' &&
								user.account.planType !== 'starter' && (
									<div className='d-flex align-items-center fs-4'>
										<Link
											className={`text-decoration-none ${
												user.role === 'external'
													? 'text-secondary'
													: 'text-white'
											}`}
											style={{ width: '35px' }}
											to='/users'
											title={t('privateLayout.users')}
										>
											<FontAwesomeIcon icon={faUser} className='me-2 fs-5' />
										</Link>
										<Link
											to='/users'
											className={`font-20 d-md-none d-lg-block text-decoration-none link-aside rounded-2 p-1 ${isActive(
												'users'
											)} ${
												user.role === 'external'
													? 'text-secondary'
													: 'text-white'
											}`}
											id='users-edit'
											title={t('privateLayout.users')}
										>
											{t('privateLayout.usersMenu')}
										</Link>
									</div>
								)}

							{/* groups */}
							{user.role !== 'external' &&
								user.account.planType !== 'starter' && (
									<div className='d-flex align-items-center fs-4'>
										<Link
											className={`text-decoration-none ${
												user.role === 'external'
													? 'text-secondary'
													: 'text-white'
											}`}
											style={{ width: '35px' }}
											title={t('privateLayout.groups')}
											to={() => {
												switch (user.role) {
													case 'collaborator':
														return '/groups';
													default:
														return '/groups';
												}
											}}
										>
											<FontAwesomeIcon icon={faUsers} className='me-2 fs-5' />
										</Link>
										<Link
											to={() => {
												switch (user.role) {
													case 'collaborator':
														return '/groups';
													default:
														return '/groups';
												}
											}}
											className={`font-20  d-md-none d-lg-block text-decoration-none link-aside rounded-2 p-1 ${
												user.role === 'external'
													? 'text-secondary'
													: 'text-white'
											} ${isActive('groups')}`}
											id='groups'
											title={t('privateLayout.groups')}
										>
											{' '}
											{t('privateLayout.groups')}
										</Link>
									</div>
								)}

							{/* channels */}
							{user.role !== 'external' &&
								(user.account.planType == 'premium' ||
									user.account.planType == 'enterprise') && (
									<div className='d-flex align-items-center fs-4'>
										<Link
											className={`text-decoration-none ${
												user.role === 'external'
													? 'text-secondary'
													: 'text-white'
											} `}
											style={{ width: '35px' }}
											to='/channels'
											title={t('privateLayout.channels')}
										>
											<FontAwesomeIcon
												icon={faShareNodes}
												className='me-2 fs-5'
											/>
										</Link>
										<Link
											to='/channels'
											className={`font-20 d-md-none d-lg-block text-decoration-none link-aside rounded-2 p-1 ${
												user.role === 'external'
													? 'text-secondary'
													: 'text-white'
											} ${isActive('channels')}`}
											id='channels'
											title={t('privateLayout.channels')}
										>
											{t('privateLayout.channels')}
										</Link>
									</div>
								)}

							{/* invitations */}
							{user.role == 'admin' && user.account.planType != 'starter' && (
								<div className='d-flex align-items-center fs-4'>
									<Link
										className={`text-decoration-none ${
											user.role === 'external' ? 'text-secondary' : 'text-white'
										} `}
										style={{ width: '35px' }}
										to='/invitations'
										title={t('privateLayout.invitations')}
									>
										<FontAwesomeIcon icon={faEnvelope} className='me-2 fs-5' />
									</Link>
									<Link
										to='/invitations'
										className={`font-20 d-md-none d-lg-block text-decoration-none link-aside rounded-2 p-1 ${
											user.role === 'external' ? 'text-secondary' : 'text-white'
										} ${isActive('invitations')}`}
										id='channels'
										title={t('privateLayout.invitations')}
									>
										{t('privateLayout.invitations').toUpperCase()}
									</Link>
								</div>
							)}

							{/* send notification */}
							{user.role !== 'external' && (
								<div className='d-flex align-items-center fs-4'>
									<Link
										className={`text-decoration-none ${
											user.role === 'external' ? 'text-secondary' : 'text-white'
										} `}
										style={{ width: '35px' }}
										to='/send-notification'
										title={t('privateLayout.notification')}
									>
										<FontAwesomeIcon icon={faBell} className='me-2 fs-5' />
									</Link>
									<Link
										to='/send-notification'
										className={`font-20 d-md-none d-lg-block text-decoration-none link-aside rounded-2 p-1 ${
											user.role === 'external' ? 'text-secondary' : 'text-white'
										} ${isActive('sendNotification')}`}
										id='channels'
										title={t('privateLayout.notification')}
									>
										{t('privateLayout.notification').toUpperCase()}
									</Link>
								</div>
							)}

							{/* Docs page */}
							<div className='d-flex align-items-baseline fs-4'>
								<Link
									to='#'
									className='text-decoration-none text-white'
									style={{ width: '35px' }}
									onClick={() => openInNewTab('https://docs.everylog.io/')}
								>
									<FontAwesomeIcon icon={faCircleInfo} className='me-2 fs-5' />
								</Link>
								<Link
									to='#'
									onClick={() => openInNewTab('https://docs.everylog.io/')}
									className={`font-20 d-md-none d-lg-block text-decoration-none text-white link-aside rounded-2 p-1`}
									id='docs'
								>
									DOCS
								</Link>
							</div>
						</div>
						<FontAwesomeIcon
							onClick={() => setEnabled(true)}
							icon={faCircleQuestion}
							className='d-none d-lg-block align-self-start info-help'
							id='info-help'
						/>
					</div>
					<Container className='first-container container-width'>
						{children}
					</Container>
				</div>
			</div>
			<BaseFooter />
		</div>
	);
};

export default PrivateLayout;
