import React, { useEffect, useRef, useState } from 'react';
import { Channels } from 'api/queries';
import Loader from 'app/components/Loader';
import { useQuery } from 'react-query';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import { activeMenuState } from 'libs/atoms';
import { useRecoilState } from 'recoil';
import { Link, useHistory } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { DeleteChannel, CreateChannel, UpdateChannel } from 'api/mutations';
import confirmAlert from 'libs/confirmAlert';
import { useTranslation } from 'react-i18next';
import { formatErrors } from 'libs/utils';
import useOnClickOutside from 'hoc/onClickOutsude';
import axios from 'libs/axios';
import { Steps } from 'intro.js-react';
import {
	FormControl,
	Icon,
	InputLabel,
	MenuItem,
	Select,
	SvgIcon,
	TextField,
	Button as ButtonMUI,
} from '@mui/material';

export const selectOptions = [
	{
		value: 'slack',
		name: 'Slack',
		svg: (
			<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
				<path d='M94.12 315.1c0 25.9-21.16 47.06-47.06 47.06S0 341 0 315.1c0-25.9 21.16-47.06 47.06-47.06h47.06v47.06zm23.72 0c0-25.9 21.16-47.06 47.06-47.06s47.06 21.16 47.06 47.06v117.84c0 25.9-21.16 47.06-47.06 47.06s-47.06-21.16-47.06-47.06V315.1zm47.06-188.98c-25.9 0-47.06-21.16-47.06-47.06S139 32 164.9 32s47.06 21.16 47.06 47.06v47.06H164.9zm0 23.72c25.9 0 47.06 21.16 47.06 47.06s-21.16 47.06-47.06 47.06H47.06C21.16 243.96 0 222.8 0 196.9s21.16-47.06 47.06-47.06H164.9zm188.98 47.06c0-25.9 21.16-47.06 47.06-47.06 25.9 0 47.06 21.16 47.06 47.06s-21.16 47.06-47.06 47.06h-47.06V196.9zm-23.72 0c0 25.9-21.16 47.06-47.06 47.06-25.9 0-47.06-21.16-47.06-47.06V79.06c0-25.9 21.16-47.06 47.06-47.06 25.9 0 47.06 21.16 47.06 47.06V196.9zM283.1 385.88c25.9 0 47.06 21.16 47.06 47.06 0 25.9-21.16 47.06-47.06 47.06-25.9 0-47.06-21.16-47.06-47.06v-47.06h47.06zm0-23.72c-25.9 0-47.06-21.16-47.06-47.06 0-25.9 21.16-47.06 47.06-47.06h117.84c25.9 0 47.06 21.16 47.06 47.06 0 25.9-21.16 47.06-47.06 47.06H283.1z' />
			</svg>
		),
	},
	{
		value: 'discord',
		name: 'Discord',
		svg: (
			<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'>
				<path d='M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z' />
			</svg>
		),
	},
	{
		value: 'telegram',
		name: 'Telegram',
		svg: (
			<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 496 512'>
				<path d='M248,8C111.033,8,0,119.033,0,256S111.033,504,248,504,496,392.967,496,256,384.967,8,248,8ZM362.952,176.66c-3.732,39.215-19.881,134.378-28.1,178.3-3.476,18.584-10.322,24.816-16.948,25.425-14.4,1.326-25.338-9.517-39.287-18.661-21.827-14.308-34.158-23.215-55.346-37.177-24.485-16.135-8.612-25,5.342-39.5,3.652-3.793,67.107-61.51,68.335-66.746.153-.655.3-3.1-1.154-4.384s-3.59-.849-5.135-.5q-3.283.746-104.608,69.142-14.845,10.194-26.894,9.934c-8.855-.191-25.888-5.006-38.551-9.123-15.531-5.048-27.875-7.717-26.8-16.291q.84-6.7,18.45-13.7,108.446-47.248,144.628-62.3c68.872-28.647,83.183-33.623,92.511-33.789,2.052-.034,6.639.474,9.61,2.885a10.452,10.452,0,0,1,3.53,6.716A43.765,43.765,0,0,1,362.952,176.66Z' />
			</svg>
		),
	},
	{
		value: 'email',
		name: 'Email',
		svg: (
			<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
				<path d='M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z' />
			</svg>
		),
	},
	{ value: 'http', name: 'Webhook', svg: null },
];

export default function ChannelsPage({ user }) {
	const [activeMenu, setActiveMenu] = useRecoilState(activeMenuState);

	useEffect(() => {
		setActiveMenu('channels');
	}, []);

	const { t } = useTranslation();

	const [deletePopup, setDeletePopup] = useState(false);
	const [createChannelPopup, setCreateChannelPopup] = useState(false);
	const [channelId, setChannelId] = useState(undefined);
	const [errorMessage, seterrorMessage] = useState();
	const [errorCreate, setErrorCreate] = useState(false);
	const [help, setHelp] = useState(false);
	const [sortbyList, setSortbyList] = useState({
		name: true,
		code: true,
		type: true,
	});

	const refDeleteChannel = useRef();

	const [channelData, setChannelData] = useState({
		name: undefined,
		code: undefined,
		type: 'Type ',
	});

	const history = useHistory();

	const { isLoading, error, data } = useQuery(
		['channels', user.currentAccount],
		Channels
	);

	const queryClient = useQueryClient();

	const deleteChannelMutation = useMutation(DeleteChannel, {
		onSuccess: () => {
			queryClient.invalidateQueries(['channels', user.currentAccount]);
		},
	});

	const createChannelMutate = useMutation(CreateChannel, {
		onSuccess: () => {
			queryClient.invalidateQueries(['channels', user.currentAccount]);
		},
	});

	const enableChannelMutate = useMutation(UpdateChannel, {
		onSuccess: () => {
			queryClient.invalidateQueries(['channels', user.currentAccount]);
		},
	});

	const handleEnableChannel = async (channelId, enable) => {
		const channel = await axios.authenticated().get(`/channels/${channelId}`);
		let data = {
			...channel.data,
			enable: !enable,
		};
		try {
			const response = await enableChannelMutate.mutateAsync({
				channelId: channelId,
				data: data,
			});
		} catch (error) {
			if (error.response?.data) {
				confirmAlert.error(error.response.data);
				return;
			}
		}
	};

	const deleteChannel = async () => {
		const data = channelId;
		try {
			const response = await deleteChannelMutation.mutateAsync(data);
			if (response) {
				confirmAlert.success(t('channelsPage.channelDeleted'));
			}
		} catch (error) {
			if (error.response && error.response.data) {
				confirmAlert.error(error.response.data);
				return;
			}
		}
	};

	const handleSubmit = async (data) => {
		handleClose();
		try {
			const response = await createChannelMutate.mutateAsync(data);
			if (response) {
				confirmAlert.success(t('channelsPage.channelCreated'));
				history.push(`/channels/${response.data.id}`);
			}
		} catch (error) {
			if (error.response && error.response.data) {
				let msg = formatErrors(error.response.data);
				seterrorMessage(msg);
				setErrorCreate(true);
				return;
			}
		}
	};

	const kebabCase = (string) =>
		string
			.replace(/([a-z])([A-Z])/g, '$1-$2')
			.replace(/[\s_]+/g, '-')
			.toLowerCase();

	const handleChannelNameInput = (event) => {
		setChannelData({ ...channelData, name: event.target.value });
	};

	const handleChannelCodeInput = (event) => {
		const kebabCode = kebabCase(event.target.value);
		setChannelData({ ...channelData, code: event.target.value });
	};

	const handleClose = () => {
		setCreateChannelPopup(false);
	};

	const channelType = (type) => {
		switch (type) {
			case 'telegram':
				return 'Telegram';
			case 'slack':
				return 'Slack';
			case 'discord':
				return 'Discord';
			case 'email':
				return 'Email';
			case 'http':
				return 'WEBHOOK';
			default:
				return t('channelsPage.type');
		}
	};

	useOnClickOutside(refDeleteChannel, () => {
		setDeletePopup(false);
	});

	const options = {
		nextLabel: t('guideButtons.next'),
		prevLabel: t('guideButtons.prev'),
		doneLabel: t('guideButtons.done'),
	};

	let stepsUsers = [
		{
			element: '#create-new-channel',
			intro: (
				<div>
					<h5 style={{ fontSize: '20px', fontWeight: '700' }}>
						{t('channelGuide.firstStepTitle')}
					</h5>
					<div className='pb-0'>{t('channelGuide.firstStep')}</div>
				</div>
			),
		},
		{
			intro: (
				<div>
					<h5 style={{ fontSize: '20px', fontWeight: '700' }}>
						{t('channelGuide.secondStepTitle')}
					</h5>
					<div className='pb-0'>{t('channelGuide.secondStep')}</div>
				</div>
			),
		},
		{
			element: '#edit-channel',
			intro: (
				<div>
					<h5 style={{ fontSize: '20px', fontWeight: '700' }}>
						{t('channelGuide.thirdStepTitle')}
					</h5>
					<div className='pb-0'>{t('channelGuide.thirdStep')}</div>
				</div>
			),
		},
		{
			element: '#delete-channel',
			intro: (
				<div>
					<h5 style={{ fontSize: '20px', fontWeight: '700' }}>
						{t('channelGuide.fourthStepTitle')}
					</h5>
					<div className='pb-0'>{t('channelGuide.fourthStep')}</div>
				</div>
			),
		},
	];

	const onExit = () => {
		setHelp(false);
	};

	const sortBy = (by) => {
		switch (by) {
			case 'name':
				if (sortbyList.name) {
					data.data.sort((a, b) => {
						const titleA = a.name.toUpperCase();
						const titleB = b.name.toUpperCase();
						if (titleA < titleB) {
							return -1;
						}
						if (titleA > titleB) {
							return 1;
						}
						return 0;
					});
				} else {
					data.data.sort((a, b) => {
						const titleA = a.name.toUpperCase();
						const titleB = b.name.toUpperCase();
						if (titleA > titleB) {
							return -1;
						}
						if (titleA < titleB) {
							return 1;
						}
						return 0;
					});
				}
				setSortbyList({ ...sortbyList, name: !sortbyList.name });
				break;

			case 'code':
				if (sortbyList.code) {
					data.data.sort((a, b) => {
						const titleA = a.code.toUpperCase();
						const titleB = b.code.toUpperCase();
						if (titleA < titleB) {
							return -1;
						}
						if (titleA > titleB) {
							return 1;
						}
						return 0;
					});
				} else {
					data.data.sort((a, b) => {
						const titleA = a.code.toUpperCase();
						const titleB = b.code.toUpperCase();
						if (titleA > titleB) {
							return -1;
						}
						if (titleA < titleB) {
							return 1;
						}
						return 0;
					});
				}
				setSortbyList({ ...sortbyList, code: !sortbyList.code });
				break;

			case 'type':
				if (sortbyList.type) {
					data.data.sort((a, b) => {
						const titleA = a.type.toUpperCase();
						const titleB = b.type.toUpperCase();
						if (titleA < titleB) {
							return -1;
						}
						if (titleA > titleB) {
							return 1;
						}
						return 0;
					});
				} else {
					data.data.sort((a, b) => {
						const titleA = a.type.toUpperCase();
						const titleB = b.type.toUpperCase();
						if (titleA > titleB) {
							return -1;
						}
						if (titleA < titleB) {
							return 1;
						}
						return 0;
					});
				}
				setSortbyList({ ...sortbyList, type: !sortbyList.type });
				break;

			default:
				break;
		}
	};

	if (isLoading) {
		return <Loader />;
	}

	return (
		<div className='max-width m-0-auto'>
			<Steps
				enabled={help}
				steps={stepsUsers}
				onExit={onExit}
				initialStep={0}
				options={options}
			/>
			<Modal
				show={createChannelPopup}
				centered
				onHide={() => setCreateChannelPopup(false)}
			>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						handleSubmit(channelData);
					}}
					onReset={(e) => {
						e.preventDefault();
						setCreateChannelPopup(false);
						setChannelData({ code: null, name: null, type: 'Type' });
					}}
				>
					<div className='popup-crea-gruppo p-4'>
						<TextField
							required
							onChange={(event) => handleChannelNameInput(event)}
							type={'text'}
							label={t('channelsPage.channelName')}
							variant='outlined'
							value={channelData.name}
							fullWidth
						/>
						<TextField
							required
							onChange={(event) => handleChannelCodeInput(event)}
							type={'text'}
							variant='outlined'
							label={t('channelsPage.channelCode')}
							value={channelData.code}
							fullWidth
						/>
						<FormControl>
							<InputLabel id='select-channel'>
								{t('channelsPage.type')}
							</InputLabel>
							<Select
								labelId='select-channel'
								required
								label='channel'
								onChange={(event) =>
									setChannelData({
										...channelData,
										type: event.target.value,
									})
								}
							>
								{selectOptions.map((el) => (
									<MenuItem key={el.value} value={el.value}>
										<SvgIcon sx={{ mr: '10px' }}>{el.svg}</SvgIcon>
										{el.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<div className='d-flex justify-content-center gap-5'>
							<ButtonMUI type='reset' color='error' variant='contained'>
								{t('createUsersPage.cancel')}
							</ButtonMUI>
							{/* <Button
								type='reset'
								className='group-cancel-button d-flex justify-content-center align-items-center'
							>
								{t('createUsersPage.cancel')}
							</Button> */}
							<ButtonMUI
								type='submit'
								variant='contained'
								sx={{ width: '100px' }}
							>
								{t('createUsersPage.save')}
							</ButtonMUI>
							{/* <Button
								type='submit'
								className='create-group-button d-flex justify-content-center align-items-center'
							>
								{t('createUsersPage.save')}
							</Button> */}
						</div>
					</div>
				</form>
			</Modal>
			<Modal show={deletePopup} centered>
				<div className='delete-popup font-18' ref={refDeleteChannel}>
					<div className='text-center m-3'>
						{t('channelsPage.deleteChannel')}
					</div>
					<div className='d-flex justify-content-center gap-5 mb-3'>
						<div
							onClick={() => {
								deleteChannel();
								setDeletePopup(false);
							}}
							className='group-cancel-button d-flex justify-content-center align-items-center'
						>
							{t('groupsPage.yes')}
						</div>
						<div
							onClick={() => setDeletePopup(false)}
							className='group-cancel-button d-flex justify-content-center align-items-center'
						>
							{t('groupsPage.no')}
						</div>
					</div>
				</div>
			</Modal>
			<Modal
				show={errorCreate}
				centered
				className='mt-5'
				onHide={() => setErrorCreate(false)}
			>
				<div className='d-flex flex-column align-content-center'>
					<div className='text-center p-3'>{errorMessage}</div>
					<div
						className=' text-center p-1 pointer-hover ok-alert-button m-auto mb-3 '
						onClick={() => {
							setErrorCreate(false);
							setCreateChannelPopup(true);
						}}
					>
						ok
					</div>
				</div>
			</Modal>
			<div className='d-flex gap-2'>
				<h1 className='title-font'>{t('channelsPage.pageTitle')}</h1>
				<FontAwesomeIcon
					icon={faCircleQuestion}
					className='text-blue pointer-hover fs-5'
					onClick={() => setHelp(true)}
				/>
			</div>
			<div className='dashboard-box mt-4 py-4 px-5'>
				<div className='d-flex justify-content-between align-items-center mb-4'>
					<div>
						{data && data.data.length === 0 && (
							<h2 className='font-20 fw-bold'>{t('channelsPage.noChannel')}</h2>
						)}
					</div>
					<ButtonMUI
						variant='contained'
						id='create-new-channel'
						onClick={() => {
							setCreateChannelPopup(true);
						}}
					>
						{t('channelsPage.createChannel')}
					</ButtonMUI>
					{/* <div
						id='create-new-channel'
						style={{ color: 'white', backgroundColor: '#72d830' }}
						className='table-row-color mt-3 create-group-button d-flex justify-content-center align-items-center '
						onClick={() => {
							setCreateChannelPopup(true);
						}}
					>
						{t('channelsPage.createChannel')}
					</div> */}
				</div>
				{data && data.data.length !== 0 && (
					<Table responsive bordered>
						<thead>
							<tr className='d-none d-md-table-row'>
								<th className='col-1 text-start font-20'>
									<span
										className='pointer-hover'
										onClick={() => sortBy('name')}
									>
										{t('channelsPage.name')}
									</span>
								</th>
								<th className='col-1 text-start font-20'>
									<span
										className='pointer-hover'
										onClick={() => sortBy('code')}
									>
										{t('channelsPage.code')}
									</span>
								</th>
								<th className='col-1 text-start font-20'>
									<span
										className='pointer-hover'
										onClick={() => sortBy('type')}
									>
										{t('channelsPage.type')}
									</span>
								</th>
								<th className='col-1 text-start font-20'>
									{t('channelsPage.action')}
								</th>
							</tr>
						</thead>
						<tbody>
							{data &&
								data.data.map((element, i) => (
									<tr
										key={`channel-${i}`}
										className='table-row-color font-14 d-flex flex-column d-md-table-row'
									>
										<div className='d-md-none font-16 fw-bold pb-0'>
											{t('channelsPage.name')}:
										</div>
										<td className='text-start vertical-align border-0'>
											{element.name}
										</td>
										<div className='d-md-none font-16 fw-bold pb-0'>
											{t('channelsPage.code')}:
										</div>
										<td className='text-start vertical-align border-0'>
											{element.code}
										</td>
										<div className='d-md-none font-16 fw-bold pb-0'>
											{t('channelsPage.type')}:
										</div>
										<td className='text-start vertical-align border-0'>
											{channelType(element.type)}
										</td>
										<div className='d-md-none font-16 fw-bold pb-0'>
											{t('channelsPage.action')}:
										</div>
										<td className='border-0'>
											<div className='d-flex justify-content-start gap-3'>
												<ButtonMUI
													variant='contained'
													color='secondary'
													onClick={() =>
														history.push(`/channels/${element.id}`)
													}
													id={i === 0 ? 'edit-channel' : `${i}-group-edit`}
												>
													{t('channelsPage.edit')}
												</ButtonMUI>
												<ButtonMUI
													variant='contained'
													color='error'
													id={i === 0 ? 'delete-channel' : `${i}-group-delete`}
													onClick={() => {
														setChannelId(element.id);
														setDeletePopup(true);
													}}
												>
													{t('channelsPage.delete')}
												</ButtonMUI>
												<ButtonMUI
													variant='outlined'
													color={element.enable ? 'primary' : 'error'}
												>
													{element.enable
														? t('channelsPage.enabled')
														: t('channelsPage.disabled')}
												</ButtonMUI>
											</div>
										</td>
									</tr>
								))}
						</tbody>
					</Table>
				)}
			</div>
		</div>
	);
}
