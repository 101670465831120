import { yupResolver } from '@hookform/resolvers/yup';
import {
	AddAccount,
	ChangeAccountStatus,
	ChangePassword,
	RemoveAccount,
	UpdateAccountName,
	UpdateMe,
	UploadAccountImage,
} from 'api/mutations';
import ConfirmAlert from 'libs/confirmAlert';
import { useEffect, useState } from 'react';

import { Button, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import * as yup from 'yup';
import { Steps } from 'intro.js-react';

import {
	faCircleQuestion,
	faTrashCan,
	faEye,
	faEyeSlash,
	faCopy,
	faBell,
	faBellSlash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthToken, NewAuthToken } from 'api/queries';
import { isAccountActive } from 'libs/utils';
import { ENTERPRISE } from 'config';
import CreateNewAccountButton from './CreateNewAccount';
import {
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Stack,
	IconButton,
	Typography,
} from '@mui/material';
import { Button as ButtonMUI } from '@mui/material';

const schema = yup.object().shape({
	password: yup.string().min(8).required('editUserPage.passwordRequired'),
});

const EditUserPage = (props) => {
	const { t } = useTranslation();

	const [userData, setUserData] = useState({
		name: props.user.name,
		surname: props.user.surname,
		language: props.user.language,
	});
	const [token, setToken] = useState('');
	const queryClient = useQueryClient();
	const [confirmRemoveAccount, setConfirmRemoveAccount] = useState({
		modal: false,
		id: null,
	});
	const [help, setHelp] = useState(false);
	const [file, setFile] = useState(null);
	const [image, setImage] = useState(null);
	const [accountName, setAccountName] = useState('');

	const [tokenAuth, setTokenAuth] = useState('');
	const [showToken, setShowToken] = useState('password');
	const [modalNewToken, setModalNewToken] = useState(false);

	useEffect(() => {
		setAccountName(props.user.accountName);
		setTokenAuth('');
	}, [props.user]);

	const { refetch } = useQuery(
		['Token', props.user.currentAccount],
		() => AuthToken(),
		{
			enabled: false,
			retry: false,
		}
	);

	const getToken = async () => {
		try {
			const response = await refetch();
			if (response.error) {
				ConfirmAlert.error(response.error.response.data.message);
				return;
			} else {
				setTokenAuth(response.data.data);
			}
		} catch (error) {}
	};

	const { refetch: refetchNewToken } = useQuery(
		['newToken', props.user.currentAccount],
		() => NewAuthToken(),
		{
			enabled: false,
			retry: false,
		}
	);

	const getNewToken = async () => {
		try {
			const response = await refetchNewToken();
			if (response.error) {
				ConfirmAlert.error(response.error.response.data.message);
				return;
			} else {
				setTokenAuth(response.data.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const mutation = useMutation(ChangePassword);

	const onSubmit = async (data) => {
		data = { password: data.password };
		const response = await mutation.mutateAsync(data);
		if (response) {
			ConfirmAlert.success(t('editUserPage.passwordUpdated'));
			props.history.push('/');
		}
	};

	const removeAccountMutate = useMutation(RemoveAccount, {
		onSuccess: () => {
			queryClient.invalidateQueries(['Me']);
		},
	});

	const removeAccount = async (id) => {
		try {
			const response = await removeAccountMutate.mutateAsync(id);
			if (response) {
				ConfirmAlert.success(t('editUserPage.accountRemoved'));
			}
		} catch (error) {
			if (error.response && error.response.data) {
				ConfirmAlert.error(error.response.data);
				return;
			}
		}
	};

	const addAccountMutate = useMutation(AddAccount, {
		onSuccess: () => {
			queryClient.invalidateQueries(['Me']);
		},
	});

	const addAccount = async (token) => {
		try {
			const response = await addAccountMutate.mutateAsync(token);
			if (response) {
				ConfirmAlert.success(t('editUserPage.accountAdded'));
			}
		} catch (error) {
			if (error.response && error.response.data) {
				ConfirmAlert.error(error.response.data);
				return;
			}
		}
	};

	const updateUserMutate = useMutation(UpdateMe, {
		onSuccess: () => {
			queryClient.invalidateQueries(['Me']);
		},
	});

	const updateMe = async (data) => {
		if (
			props.user.name === userData.name &&
			props.user.surname === userData.surname &&
			props.user.language === userData.language
		) {
			return;
		}
		try {
			const response = await updateUserMutate.mutateAsync(data);
			if (response) {
				ConfirmAlert.success(t('editUserPage.userUpdated'));
			}
		} catch (error) {
			if (error.response && error.response.data) {
				ConfirmAlert.error(error.response.data);
				return;
			}
		}
	};

	const updateAccountNameMutate = useMutation(UpdateAccountName, {
		onSuccess: () => {
			queryClient.invalidateQueries(['Me']);
		},
	});

	const updatAccountName = async () => {
		if (props.user.accountName === accountName) {
			return;
		}
		const data = {
			id: props.user.currentAccount,
			name: accountName,
		};
		try {
			const response = await updateAccountNameMutate.mutateAsync(data);
			if (response) {
				ConfirmAlert.success(t('editUserPage.accountUpdated'));
			}
		} catch (error) {
			if (error.response && error.response.data) {
				ConfirmAlert.error(error.response.data);
				return;
			}
		}
	};

	const changeAccountStatusMutate = useMutation(ChangeAccountStatus, {
		onSuccess: () => {
			queryClient.invalidateQueries(['Me']);
		},
	});

	const changeAccountStatus = async (id) => {
		try {
			const response = changeAccountStatusMutate.mutateAsync(id);
		} catch (error) {
			if (error.response && error.response.data) {
				ConfirmAlert.error(error.response.data);
				return;
			}
		}
	};

	const updateAccountImageMutate = useMutation(UploadAccountImage, {
		onSuccess: () => {
			queryClient.invalidateQueries(['Me']);
		},
	});

	const UploadImage = async () => {
		const data = { image };
		try {
			const result = await updateAccountImageMutate.mutateAsync(data);
			if (result) {
				ConfirmAlert.success(t('editUserPage.imageUploaded'));
			}
		} catch (error) {
			if (error.response && error.response.data) {
				ConfirmAlert.error(error.response.data);
				return;
			}
		}
	};

	const options = {
		nextLabel: t('guideButtons.next'),
		prevLabel: t('guideButtons.prev'),
		doneLabel: t('guideButtons.done'),
	};

	let stepsUsers = [
		{
			element: '#addAccount',
			intro: (
				<div>
					<h5 style={{ fontSize: '20px', fontWeight: '700' }}>
						{t('accountsGuide.addAccountStepTitle')}
					</h5>
					<div className='pb-0'>{t('accountsGuide.addAccountStep')}</div>
				</div>
			),
		},
		{
			element: '#notificationsAccount',
			intro: (
				<div>
					<h5 style={{ fontSize: '20px', fontWeight: '700' }}>
						{t('accountsGuide.editNotificationsStepTitle')}
					</h5>
					<div className='pb-0'>{t('accountsGuide.editNotificationsStep')}</div>
				</div>
			),
		},
		{
			element: '#accountName',
			intro: (
				<div>
					<h5 style={{ fontSize: '20px', fontWeight: '700' }}>
						{t('accountsGuide.accountNameStepTitle')}
					</h5>
					<div className='pb-0'>{t('accountsGuide.accountNameStep')}</div>
				</div>
			),
		},
		{
			element: '#authToken',
			intro: (
				<div>
					<h5 style={{ fontSize: '20px', fontWeight: '700' }}>
						{t('accountsGuide.authTokenStepTitle')}
					</h5>
					<div className='pb-0'>{t('accountsGuide.authTokenStep')}</div>
				</div>
			),
		},
		{
			element: '#removeAccount',
			intro: (
				<div>
					<h5 style={{ fontSize: '20px', fontWeight: '700' }}>
						{t('accountsGuide.accountRemoveStepTitle')}
					</h5>
					<div className='pb-0'>{t('accountsGuide.accountRemoveStep')}</div>
				</div>
			),
		},
		{
			element: '#accountImage',
			intro: (
				<div>
					<h5 style={{ fontSize: '20px', fontWeight: '700' }}>
						{t('accountsGuide.accountImageStepTitle')}
					</h5>
					<div className='pb-0'>{t('accountsGuide.accountImageStep')}</div>
				</div>
			),
		},
	];

	const onExit = () => {
		setHelp(false);
	};

	const handleChange = (event) => {
		setImage(event.target.files[0]);
		setFile(URL.createObjectURL(event.target.files[0]));
	};

	const copyAuthToken = () => {
		navigator.clipboard.writeText(`${tokenAuth}`);
		ConfirmAlert.success(t('dashboardPage.confirmCopy'));
	};

	return (
		<div className='m-0-auto max-width'>
			<Steps
				enabled={help}
				steps={stepsUsers}
				onExit={onExit}
				initialStep={0}
				options={options}
			/>
			<Modal
				show={confirmRemoveAccount.modal}
				centered
				onHide={() => setConfirmRemoveAccount({ modal: false, id: null })}
			>
				<div className='delete-popup font-18'>
					<div className='text-center m-3'>
						{t('editUserPage.removeAccount')}
					</div>
					<div className='d-flex justify-content-center gap-5 mb-3'>
						<div
							onClick={() => {
								removeAccount(confirmRemoveAccount.id);
								setConfirmRemoveAccount({ modal: false, id: null });
							}}
							className='group-cancel-button d-flex justify-content-center align-items-center'
						>
							{t('groupsPage.yes')}
						</div>
						<div
							onClick={() =>
								setConfirmRemoveAccount({ modal: false, id: null })
							}
							className='group-cancel-button d-flex justify-content-center align-items-center'
						>
							{t('groupsPage.no')}
						</div>
					</div>
				</div>
			</Modal>
			<Modal
				show={modalNewToken}
				centered
				onHide={() => setModalNewToken(false)}
			>
				<div className='delete-popup font-18'>
					<div className='text-center m-3'>{t('editUserPage.getNewToken')}</div>
					<div className='d-flex justify-content-center gap-5 mb-3'>
						<div
							onClick={() => {
								setModalNewToken(false);
								getNewToken();
							}}
							className='group-cancel-button d-flex justify-content-center align-items-center'
						>
							{t('groupsPage.yes')}
						</div>
						<div
							onClick={() => {
								setModalNewToken(false);
							}}
							className='group-cancel-button d-flex justify-content-center align-items-center'
						>
							{t('groupsPage.no')}
						</div>
					</div>
				</div>
			</Modal>
			<div className='d-flex gap-2 justify-content-between flex-column flex-md-row me-3'>
				<h1 className='title-font'> {t('editUserPage.userData')}</h1>
				{!ENTERPRISE && (
					<div
						className='d-flex flex-column flex-md-row gap-3 align-items-baseline mb-3'
						id='addAccount'
					>
						<TextField
							value={token}
							onChange={(e) => setToken(e.target.value)}
							type={'number'}
							label='Token'
							sx={{ paddingBlock: '6px' }}
							InputProps={{ sx: { height: '40px' } }}
						/>
						<ButtonMUI
							onClick={() => {
								setToken('');
								addAccount(token);
							}}
							color='secondary'
							variant='contained'
						>
							{t('editUserPage.addAccount')}
						</ButtonMUI>
					</div>
				)}
			</div>
			<div className='dashboard-box mt-3 mb-4 py-4 px-3'>
				<div className='d-flex  gap-3 flex-column flex-lg-row '>
					{/* name */}
					<TextField
						fullWidth
						label={t('editUserPage.name')}
						value={userData.name ? userData.name : ''}
						onChange={(e) => setUserData({ ...userData, name: e.target.value })}
					/>
					{/* surname */}
					<TextField
						fullWidth
						label={t('editUserPage.surname')}
						value={userData.surname ? userData.surname : ''}
						onChange={(e) =>
							setUserData({ ...userData, surname: e.target.value })
						}
					/>
					{/* language */}
					<FormControl fullWidth>
						<InputLabel id='select-role'>
							{t('editUserPage.language')}
						</InputLabel>
						<Select
							labelId='select-language'
							label='language'
							value={userData.language ? userData.language : ''}
							onChange={(e) =>
								setUserData({ ...userData, language: e.target.value })
							}
						>
							<MenuItem value={'it'}>It</MenuItem>
							<MenuItem value={'en'}>En</MenuItem>
						</Select>
					</FormControl>
					<TextField
						label='Email'
						fullWidth
						value={props.user.email}
						disabled
					/>
				</div>

				{/* change password: */}
				<Stack
					direction={'row'}
					alignItems={'end'}
					justifyContent={'space-between'}
				>
					<Stack>
						<Typography fontSize={20} mt={3} mb={1}>
							{t('editUserPage.changePassword')}
						</Typography>
						<Form
							id='email-form'
							name='email-form'
							data-name='Reset Password Form'
							onSubmit={handleSubmit(onSubmit)}
							className='d-flex gap-2 flex-column align-items-center flex-md-row'
						>
							<TextField
								fullWidth
								label='Password'
								sx={{ paddingBlock: '6px', padding: 0 }}
								// InputProps={{ sx: { height: '40px' } }}
								inputProps={{ maxLength: 256 }}
								{...register('password', { required: true })}
								type='password'
								name='password'
								placeholder={t('editUserPage.insertNewPassword')}
								id='password'
							/>
							<ButtonMUI
								type='submit'
								data-wait='Please wait...'
								variant='contained'
								fullWidth
							>
								{t('editUserPage.updatePassword')}
							</ButtonMUI>
						</Form>
					</Stack>
					{/* save button */}
					<ButtonMUI
						onClick={() => {
							updateMe(userData);
							updatAccountName();
						}}
						variant='contained'
					>
						{t('userEdit.save')}
					</ButtonMUI>
				</Stack>
			</div>

			{/* accounts  */}
			<div className='d-flex gap-2 align-items-start'>
				<h1 className='title-font'>{t('editUserPage.account')}</h1>
				<FontAwesomeIcon
					icon={faCircleQuestion}
					className='text-blue pointer-hover fs-5'
					onClick={() => {
						setHelp(true);
					}}
				/>
				{!isAccountActive(props.user.account) && (
					<div className='text-danger align-self-center'>
						{t('editUserPage.accountNotActive')}
					</div>
				)}
				<CreateNewAccountButton />
			</div>

			{/* Account data */}
			<div className='d-flex flex-column flex-lg-row gap-4 mt-3 '>
				<div className='dashboard-box mt-2 mb-4 py-4 px-5 d-flex flex-column gap-4 justify-content-between'>
					<Stack gap={3}>
						<Stack direction={{ md: 'row', xs: 'column' }} gap={2}>
							{/* role  */}
							<TextField
								fullWidth
								value={props.user.role.toUpperCase()}
								disabled
								label={t('editUserPage.role')}
							/>
							{/* notifications Enabled */}
							<ButtonMUI
								fullWidth
								size='large'
								id='notificationsAccount'
								sx={{
									height: '57px',
									border: `solid 1px ${
										props.user.notificationsEnabled ? '#72d830' : '#ff3131'
									}`,
									color: props.user.notificationsEnabled
										? '#72d830'
										: '#ff3131',
									'&:hover': {
										border: `solid 2px ${
											props.user.notificationsEnabled ? '#72d830' : '#ff3131'
										}`,
									},
								}}
								variant='outlined'
								startIcon={
									<FontAwesomeIcon
										icon={
											props.user.notificationsEnabled ? faBell : faBellSlash
										}
									/>
								}
								onClick={() => {
									changeAccountStatus(props.user.currentAccount);
								}}
							>
								{t('editUserPage.notifications')}
							</ButtonMUI>
						</Stack>

						{/* account Name */}
						{!ENTERPRISE && (
							<TextField
								id='accountName'
								label={t('editUserPage.accountName')}
								value={accountName ? accountName : ''}
								onChange={(e) => setAccountName(e.target.value)}
								disabled={!props.user.owner}
							/>
						)}

						{/* token */}
						{/* {!ENTERPRISE && (
						<div className='d-flex gap-3 w-100-perc flex-column '>
							<div
								id='authToken'
								className='d-flex flex-column flex-md-row gap-3 align-items-baseline align-items-md-center'
							>
								<TextField
									label={t('editUserPage.tokenAuth')}
									type={showToken}
									disabled
									value={tokenAuth}
									sx={{ paddingBlock: '5px' }}
									InputProps={{
										sx: { height: '40px' },
										endAdornment: (
											<>
												<IconButton size='small'>
													<FontAwesomeIcon
														icon={showToken === 'password' ? faEye : faEyeSlash}
														onClick={() => {
															setShowToken(
																showToken === 'password' ? 'text' : 'password'
															);
														}}
														title={t('dashboardPageNew.showKey')}
													/>
												</IconButton>
												<IconButton size='small'>
													<FontAwesomeIcon
														icon={faCopy}
														onClick={() => {
															copyAuthToken();
														}}
														title={t('dashboardPageNew.copyKey')}
													/>
												</IconButton>
											</>
										),
									}}
								/>
								<ButtonMUI
									variant='contained'
									color='secondary'
									onClick={() => {
										setModalNewToken(true);
									}}
								>
									{t('editUserPage.getToken')}
								</ButtonMUI>
								<ButtonMUI
									onClick={() => {
										setModalNewToken(true);
									}}
									variant='contained'
								>
									{t('editUserPage.newToken')}
								</ButtonMUI>
							</div>
						</div>
					)} */}
					</Stack>

					{!ENTERPRISE && (
						<div className='d-flex justify-content-between gap-3 '>
							<ButtonMUI
								variant='contained'
								color='error'
								startIcon={<FontAwesomeIcon icon={faTrashCan} />}
								onClick={() => {
									setConfirmRemoveAccount({
										modal: true,
										id: props.user.currentAccount,
									});
								}}
								title={t('editUserPage.removeAccount')}
								id='removeAccount'
							>
								{t('editUserPage.removeAccount')}
							</ButtonMUI>
							<ButtonMUI
								variant='contained'
								onClick={() => {
									updatAccountName();
								}}
							>
								{t('userEdit.save')}
							</ButtonMUI>
							{/* <div
								style={{ color: 'white', backgroundColor: '#72d830' }}
								onClick={() => {
									updatAccountName();
								}}
								className=' create-group-button d-flex justify-content-center align-items-center text-decoration-none trash-color'
							>
								{t('userEdit.save')}
							</div> */}
						</div>
					)}
				</div>

				<div
					className='dashboard-box mb-4 py-4 mt-2 px-4 d-flex flex-column align-items-center'
					style={{ maxWidth: '400px' }}
				>
					{/* img + save/del */}
					<h4>{t('editUserPage.accountImage')}</h4>
					<div
						id='accountImage'
						className='d-flex flex-column justify-content-between border border-secondary border-1 rounded-3'
						style={{ width: '200px', height: '270px' }}
					>
						<label
							for='update-img'
							className='w-100-perc h-100 d-flex justify-content-center align-items-center'
						>
							<img
								alt='image'
								style={{ objectFit: 'cover', cursor: 'pointer', width: '100%' }}
								src={
									file !== null
										? file
										: props.user.image === ''
										? '/images/everylog-180.png'
										: props.user.image
								}
							/>
						</label>
						<input
							id='update-img'
							type='file'
							onChange={handleChange}
							className='d-none'
						/>
						<div
							className=' px-2 w-auto blue text-white text-center pointer-hover text-nowrap pt-2'
							style={{ height: '40px' }}
							onClick={() => {
								if (file !== null) {
									UploadImage();
								} else {
									ConfirmAlert.error(t('editUserPage.selectImage'));
								}
							}}
						>
							{t('editUserPage.uploadImage')}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default EditUserPage;
