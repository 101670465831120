export default {
	loginPage: {
		access: 'Access',
		register: 'Register',
		forgotPassword: 'Forgot password?',
		didntReceivedActivationEmail: "Didn't receive the activation email?",
		confirm: 'Confirm',
		emailPasswordInvalid: 'Email or password invalid',
	},
	registerPage: {
		invalidSubdomain: 'Invalid subdomain format',
		termsAccepted: 'The terms and conditions must be accepted.',
		confirmEmailSent:
			'An email with the activation token has been sent. Check your inbox.',
		emailPasswordInvalid: 'Email or password invalid',
		subdomain: 'Subdomain',
		registerConsent:
			"By clicking on the 'Register' button I consent the terms and conditions of the service and I understand that my account's informations will be used according to the privacy policy.",
		terms: 'Terms and Conditions',
		privacy: 'Privacy and Policy',
		marketingConsent:
			'EveryLog is committed to protecting and respecting your privacy, and will only use your personal information to administer your account and to provide the service you have requested. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you.',
		alreadyRegistered: 'Already registered?',
		didntReceivedActivationEmail: "Didn't receive the activation email?",
		confirm: 'Confirm',
		register: 'Register',
		betaTestingMessage:
			'We are still in the beta testing and for now we don\'t allow self registration. Please subscribe to the mailing list <strong><a href="https://www.everylog.io/#form" target="_blank">HERE</a></strong> in order to be added to the beta testing program.',
	},
	forgotPasswordPage: {
		passwordEmailSent:
			'An email with the reset password code has been sent. Check your inbox',
		resetPasswordFailure: 'Reset password went wrong, please retry',
		sendCode: 'Send reset code',
		back: 'Back to login page',
		passwordReset: 'Password reset',
	},
	resendActivationPage: {
		emailSent:
			'An email with the activation token has been sent. Check your inbox.',
		invalidEmail: 'Email not valid',
		resendActivationCode: 'Resend activation code',
		back: 'Back to login page',
		confirm: 'Confirm',
	},
	resetPasswordPage: {
		passwordUpdated: 'Your password has been successfully updated',
		resetPasswordFailure: 'Reset password went wrong, please retry',
		changePassword: 'Change password',
		updatePassword: 'Update password',
		back: 'Back to login page',
	},
	activateAccountPage: {
		accountActivated: 'Your account has been activated',
		activationFailure: 'Activation went wrong, please retry',
		activateAccount: 'Activate Account',
		activate: 'Activate',
		back: 'Back to login page',
	},
	accountForm: {
		companyName: 'Company name',
		phoneNumber: 'Phone number',
		billingAddress: 'Billing address',
		vatNumber: 'VAT number',
		update: 'Update billing details',
		companyCountry: 'Country',
		selectOne: 'Select one',
		pec: 'PEC',
		sdi: 'SDI',
		onlyForItalians: ' only for italian companies with VAT',
	},
	addCardPage: {
		creditCard: 'Credit card data',
	},
	editAccountPage: {
		detailsUpdated: 'Billing details have been successfully updated',
		details: 'Billing details',
	},
	editUserPage: {
		passwordRequired: 'Password is required',
		passwordUpdated: 'Your password has been successfully updated',
		changePassword: 'Change password',
		insertNewPassword: 'Insert the new password',
		updatePassword: 'Update password',
		userData: 'User Data',
		name: 'Name',
		surname: 'Surname',
		language: 'Language',
		removeAccount: 'Remove Account',
		addAccount: 'Add Account',
		notifications: 'Notifications',
		role: 'Role',
		accountRemoved: 'Account Removed',
		accountAdded: 'Account Added',
		accountUpdated: 'Account Updated',
		account: 'Account',
		userUpdated: 'User Updated',
		accountName: 'Account Name',
		accountImage: 'Account Image',
		uploadImage: 'Upload Image',
		selectImage: 'Select Image',
		accountNotActive: "Account isn't active",
		tokenAuth: 'Token',
		getToken: 'Get Token',
		newToken: 'New Token',
		getNewToken: 'Get New Token?',
		imageUploaded: 'Image Uploaded',
		projects: 'Projects',
		addNewAccount: 'Add New Account',
		active: 'Active',
	},
	stripeCCForm: {
		cardAdded: 'Card added successfully',
		cardOwner: 'Credit card owner',
		addCard: 'Add this card',
		addCardFailed: 'Card is not added due to some errors:',
	},
	planCard: {
		updateSubscription: 'Update your subscription',
		areYouSure: 'Are you sure to update your plan?',
		yes: 'Yes',
		no: 'No',
		planUpdated: 'Plan successfully updated',
		month: '/month',
		year: '/year',
		changePlan: 'Change plan',
		currentPlan: 'Your curren plan',
		selectPlan: 'Select this plan',
		toPay: 'To pay',
		plusVat: '+ TAX',
	},
	planPage: {
		selectaPlan: 'Select a plan',
		monthly: 'Monthly plan',
		yearly: 'Yearly plan',
		deactivatedAccountNotice:
			"Your account isn't active. Please subscribe a plan to activate it.",
	},
	stripeForm: {
		paymentFailed: 'Payment failed due to some errors:',
		paymentCompleted: 'Payment successfully completed',
		cardOwner: 'Credit card owner',
		subscribe: 'Subscribe',
	},
	subscribePlanPage: {
		billingDetails: 'Billing details',
		yourOrder: 'Your order',
		plan: 'Plan',
		invoicing: 'Invoicing',
		price: 'Price',
		monthly: 'Monthly plan',
		yearly: 'Yearly plan',
		creditCard: 'Credit card data',
		plusVat: '+ TAX',
		subscribe: 'Subscribe',
	},
	trialComponent: {
		youAreOnTrial:
			'You are currently working on the trial version of EveryLog, on "{{planType}}" plan.',
		trialEndsAt: 'Trial period ends at',
		goToPlans: 'Go to plans',
	},
	dashboardPage: {
		unsubscribe: 'Unsubscribe',
		areYouSureToUnsubscribe:
			'Are you sure to unsubscribe? In any case, your subscription will be active until the end of the paid period',
		yes: 'Yes',
		no: 'No',
		removeCard: 'Remove the card',
		areYouSureToRemoveCard: 'Are you sure to remove this card?',
		makeDefault: 'Make default',
		areYouSureMakeDefault: 'Are you sure to make this card the default one?',
		manageSubscription:
			'In this panel you can manage your subscription and check your payment history',
		failedPaymentAt: 'ATTENTION! You have a failed payment at',
		checkYourPayments:
			'Please check your due payment in the Payment History section or in your email inbox.',
		subscriptionDeactivateOn:
			'Your subscription will automatically deactivate on',
		subscriptionRenewOn: 'Your subscription will automatically renew on',
		yourSubscription: 'Your subscription',
		plan: 'Plan',
		price: 'Price',
		canceledAt: 'Canceled at',
		willDeactivateAt: 'Will deactivate at',
		willRenewOn: 'Will renew on',
		paymentFailedAt: 'Payment failed at',
		paymentMethods: 'Your payment methods',
		default: 'default',
		remove: 'remove',
		addCreditCard: 'Add a credit card',
		changePlan: 'Change plan',
		deleteSubscription: 'Delete subscription',
		paymentHistory: 'Payment history',
		paymentId: 'Payment ID',
		status: 'Status',
		date: 'Date',
		total: 'Total',
		paid: 'Paid',
		toPay: 'To pay',
		actions: 'Actions',
		plusVat: '+ TAX',
		confirmCopy: 'Copied to clipbard',
		noNotifications: 'No notification found',
	},
	dashboardPageNew: {
		guide: 'Find out how to easily integrate EveryLog in your project ',
		guideButton: 'Go to guide',
		latestNotifications: 'Latest notifications',
		project: 'Project',
		tags: 'Tags',
		title: 'Title',
		summary: 'Summary',
		trialMsg:
			'You are currently working on the trial version of EveryLog, on "Premium" plan.',
		trialPeriodEndAt: 'Trial period ends at ',
		planBtn: 'Go to plans',
		copyKeyAlert: 'Copied',
		showKey: 'Show Api Key',
		hideKey: 'Hide Api Key',
		copyKey: 'Copy Api Key',
	},
	privateLayout: {
		billingDetails: 'Billing details',
		editUser: 'Edit user info',
		logout: 'Logout',
		toggle: 'Toggle',
		apiKey: 'API KEY',
		users: 'Manage users',
		webSite: 'Visit ',
		manageUser: 'User data',
		managePayment: 'Billing details',
		managePlan: 'Manage plan',
		codeApiKey: 'API KEY CODE',
		usersMenu: 'USERS',
		projects: 'PROJECTS',
		groups: 'GROUPS',
		channels: 'CHANNELS',
		invitations: 'INVITATIONS',
		notification: 'SEND NOTIFICATION',
	},
	apiKeyComponent: {
		apiKey: 'API KEY',
	},
	indexUsersPage: {
		users: 'Users',
		deleteUser: 'Delete user',
		areYouSureToDelete: 'Are you sure to delete this user?',
		yes: 'Yes',
		no: 'No',
		userCreated: 'User created',
		userUpdated: 'User updated',
		name: 'Name',
		surname: 'Surname',
		email: 'Email',
		role: 'Role',
		actions: 'Actions',
		editUser: 'Edit user',
		deleteUser: 'Delete user',
		addUser: 'Add user',
		addGroup: 'Add/Remove groups',
		userDeleted: 'User deleted',
		inviteUser: 'Invite User',
	},
	createUsersPage: {
		createUser: 'Create a new user',
		userCreated: 'User created',
		name: 'Name',
		surname: 'Surname',
		email: 'Email',
		cancel: 'Cancel',
		save: 'Save',
	},
	updateUsersPage: {
		updatedUser: 'Update user',
		userUpdated: 'User updated',
	},
	usersForm: {
		name: 'Name',
		surname: 'Surname',
		email: 'Email',
		language: 'Language',
		role: 'Role',
		user: 'User',
		admin: 'Admin',
		save: 'Save',
	},
	usersAddGroup: {
		titleWithName: 'Add/Remove groups to ',
		title: 'Add/Remove groups',
		groupName: 'Group name',
		nrUsers: 'Users',
		code: 'Code',
		actions: 'Actions',
		addGroup: 'Add group',
		removeGroup: 'Remove group',
	},
	groupsPage: {
		title: 'Groups',
		name: 'Group Name',
		nrUsers: 'Users',
		code: 'Code',
		actions: 'Actions',
		button: 'Create group ',
		delete: 'Delete group',
		searchInput: 'Search',
		deleteGroup: 'Delete group',
		yes: 'yes',
		no: 'no',
		create: 'Create group',
		noGroup: 'Create first group',
		userWithNoGroup: 'You have not been added to any group',
		groupCreated: 'Group successfully created',
		groupDeleted: 'Group successfully deleted',
	},
	invitationsPage: {
		invitations: 'Invitations',
		role: 'Role',
		sent: 'Sent',
		actions: 'Actions',
		createInvitation: 'Create Invitation',
		send: 'Send',
		delete: 'Delete',
		deleteInvitation: 'Delete Invitation',
		invitationCreated: 'Invitation Created',
		invitationSent: 'Invitation Sent',
		invitationDeleted: 'Invitation Deleted',
		invitationProjects: 'Projects',
		add: 'Add',
	},
	createGroup: {
		groupName: 'Group Name',
		code: 'Code',
		cancel: 'Anulla',
		save: 'Salva',
	},
	groupPage: {
		name: 'Name',
		surname: 'Surname',
		email: 'Email',
		actions: 'Actions',
		button: 'Add user',
		delete: 'Delete group',
		user: 'User',
		save: 'Save',
		search: 'Search',
		changesSaved: 'Changes saved',
		goGroupsPage: 'Go groups page',
		groupCreated: 'Group successfully created',
		groupDeleted: 'Group successfully deleted',
		groupUpdated: 'Group successfully updated',
	},
	addGroupUser: {
		title: 'Add user',
		name: 'Name',
		surname: 'Surname',
		email: 'Email',
		role: 'Role',
		actions: 'Actions',
		addUser: 'Add user to group',
	},
	createGroup: {
		title: 'Create new group',
		code: 'Code',
		groupName: 'Group name',
		save: 'Save',
	},
	projectsPage: {
		title: 'Projects',
		projectTitle: 'Title',
		tags: 'Tags',
		summary: 'Summary',
		actions: 'Details',
		searchButton: 'Search',
		searchInSummary: 'Search in summary',
		searchByProperties: 'Search in properties',
		properties: 'Properties',
		star: 'Star',
		unstar: 'Unstar',
		delete: 'Delete notification',
		modalDelete: 'Delete notification',
		info: 'Info',
		all: 'All',
		starred: 'Starred',
		display: 'Display',
		time: 'Time',
		timeSince: {
			yearsAgo: ' years ago',
			monthsAgo: ' months ago',
			daysAgo: ' days ago',
			hoursAgo: ' hours ago',
			minutesAgo: ' minutes ago',
			secondsAgo: ' seconds ago',
		},
		noProject: 'No project found',
		noAccess: "You don't have access to this page",
		projectActive: 'Project enabled',
		projectNotActive: 'Project disabled',
	},
	guideButtons: {
		next: 'Next',
		prev: 'Back',
		done: 'Done',
	},
	onboardGuide: {
		dashboardIntroTitle: 'Welcome to EveryLog',
		dashboardIntro:
			'Here is an overview to discover all the features of the app.',
		dashboardApiKeyTitle: 'API KEY Code',
		dashboardDocs: 'DOCS',
		dashboardApiKey:
			'In this section you will find a guide on how to use the KEY API in the case of various languages.',
		dashboardManageUsersTitle: 'Users',
		dashboardManageUsers:
			'In this section you can create, edit and delete users using EveryLog.',
		dashboardProjectsTitle: 'Project',
		dashboardProjects:
			'Here you can choose your projects and view all notifications received. ',
		dashboardManageGroupsTitle: 'Groups',
		dashboardManageGroups:
			'In this section you can create, edit and delete groups using EveryLog.',
		dashboardManageAccountTitle: 'Account',
		dashboardManageAccount:
			'Here you can change your password, billing data and manage your EveryLog subscription.',
		dashboardGuideTitle: 'Guides',
		dashboardGuide:
			'If you need a hand, you can always consult our guide by clicking here',
	},
	usersGuide: {
		inviteNewUserStepTitle: 'Invite user',
		editUserStepTitle: 'Edit user',
		deleteUserStepTitle: 'Delete user',
		inviteNewUserStep: 'Invite new user to your account.',
		editUserStep:
			'Edit the information you have entered on the user and the groups they work in',
		deleteUserStep: 'Deletes the user from the list of users using EveryLog.',
	},
	groupGuide: {
		firstStepTitle: 'Create a group',
		secondStepTitle: 'Group settings',
		thirdStepTitle: 'Edit group',
		fourthStepTitle: 'Delete group',
		firstStep:
			'When you want to add a new group to EveryLog click on Create Group.',
		secondStep: 'Enter the name, group code and save.',
		thirdStep: 'Click on edit and change the users in your groups.',
		fourthStep: 'Delete the group from the list of your groups on EveryLog.',
	},
	channelGuide: {
		firstStepTitle: 'Create a channel',
		secondStepTitle: 'Channel settings',
		thirdStepTitle: 'Edit channel',
		fourthStepTitle: 'Delete channel',
		firstStep: "Click on 'Create Channel' to add a channel",
		secondStep: 'Enter the name, code and channel type and save.',
		thirdStep: 'Click on edit and change the channel settings.',
		fourthStep:
			'Delete the channel from the list of your channels on EveryLog.',
	},
	accountsGuide: {
		addAccountStepTitle: 'Add New Account',
		editNotificationsStepTitle: 'Enable Notifications',
		accountNameStepTitle: 'Account Name',
		authTokenStepTitle: 'X-Auth-Token',
		accountRemoveStepTitle: 'Remove Account',
		accountProjectsStepTitle: 'Account projects',
		accountImageStepTitle: 'Upload Image',
		addAccountStep:
			'Use the token from the invitation email to add a New Account',
		editNotificationsStep: 'Enable/Disable Notifications',
		accountNameStep: 'Account Name',
		accountProjectsStep: 'Account projects',
		authTokenStep: 'Get token or new token',
		accountRemoveStep: 'Remove Account',
		accountImageStep: 'Upload account image',
	},
	invitationGuide: {
		firstStepTitle: 'Create Invitation',
		secondStepTitle: 'Send Invitation',
		thirdStepTitle: 'Delete Invitation',
		firstStep: 'Create new Invitation....',
		secondStep: 'Send invitation... You can send invitation once per day',
		thirdStep: 'Delete invitation...',
	},
	search: {
		placeholder: 'Search',
	},
	buttonUsers: {
		createUser: 'Create User',
		edit: 'Edit',
		delete: 'Delete',
	},
	userEdit: {
		user: 'User',
		editUser: 'Edit user',
		group: 'Group',
		users: 'Users',
		name: 'Name',
		surname: 'Surname',
		language: 'Language',
		role: 'Role',
		email: 'Email',
		save: 'Save',
		confirmSave: 'Save user changes?',
		projects: 'Projects',
		add: 'Add',
	},
	notifyPage: {
		pageTitle: 'Send notification',
		projectId: 'ProjectId',
		title: 'Title',
		summary: 'Summary',
		body: 'Body',
		groups: 'Groups',
		channels: 'Channels',
		send: 'Send',
		notificationSent: 'Notification sent',
		projectIdPlaceholder: 'Enter new project or select one',
		titlePlaceholder: 'Enter title',
		summaryPlaceholder: 'Enter summary',
		bodyPlaceholder: 'Enter body ',
		linkPlaceholder: 'Enter link',
		tagsPlaceholder: 'Write tags separated by comma',
		addGroup: 'Add group',
		addChannel: 'Add channel',
		addEmoji: 'Enter Emoji',
		keyRequired: 'Key required',
		valueRequired: 'Value required',
		users: 'Users',
		addUsers: 'Add User',
		availableHtmlTags: 'Available html tags',
		noActiveProjects: 'No active projects',
	},
	channelsPage: {
		pageTitle: 'Channels',
		channelName: 'Channel name',
		channelCode: 'Channel code',
		namePlaceholder: 'Enter channel name',
		codePlaceholder: 'Enter channel code',
		channelCreated: 'New channel created',
		name: 'Name',
		code: 'Code',
		type: 'Type',
		enable: 'Enable',
		action: 'Action',
		createChannel: 'Create channel',
		searchChannel: 'Search channel',
		deleteChannel: 'Delete channel?',
		channelDeleted: 'Channel deleted',
		noChannel: 'Create first channel',
		edit: 'Edit',
		delete: 'Delete',
		enabled: 'Enabled',
		disabled: 'Disabled',
	},
	channelPage: {
		editChannel: 'Edit Channel',
		pageSubTitle: 'Channel',
		save: 'Save',
		name: 'Name',
		code: 'Code',
		enable: 'Enable',
		type: 'Type',
		chatIdMsg: 'Write chats separeted by comma',
		username: 'Username',
		password: 'Password',
		emailFrom: 'From',
		emailTo: 'To',
		webhookNoAuth: 'No Authentication',
		basicAccess: 'Basic access authentication',
		bearerAccess: 'Bearer-Token access authentication',
		customAccess: 'Custom access authentication',
		yourHeaders: 'Your headers',
		key: 'Key',
		value: 'Value',
		add: 'Add',
		state: 'State',
	},
	role: {
		collaborator: 'Collaborator',
		external: 'External',
	},
	projects: {
		projectCreated: 'Project Created',
		name: 'Name',
		color: 'Color',
		description: 'Description',
		createProject: 'Create Project',
		confirmDeleteProject: 'Delete Project?',
		editProject: 'Edit Project',
		deleteProject: 'Delete Project',
		projectUpdated: 'Project updated',
		projectActive: 'Project active',
	},
};
