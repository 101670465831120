import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { Button, FormControl, Stack, Typography } from '@mui/material';

export default function ModalCreateNewAccount({ show, setShow, onConfirm }) {
	const { t } = useTranslation();
	const [accountName, setaccountName] = useState('');
	const handleInput = (e) => {
		setaccountName(e.target.value);
	};
	return (
		<Modal
			show={show}
			centered
			onHide={() => {
				setShow();
			}}
		>
			<div className='delete-popup font-18'>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						onConfirm(accountName);
						setShow();
						setaccountName('');
					}}
					onReset={(e) => {
						e.preventDefault();
						setaccountName('');
						setShow();
					}}
				>
					<Stack gap={3} sx={{ my: '20px' }}>
						<Typography fontSize={20}>
							{t('editUserPage.addNewAccount')}
						</Typography>
						<TextField
							value={accountName}
							onChange={(e) => handleInput(e)}
							variant='outlined'
							label={t('editUserPage.accountName')}
							fullWidth
							required
						/>
						<Stack direction={'row'} gap={2}>
							<Button variant='contained' color='secondary' type='reset'>
								{t('createUsersPage.cancel')}
							</Button>
							<Button variant='contained' color='primary' type='submit'>
								{t('editUserPage.addAccount')}
							</Button>
						</Stack>
					</Stack>
				</form>
			</div>
		</Modal>
	);
}
