import { yupResolver } from '@hookform/resolvers/yup';
import { Register } from 'api/mutations';
import { ENTERPRISE } from 'config';
import { ENABLE_SIGNUP, SIGNUP_WITH_ACTIVATE } from 'config';
import ConfirmAlert from 'libs/confirmAlert';
import i18next from 'libs/i18n';

import { Col, Form, FormGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Button, Stack, TextField } from '@mui/material';

const RegisterPage = (props) => {
	const mutation = useMutation(Register);
	const { t } = useTranslation();

	const history = useHistory();

	const schema = yup.object().shape({
		email: yup.string().lowercase().email().required(),
		password: yup.string().min(8).required(),
		name: yup.string().required(),
		surname: yup.string().required(),
		accountName: yup.string().required(),
		privacyAccepted: yup
			.boolean()
			.required(t('registerPage.termsAccepted'))
			.oneOf([true], t('registerPage.termsAccepted')),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm({
		resolver: yupResolver(schema),
	});

	const onSubmit = async (data) => {
		try {
			data.language = i18next.language;
			data.subdomain = uuidv4();
			const response = await mutation.mutateAsync(data);
			if (response) {
				if (SIGNUP_WITH_ACTIVATE) {
					props.history.push('/dashboard');
				} else {
					ConfirmAlert.success(t('registerPage.confirmEmailSent'));
					props.history.push(`/auth/activate/${data.email}`);
				}
			}
		} catch (error) {
			ConfirmAlert.error(t('registerPage.emailPasswordInvalid'));
		}
	};

	const betaTestingMessage = t('registerPage.betaTestingMessage');

	if (ENTERPRISE) {
		history.push('/auth/register-with-invitation');
	}

	return (
		<div>
			<h3 className='m-20 m-b-30'>{t('registerPage.register')}</h3>
			{ENABLE_SIGNUP ? (
				<Form
					id='email-form'
					name='email-form'
					data-name='Email Form'
					className='form'
					onSubmit={handleSubmit(onSubmit)}
				>
					<Stack gap={2}>
						{/* <FormGroup>
            <small id="emailHelp" className="form-text text-muted">
              {errors.email?.message}
            </small>
            <input
              type="email"
              className="form-control custom-input"
              maxLength="256"
              aria-describedby="emailHelp"
              name="email"
              data-name="Email"
              placeholder="Email"
              id="email"
              {...register("email", { required: true })}
            />
          </FormGroup> */}
						<TextField
							type='email'
							name='email'
							id='email'
							{...register('email', { required: true })}
							helperText={errors.email?.message}
							fullWidth
							inputProps={{ maxLength: 256 }}
							label='Email'
						/>
						{/* <FormGroup>
						<small id='passwordHelp' className='form-text text-muted'>
							{errors.password?.message}
						</small>
						<input
							type='password'
							className='form-control custom-input mt-3'
							maxLength='256'
							name='password'
							data-name='Password'
							placeholder='Password'
							id='password'
							required=''
							{...register('password', { required: true })}
						/>
					</FormGroup> */}
						<TextField
							type='password'
							name='password'
							id='password'
							{...register('password', { required: true })}
							helperText={errors.password?.message}
							fullWidth
							inputProps={{ maxLength: 256 }}
							label='Password'
						/>
						{/* <FormGroup>
							<small id='nameHelp' className='form-text text-muted'>
								{errors.name?.message}
							</small>
							<input
								type='string'
								className='form-control custom-input mt-3'
								maxLength='256'
								name='name'
								data-name='Name'
								placeholder='Name'
								id='name'
								{...register('name', { required: true })}
							/>
						</FormGroup> */}
						<TextField
							type='string'
							name='name'
							id='name'
							{...register('name', { required: true })}
							helperText={errors.name?.message}
							fullWidth
							inputProps={{ maxLength: 256 }}
							label='Name'
						/>
						{/* <FormGroup>
							<small id='surnameHelp' className='form-text text-muted'>
								{errors.surname?.message}
							</small>
							<input
								type='string'
								className='form-control custom-input mt-3'
								maxLength='256'
								name='surname'
								data-name='Surname'
								placeholder='Surname'
								id='surname'
								{...register('surname', { required: true })}
							/>
						</FormGroup> */}
						<TextField
							type='string'
							name='surname'
							id='surname'
							{...register('surname', { required: true })}
							helperText={errors.surname?.message}
							fullWidth
							inputProps={{ maxLength: 256 }}
							label='Surname'
						/>
						{/* <FormGroup>
							<small id='accountNameHelp' className='form-text text-muted'>
								{errors.accountName?.message}
							</small>
							<input
								type='string'
								className='form-control custom-input mt-3'
								maxLength='256'
								name='accountName'
								data-name='Account Name'
								placeholder='Account Name'
								id='accountName'
								{...register('accountName', { required: true })}
							/>
						</FormGroup> */}
						<TextField
							type='string'
							name='accountName'
							id='accountName'
							{...register('accountName', { required: true })}
							helperText={errors.accountName?.message}
							fullWidth
							inputProps={{ maxLength: 256 }}
							label='Account Name'
						/>
						<FormGroup>
							<small id='privacyAcceptedHelp' className='form-text text-muted'>
								{errors.privacyAccepted?.message}
							</small>
							<input
								type='checkbox'
								id='privacyAccepted'
								name='policy'
								data-name='Checkbox'
								className='w-checkbox-input checkbox mt-3'
								{...register('privacyAccepted', { required: true })}
								aria-describedby='privacyAcceptedHelp'
							/>
							<span className='checkbox-label w-form-label text-justify'>
								&nbsp;{t('registerPage.registerConsent')}
							</span>
							<br />
							<a
								href='https://www.everylog.io/privacy-policy'
								target='_blank'
								rel='noreferrer'
							>
								{t('registerPage.privacy')}
							</a>
						</FormGroup>
						<FormGroup>
							<small
								id='marketingAcceptedHelp'
								className='form-text text-muted'
							>
								{errors.marketingAccepted?.message}
							</small>
							<input
								type='checkbox'
								id='marketingAccepted'
								name='policy'
								data-name='Checkbox'
								className='w-checkbox-input checkbox'
								{...register('marketingAccepted')}
								aria-describedby='marketingAcceptedHelp'
							/>
							<span className='checkbox-label w-form-label text-justify'>
								&nbsp;{t('registerPage.marketingConsent')}
							</span>
						</FormGroup>
						<Button variant='contained' type='submit'>
							{t('registerPage.confirm')}
						</Button>
						{/* <input
							type='submit'
							value={t('registerPage.confirm')}
							className='btn btn-primary m-t-20'
						/> */}
					</Stack>
				</Form>
			) : (
				<div dangerouslySetInnerHTML={{ __html: betaTestingMessage }} />
			)}
			<Col sm={12} className='text-justify m-t-20'>
				<Link to='/auth/login'>{t('registerPage.alreadyRegistered')}</Link>
				<br />
				<Link to={{ pathname: '/auth/resend-activation' }}>
					{t('registerPage.didntReceivedActivationEmail')}
				</Link>
				<br />
			</Col>
		</div>
	);
};
export default RegisterPage;
