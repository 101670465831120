import { Channel } from 'api/queries';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import {
	faXmark,
	faBell,
	faBellSlash,
	faEye,
	faEyeSlash,
	faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { RemoveCustomHeader, UpdateChannel } from 'api/mutations';
import confirmAlert from 'libs/confirmAlert';
import * as yup from 'yup';
import {
	Autocomplete,
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	IconButton,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Stack,
	SvgIcon,
	TextField,
	Typography,
} from '@mui/material';
import { selectOptions } from './ChannelsPage';

export default function ChannelPage({ user }) {
	const { channelId } = useParams();
	const { t } = useTranslation();

	const [showPass, setShowPass] = useState(false);

	const [channelData, setChannelData] = useState({
		discordBotToken: '',
		discordChatIds: '',
		emails: '',
		enable: false,
		httpPassword: '',
		httpToken: '',
		httpUrl: '',
		httpMethod: 'METHOD ',
		httpUser: '',
		httpCustomHeaders: [],
		name: '',
		slackChatId: '',
		slackToken: '',
		telegramChatId: '',
		telegramToken: '',
		type: '',
		emailFrom: '',
		emailHost: '',
		emailPort: null,
		emailUsername: '',
		emailPassword: '',
		emails: '',
		emailEncryption: '',
	});

	const [customHeaders, setCustomHeaders] = useState({
		key: '',
		value: '',
	});

	let emailSchema = yup.object().shape({
		emailFrom: yup.string().email().required(),
		emailHost: yup.string().required(),
		emailPort: yup.number().required(),
		emailUsername: yup.string().required(),
		emailPassword: yup.string().required(),
		emails: yup.string().required(),
	});

	let webhookSchema = yup.object().shape({
		httpUrl: yup.string().url().required(),
		httpMethod: yup.string().required(),
	});

	let telegramSchema = yup.object().shape({
		telegramToken: yup.string().required(),
		telegramChatId: yup.string().required(),
	});

	let slackSchema = yup.object().shape({
		slackToken: yup.string().required(),
		slackChatId: yup.string().required(),
	});

	let discordSchema = yup.object().shape({
		discordBotToken: yup.string().required(),
		discordChatIds: yup.string().required(),
	});

	const [switchAuth, setSwitchAuth] = useState('no auth');

	const { isLoading, error, data, refetch } = useQuery(
		['channel', user.currentAccount],
		() => Channel(channelId),
		{
			onSuccess: (data) => {
				setChannelData({ ...data.data });
				if (data.data.httpToken !== '') {
					setSwitchAuth('bearer');
				} else if (data.data.httpPassword !== '') {
					setSwitchAuth('basic');
				} else if (data.data.httpCustomHeaders.length > 0) {
					setSwitchAuth('custom');
				} else {
					setSwitchAuth('no auth');
				}
			},
		}
	);

	const saveChannelMutate = useMutation(UpdateChannel, {
		onSuccess: () => {
			refetch();
		},
	});

	const removeHeaderMutete = useMutation(RemoveCustomHeader, {
		onSuccess: () => {
			refetch();
		},
	});

	const removeHeader = async (key) => {
		try {
			const resp = await removeHeaderMutete.mutateAsync({
				channelId: channelId,
				key: key,
			});
		} catch (error) {
			if (error.response?.data) {
				confirmAlert.error(error.response.data);
				return;
			}
		}
	};

	const handleSaveChannel = async () => {
		let validateData = {};
		let schema;
		if (channelData.type === 'telegram') {
			validateData = {
				telegramToken: channelData.telegramToken,
				telegramChatId: channelData.telegramChatId,
			};
			schema = telegramSchema;
		}
		if (channelData.type === 'slack') {
			validateData = {
				slackToken: channelData.slackToken,
				slackChatId: channelData.slackChatId,
			};
			schema = slackSchema;
		}
		if (channelData.type === 'discord') {
			validateData = {
				discordBotToken: channelData.discordBotToken,
				discordChatIds: channelData.discordChatIds,
			};
			schema = discordSchema;
		}
		if (channelData.type === 'email') {
			validateData = {
				emailFrom: channelData.emailFrom,
				emailHost: channelData.emailHost,
				emailPort: channelData.emailPort,
				emailUsername: channelData.emailUsername,
				emailPassword: channelData.emailPassword,
				emails: channelData.emails,
			};
			schema = emailSchema;
		}
		if (channelData.type === 'http') {
			validateData = {
				httpUrl: channelData.httpUrl,
				httpMethod: channelData.httpMethod,
			};
			schema = webhookSchema;
			if (switchAuth === 'basic') {
				channelData.httpToken = '';
			}
			if (switchAuth === 'bearer') {
				channelData.httpUser = '';
				channelData.httpPassword = '';
			}
			if (switchAuth === 'custom' || switchAuth === 'no auth') {
				channelData.httpPassword = '';
				channelData.httpUser = '';
				channelData.httpToken = '';
			}
		}

		schema
			.validate(validateData)
			.catch((err) => {
				confirmAlert.error(`${err.name}: ${err.errors}`);
			})
			.then(async (valid) => {
				if (valid) {
					try {
						console.log(data);
						const resp = await saveChannelMutate.mutateAsync({
							channelId: channelId,
							data: channelData,
						});
						confirmAlert.success('Changes saved');
					} catch (error) {
						if (error.response?.data) {
							confirmAlert.error(error.response.data);
							return;
						}
					}
				}
			});
	};

	const addHeader = async () => {
		if (customHeaders.key !== '' && customHeaders.value !== '') {
			let arr = [];
			if (channelData.httpCustomHeaders !== null) {
				arr = channelData.httpCustomHeaders;
			}
			arr.push(customHeaders);
			setChannelData({ ...channelData, httpCustomHeaders: arr });
			setCustomHeaders({ key: '', value: '' });
			try {
				const resp = await saveChannelMutate.mutateAsync({
					channelId: channelId,
					data: channelData,
				});
			} catch (error) {
				if (error.response?.data) {
					confirmAlert.error(error.response.data);
					return;
				}
			}
		} else {
			confirmAlert.error('Error: Key or value empty');
		}
	};

	const checkedEncryption = (value) => {
		if (value === channelData.emailEncryption) {
			return true;
		}
		return false;
	};

	return (
		<div className='max-width m-0-auto'>
			{data && data.data && (
				<>
					<h1 className='title-font'>{t('channelsPage.pageTitle')}</h1>
					<div className='mt-2 font-20 trash-color'>
						{t('channelPage.pageSubTitle')} - {data.data.name}
					</div>

					{/* Type box */}
					<div className='dashboard-box mt-3' style={{ overflow: 'unset' }}>
						<h2 className='type-title mt-2'>{t('channelPage.type')}</h2>

						<div className='d-flex flex-column flex-sm-row mt-5 gap-4 justify-content-between align-items-center'>
							{/* name */}
							<div className='d-flex flex-column align-items-baseline gap-1 w-100-perc '>
								<TextField
									className='channel-name-code-input w-100-perc'
									type={'text'}
									label={t('channelPage.name')}
									value={channelData.name}
									onChange={(event) =>
										setChannelData({ ...channelData, name: event.target.value })
									}
								/>
							</div>

							{/* code  */}
							<div className='d-flex flex-column align-items-baseline gap-1 w-100-perc'>
								<TextField
									className='channel-name-code-input w-100-perc'
									type={'text'}
									label={t('channelPage.code')}
									disabled
									value={channelData.code ? channelData.code : 'code'}
								/>
							</div>

							{/* type */}
							<div className='d-flex flex-column align-items-baseline gap-1 w-100-perc'>
								<FormControl fullWidth>
									<InputLabel id='select-channel'>
										{t('channelsPage.type')}
									</InputLabel>
									<Select
										labelId='select-channel'
										required
										label='channel'
										onChange={(event) =>
											setChannelData({
												...channelData,
												type: event.target.value,
											})
										}
										value={channelData.type}
									>
										{selectOptions.map((el) => (
											<MenuItem key={el.value} value={el.value}>
												<SvgIcon sx={{ mr: '10px' }}>{el.svg}</SvgIcon>
												{el.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</div>

							{/* status */}
							<div className=' d-flex flex-column gap-1 '>
								<Button
									size='large'
									sx={{
										width: '130px',
										height: '57px',
										border: `solid 1px ${
											channelData.enable ? '#72d830' : '#ff3131'
										}`,
										color: channelData.enable ? '#72d830' : '#ff3131',
										'&:hover': {
											border: `solid 2px ${
												channelData.enable ? '#72d830' : '#ff3131'
											}`,
										},
									}}
									variant='outlined'
									startIcon={
										<FontAwesomeIcon
											icon={channelData.enable ? faBell : faBellSlash}
										/>
									}
									onClick={() => {
										setChannelData({
											...channelData,
											enable: !channelData.enable,
										});
									}}
								>
									State
								</Button>
							</div>
						</div>
					</div>

					{/* Configuration box  */}
					<div className='dashboard-box mt-3' style={{ overflow: 'unset' }}>
						<h2 className='type-title mt-2'>Configuration</h2>
						<div className='d-flex flex-column mt-5'>
							{channelData.type === 'telegram' && (
								<div className='d-flex flex-column flex-sm-row justify-content-between gap-3'>
									{/* token */}
									<div className='d-flex flex-column align-items-baseline w-100-perc'>
										<TextField
											fullWidth
											label={'Telegram Bot Token'}
											type={'text'}
											value={channelData.telegramToken}
											onChange={(event) => {
												setChannelData({
													...channelData,
													telegramToken: event.target.value,
												});
											}}
										/>
									</div>
									{/* chatID */}
									<div className='d-flex flex-column align-items-baseline w-100-perc'>
										<Autocomplete
											className='input-width'
											multiple
											id='prova-autocomplete'
											options={[]}
											value={
												channelData.telegramChatId != ''
													? channelData.telegramChatId.split(',')
													: []
											}
											freeSolo
											fullWidth
											renderInput={(params) => (
												<TextField
													{...params}
													variant='outlined'
													label='Chat Id'
												/>
											)}
											onChange={(e, value) => {
												setChannelData({
													...channelData,
													telegramChatId: value.join(),
												});
											}}
										/>
									</div>
								</div>
							)}
							{channelData.type === 'discord' && (
								<div className='d-flex flex-column flex-sm-row justify-content-between gap-3'>
									{/* token */}
									<div className='d-flex flex-column align-items-baseline w-100-perc'>
										<TextField
											fullWidth
											label={'Discord Bot Token'}
											type={'text'}
											value={channelData.discordBotToken}
											onChange={(event) => {
												setChannelData({
													...channelData,
													discordBotToken: event.target.value,
												});
											}}
										/>
									</div>
									{/* chatID */}
									<div className='d-flex flex-column align-items-baseline w-100-perc'>
										<Autocomplete
											className='input-width'
											multiple
											id='prova-autocomplete'
											options={[]}
											value={
												channelData.discordChatIds != ''
													? channelData.discordChatIds.split(',')
													: []
											}
											freeSolo
											fullWidth
											renderInput={(params) => (
												<TextField
													{...params}
													variant='outlined'
													label='Chat Id'
												/>
											)}
											onChange={(e, value) => {
												setChannelData({
													...channelData,
													discordChatIds: value.join(),
												});
											}}
										/>
									</div>
								</div>
							)}
							{channelData.type === 'slack' && (
								<div className='d-flex flex-column flex-sm-row justify-content-between gap-3'>
									{/* token */}
									<div className='d-flex flex-column align-items-baseline w-100-perc'>
										<TextField
											fullWidth
											label={'Slack Bot Token'}
											type={'text'}
											value={channelData.slackToken}
											onChange={(event) => {
												setChannelData({
													...channelData,
													slackToken: event.target.value,
												});
											}}
										/>
									</div>
									{/* chatID */}
									<div className='d-flex flex-column align-items-baseline w-100-perc'>
										<Autocomplete
											className='input-width'
											multiple
											id='prova-autocomplete'
											options={[]}
											value={
												channelData.slackChatId != ''
													? channelData.slackChatId.split(',')
													: []
											}
											freeSolo
											fullWidth
											renderInput={(params) => (
												<TextField
													{...params}
													variant='outlined'
													label='Chat Id'
												/>
											)}
											onChange={(e, value) => {
												setChannelData({
													...channelData,
													slackChatId: value.join(),
												});
											}}
										/>
									</div>
								</div>
							)}
							{channelData.type === 'email' && (
								<div className='d-flex flex-column'>
									<div className='d-flex flex-column flex-sm-row justify-content-between gap-3'>
										{/* from */}
										<div className='d-flex flex-column align-items-baseline w-100-perc'>
											<TextField
												fullWidth
												label={t('channelPage.emailFrom')}
												type={'text'}
												value={channelData.emailFrom}
												onChange={(event) => {
													setChannelData({
														...channelData,
														emailFrom: event.target.value,
													});
												}}
											/>
										</div>
										{/* to */}
										<div className='d-flex flex-column align-items-baseline w-100-perc'>
											<Autocomplete
												className='input-width'
												multiple
												id='prova-autocomplete'
												options={[]}
												value={
													channelData.emails != ''
														? channelData.emails.split(',')
														: []
												}
												freeSolo
												fullWidth
												renderInput={(params) => (
													<TextField
														{...params}
														variant='outlined'
														label={t('channelPage.emailTo')}
													/>
												)}
												onChange={(e, value) => {
													setChannelData({
														...channelData,
														emails: value.join(),
													});
												}}
											/>
										</div>
									</div>

									<div className='d-flex flex-column flex-sm-row justify-content-between gap-3 mt-3'>
										{/* host */}
										<div className='d-flex flex-column align-items-baseline w-100-perc'>
											<TextField
												fullWidth
												label='Host'
												type={'text'}
												value={channelData.emailHost}
												onChange={(event) => {
													setChannelData({
														...channelData,
														emailHost: event.target.value,
													});
												}}
											/>
										</div>
										{/* port */}
										<div className='d-flex flex-column align-items-baseline w-100-perc'>
											<TextField
												fullWidth
												label='Port'
												type='number'
												value={channelData.emailPort}
												onChange={(event) => {
													setChannelData({
														...channelData,
														emailPort: event.target.value,
													});
												}}
											/>
										</div>
									</div>

									<div className='d-flex flex-column flex-sm-row justify-content-between gap-3 mt-3'>
										{/* username */}
										<div className='d-flex flex-column align-items-baseline w-100-perc'>
											<TextField
												fullWidth
												label={t('channelPage.username')}
												type='text'
												value={channelData.emailUsername}
												onChange={(event) => {
													setChannelData({
														...channelData,
														emailUsername: event.target.value,
													});
												}}
											/>
										</div>
										{/* password */}
										<div className='d-flex flex-column align-items-baseline w-100-perc'>
											<TextField
												fullWidth
												label={t('channelPage.username')}
												type={showPass ? 'text' : 'password'}
												value={channelData.emailPassword}
												onChange={(event) => {
													setChannelData({
														...channelData,
														emailPassword: event.target.value,
													});
												}}
												InputProps={{
													endAdornment: (
														<IconButton onClick={() => setShowPass(!showPass)}>
															<FontAwesomeIcon
																icon={showPass ? faEyeSlash : faEye}
															/>
														</IconButton>
													),
												}}
											/>
											<FormControl>
												<InputLabel htmlFor='outlined-adornment-password'>
													Password
												</InputLabel>
											</FormControl>
										</div>
									</div>

									{/* encryption */}
									<div className='d-flex flex-column align-items-baseline mt-3'>
										<div className='w-100 font-15 fw-bold mb-2'>Encryption</div>
										<FormControl>
											<FormLabel id='demo-row-radio-buttons-group-label'>
												Encryption
											</FormLabel>
											<RadioGroup row>
												<FormControlLabel
													value='none'
													control={<Radio />}
													label='NONE'
													checked={checkedEncryption('none')}
													onClick={() => {
														setChannelData({
															...channelData,
															emailEncryption: 'none',
														});
													}}
												/>
												<FormControlLabel
													value='tls'
													control={<Radio />}
													label='TLS'
													checked={checkedEncryption('tls')}
													onClick={() => {
														setChannelData({
															...channelData,
															emailEncryption: 'tls',
														});
													}}
												/>
												<FormControlLabel
													value='ssl'
													control={<Radio />}
													label='SSL'
													checked={checkedEncryption('ssl')}
													onClick={() => {
														setChannelData({
															...channelData,
															emailEncryption: 'ssl',
														});
													}}
												/>
												<FormControlLabel
													value='starttls'
													control={<Radio />}
													label='STARTTLS'
													checked={checkedEncryption('starttls')}
													onClick={() => {
														setChannelData({
															...channelData,
															emailEncryption: 'starttls',
														});
													}}
												/>
												<FormControlLabel
													value='ssltls'
													control={<Radio />}
													label='SSLTLS'
													checked={checkedEncryption('ssltls')}
													onClick={() => {
														setChannelData({
															...channelData,
															emailEncryption: 'ssltls',
														});
													}}
												/>
											</RadioGroup>
										</FormControl>
									</div>
								</div>
							)}
							{channelData.type === 'http' && (
								<div>
									{/* url */}
									<div className='d-flex flex-column align-items-baseline w-100-perc'>
										<TextField
											fullWidth
											label='Url'
											variant='outlined'
											type={'text'}
											value={channelData.httpUrl}
											onChange={(event) => {
												setChannelData({
													...channelData,
													httpUrl: event.target.value,
												});
											}}
										/>
									</div>

									{/* method & auth */}
									<div className='d-flex flex-column gap-3 flex-sm-row w-100-perc mt-4 justify-content-between mb-3'>
										{/* method  */}
										<FormControl fullWidth>
											<InputLabel id='select-method'>Method</InputLabel>
											<Select
												labelId='select-method'
												required
												label='metho'
												onChange={(event) => {
													setChannelData({
														...channelData,
														httpMethod: event.target.value,
													});
												}}
												value={channelData.httpMethod}
											>
												<MenuItem value={'GET'}>GET</MenuItem>
												<MenuItem value={'POST'}>POST</MenuItem>
												<MenuItem value={'PUT'}>PUT</MenuItem>
												<MenuItem value={'PATCH'}>PATCH</MenuItem>
											</Select>
										</FormControl>

										{/* auth */}
										<FormControl fullWidth>
											<InputLabel id='select-auth'>Auth</InputLabel>
											<Select
												labelId='select-auth'
												required
												label='auth'
												onChange={(e) => setSwitchAuth(e.target.value)}
												value={switchAuth}
											>
												<MenuItem value={'basic'}>Basic Access</MenuItem>
												<MenuItem value={'bearer'}>Bearer-Token</MenuItem>
												<MenuItem value={'custom'}>Custom Headers</MenuItem>
												<MenuItem value={'no auth'}>No authentication</MenuItem>
											</Select>
										</FormControl>
									</div>

									{switchAuth === 'basic' && (
										<div className='d-flex flex-column flex-sm-row my-4 justify-content-between gap-3'>
											<TextField
												type={'text'}
												value={channelData.httpUser}
												onChange={(event) => {
													setChannelData({
														...channelData,
														httpUser: event.target.value,
													});
												}}
												fullWidth
												label={t('channelPage.username')}
											/>

											<TextField
												fullWidth
												label='Password'
												value={channelData.httpPassword}
												onChange={(event) => {
													setChannelData({
														...channelData,
														httpPassword: event.target.value,
													});
												}}
											/>
										</div>
									)}
									{switchAuth === 'bearer' && (
										<div className='d-flex flex-column align-items-baseline my-4'>
											<TextField
												fullWidth
												label='Token'
												type={'text'}
												value={channelData.httpToken}
												onChange={(event) => {
													setChannelData({
														...channelData,
														httpToken: event.target.value,
													});
												}}
											/>
										</div>
									)}
									{switchAuth === 'custom' && (
										<div className='mt-4'>
											{/* headers  */}
											<div className='d-flex flex-wrap gap-3 align-items-center'>
												<h6 className=''>{t('channelPage.yourHeaders')}: </h6>
												{channelData.httpCustomHeaders &&
													channelData.httpCustomHeaders.map((header, i) => (
														<div key={`header-${i}`}>
															<div className='d-flex gap-1 mb-2 align-items-center'>
																<FontAwesomeIcon
																	className='pointer-hover text-danger'
																	icon={faXmark}
																	onClick={() => {
																		removeHeader(header.key);
																	}}
																/>
																<div>
																	"{header?.key}": "{header.value}"{' '}
																	{i + 1 !==
																	channelData.httpCustomHeaders.length
																		? ','
																		: ''}
																</div>
															</div>
														</div>
													))}
											</div>

											{/* key & value */}
											<div className='d-flex flex-column flex-sm-row gap-3 align-items-baseline justify-content-between mt-3'>
												{/* key */}
												<div className='d-flex flex-column align-items-baseline w-100-perc'>
													<TextField
														fullWidth
														label={t('channelPage.key')}
														type={'text'}
														value={customHeaders.key}
														onChange={(event) => {
															setCustomHeaders({
																...customHeaders,
																key: event.target.value,
															});
														}}
													/>
												</div>
												{/* value */}
												<div className='d-flex align-items-center gap-2 w-100-perc'>
													<TextField
														fullWidth
														label={t('channelPage.value')}
														type={'text'}
														value={customHeaders.value}
														onChange={(event) => {
															setCustomHeaders({
																...customHeaders,
																value: event.target.value,
															});
														}}
													/>
													<Button
														size='large'
														sx={{
															width: '130px',
															height: '57px',
															border: `solid 1px #72d830`,
															color: '#72d830',
															'&:hover': {
																border: 'solid 2px #72d830',
															},
														}}
														variant='outlined'
														startIcon={<FontAwesomeIcon icon={faPlus} />}
														onClick={() => addHeader()}
													>
														{t('channelPage.add')}
													</Button>
												</div>
											</div>
										</div>
									)}
								</div>
							)}
						</div>
					</div>
					<div className='d-flex justify-content-end mb-3 mt-2 me-3'>
						<Button
							variant='contained'
							sx={{ width: '100px', mt: 1 }}
							onClick={() => handleSaveChannel()}
						>
							{t('channelPage.save')}
						</Button>
					</div>
				</>
			)}
		</div>
	);
}
