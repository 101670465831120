import { User, Users } from "api/queries";
import UserClass from "models/user";
import confirmAlert from "libs/confirmAlert";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DeleteUser, UpdateUser } from "api/mutations";

function useUserQuery({ user, userId }) {
  const { t } = useTranslation();
  const history = useHistory();
  const queryClient = useQueryClient();

  // Get Users
  const { isLoading, data } = useQuery(["Users", user.currentAccount], Users, {
    retry: false,
    onSuccess: (data) => {
      // modify data struct
      const newArr = [];
      data.data.forEach((element) => {
        let userModel = new UserClass(element, user.currentAccount);
        newArr.push(userModel);
      });
      data.data = newArr;
    },
  });

  // Delete User
  const deleteUserMutate = useMutation(DeleteUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Users", user.currentAccount]);
    },
  });

  const deleteUser = async ({ userId }) => {
    try {
      const response = await deleteUserMutate.mutateAsync(userId);
      if (response) {
        confirmAlert.success(t("indexUsersPage.userDeleted"));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        confirmAlert.error(error.response.data);
        return;
      }
    }
  };

  // Update User
  const updateUserMutate = useMutation(UpdateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Users", user.currentAccount]);
      queryClient.invalidateQueries(["User", user.currentAccount]);
    },
  });

  const updateUser = async ({ data, userId }) => {
    try {
      const response = await updateUserMutate.mutateAsync({
        userId: userId,
        data: data,
      });
      if (response) {
        history.push("/users");
      }
    } catch (error) {
      if (error.response?.data) {
        confirmAlert.error(error.response.data);
        return;
      }
    }
  };

  // Get User (da finire )
  // const { isLoading: loadingUser, data: userData } = useQuery(
  //   ["User", user.currentAccount],
  //   () => User(userId),
  //   {
  //     retry: false,
  //     onSuccess: (data) => {
  //       const userStruct = new UserClass(data.data, user.currentAccount);
  //       data.data = userStruct;
  //       if (data.data.accountOwner) {
  //         history.push("/users");
  //       } /* else {
  //         setUser(data.data);
  //         setUserData({ role: data.data.role });
  //       } */
  //     },
  //   }
  // );

  return {
    users: { isLoading, data },
    deleteUser,
    updateUser,
    // user: { loadingUser, userData },
  };
}

export default useUserQuery;
