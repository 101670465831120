import { BACKOFFICE } from 'config';
import { JWT_TOKEN, SIGNUP_WITH_ACTIVATE } from 'config';
import Axios from 'libs/axios';
import Storage from 'libs/storage';

const Logout = async () => {
	Storage.deleteKey(JWT_TOKEN);
};

const Login = async (data) => {
	const result = await Axios.base().post('/auth/login', data);
	Storage.setItem(JWT_TOKEN, result.data.token);
	return result;
};

const ForgotPassword = async (data) => {
	const result = await Axios.base().post(
		'/auth/send-forgot-password-link',
		data
	);
	return result;
};

const ResetPassword = async (data) => {
	const result = await Axios.base().post('/auth/reset-password', data);
	return result;
};

const ResendActivation = async (data) => {
	const result = await Axios.base().post('/auth/send-activation-link', data);
	return result;
};

const Register = async (data) => {
	const result = await Axios.base().post('/auth/signup', data);
	if (SIGNUP_WITH_ACTIVATE) {
		Storage.setItem(JWT_TOKEN, result.data.token);
	}
	return result;
};

const RegisterWithinvitation = async (data) => {
	const result = await Axios.base().post('/auth/signup-with-invitation', data);
	Storage.setItem(JWT_TOKEN, result.data.token);
	return result;
};

const Activate = async (data) => {
	const result = await Axios.base().post('/auth/activate', data);
	return result;
};

const ActivateTrial = async () => {
	const result = await Axios.authenticated().post('/accounts/activate-trial');
	return result;
};

const UpdateAccount = async (data) => {
	const result = await Axios.authenticated().put(
		BACKOFFICE + `/accounts/${data.accountId}`,
		data.data
	);
	return result;
};

const AddAccount = async (token) => {
	const result = await Axios.authenticated().put(
		`/users/me/add-account/${token}`
	);
	return result;
};

// remove account from accounts
const RemoveAccount = async (id) => {
	const result = await Axios.authenticated().delete(
		`/users/me/remove-account/${id}`
	);
	return result;
};

const UploadAccountImage = async ({ image }) => {
	const formData = new FormData();
	formData.append('image', image);
	const result = await Axios.authenticated().post(
		BACKOFFICE + '/accounts/image',
		formData,
		{
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}
	);
	return result;
};

const UpdateAccountName = async (data) => {
	const result = await Axios.authenticated().put(
		'/users/me/account-name',
		data
	);
	return result;
};

const Subscribe = async (data) => {
	const result = await Axios.authenticated().post(
		BACKOFFICE + '/stripe/subscriptions',
		data
	);
	return result;
};

const CancelSubscription = async (data) => {
	const result = await Axios.authenticated().delete(
		BACKOFFICE + '/stripe/subscriptions',
		{
			data: data,
		}
	);
	return result;
};

const CreateSetupIntent = async (data) => {
	const result = await Axios.authenticated().post(
		BACKOFFICE + '/stripe/create-setup-intent',
		data
	);
	return result;
};

const RemoveCreditCard = async (data) => {
	const result = await Axios.authenticated().delete(
		BACKOFFICE + '/stripe/cards',
		{
			data: data,
		}
	);
	return result;
};

const SetDefaultCreditCard = async (data) => {
	const result = await Axios.authenticated().put(
		BACKOFFICE + '/stripe/cards',
		data
	);
	return result;
};

const UpdateCustomer = async (data) => {
	const result = await Axios.authenticated().put(
		BACKOFFICE + `/stripe/customer/update/${data.accountId}`,
		data.data
	);
	return result;
};

const ChangePassword = async (data) => {
	const result = await Axios.authenticated().put(
		'/users/me/change-password',
		data
	);
	return result;
};

const UpdateMe = async (data) => {
	const result = await Axios.authenticated().put('/users/me', data);
	return result;
};

const ChangeAccountStatus = async (id) => {
	const result = await Axios.authenticated().put(
		`/users/me/enable-notifications/${id}`
	);
	return result;
};

const ChangeCurrentAccount = async (id) => {
	const result = await Axios.authenticated().put(`/users/change-account/${id}`);
	return result;
};

// update role & active
const UpdateUser = async (data) => {
	const result = await Axios.authenticated().put(
		BACKOFFICE + `/users/${data.userId}`,
		data.data
	);
	return result;
};

// Admin delete user
const DeleteUser = async (userId) => {
	const result = await Axios.authenticated().delete(
		BACKOFFICE + `/users/${userId}`
	);
	return result;
};

const CreateCustomerCheckoutSession = async (data) => {
	console.log('-----', data);
	const result = await Axios.authenticated().post(
		BACKOFFICE + `/stripe/create-customer-checkout-session`,
		data
	);
	return result;
};

const CreateCustomerPortalSession = async () => {
	const result = await Axios.authenticated().post(
		BACKOFFICE + `/stripe/create-customer-portal-session`
	);
	return result;
};

const LogEntryDelete = async (id) => {
	const result = await Axios.authenticated().put(`log-entries/${id}/delete`);
	return result;
};

const LogEntryStar = async (id) => {
	const result = await Axios.authenticated().put(`log-entries/${id}/star`);
	return result;
};

const LogEntryUnstar = async (id) => {
	const result = await Axios.authenticated().put(`log-entries/${id}/unstar`);
	return result;
};

const CreateGroup = async (data) => {
	const result = await Axios.authenticated().post(BACKOFFICE + '/groups', data);
	return result;
};

const DeleteGroup = async (data) => {
	const result = await Axios.authenticated().delete(
		BACKOFFICE + `/groups/${data}`
	);
	return result;
};

const UpdateGroup = async ({ id, data }) => {
	const result = await Axios.authenticated().put(
		BACKOFFICE + `/groups/${id}`,
		data
	);
	return result;
};

const RemoveGroupUser = async ({ groupId, userId }) => {
	const result = await Axios.authenticated().put(
		BACKOFFICE + `/groups/${groupId}/remove-user/${userId}`
	);
	return result;
};

const AddUserGroup = async ({ groupId, userId }) => {
	const result = await Axios.authenticated().put(
		BACKOFFICE + `/groups/${groupId}/add-user/${userId}`
	);
	return result;
};

const CreateChannel = async (data) => {
	const result = await Axios.authenticated().post(
		BACKOFFICE + '/channels',
		data
	);
	return result;
};

const DeleteChannel = async (data) => {
	const result = await Axios.authenticated().delete(
		BACKOFFICE + `/channels/${data}`
	);
	return result;
};

const UpdateChannel = async (data) => {
	const result = await Axios.authenticated().put(
		BACKOFFICE + `/channels/${data.channelId}`,
		data.data
	);
	return result;
};

const RemoveCustomHeader = async (data) => {
	const result = await Axios.authenticated().put(
		BACKOFFICE + `/channels/${data.channelId}/${data.key}`
	);
	return result;
};

const SearchProperties = async ({ projectId, page, limit, data }) => {
	const result = await Axios.authenticated().post(
		`/log-entries/${projectId}/search-properties?page=${page}&limit=${limit}&sort=-created_at`,
		data
	);
	return result;
};

const CreateInvitation = async (data) => {
	const result = await Axios.authenticated().post(
		BACKOFFICE + '/invitation',
		data
	);
	return result;
};

const SendInvitation = async (id) => {
	const result = await Axios.authenticated().post(
		BACKOFFICE + `/invitation/send-email/${id}`
	);
	return result;
};

const DeleteInvitaton = async (id) => {
	const result = await Axios.authenticated().delete(
		BACKOFFICE + `/invitation/${id}`
	);
	return result;
};

const CreateProject = async (data) => {
	const result = await Axios.authenticated().post(
		BACKOFFICE + '/projects',
		data
	);
	return result;
};

const DeleteProject = async (id) => {
	const result = await Axios.authenticated().delete(
		BACKOFFICE + `/projects/${id}`
	);
	return result;
};

const UpdateProject = async ({ id, data }) => {
	const result = await Axios.authenticated().put(
		BACKOFFICE + `/projects/${id}`,
		data
	);
	return result;
};

const ProjectsExternalUsers = async (data) => {
	const result = await Axios.authenticated().post(
		BACKOFFICE + `/projects-external-user`,
		data
	);
	return result;
};

const ChangeProjectStatus = async (id) => {
	const result = await Axios.authenticated().put(
		BACKOFFICE + `/projects/status/${id}`
	);
	return result;
};

const CreateNewAccount = async (data) => {
	const result = await Axios.authenticated().post(
		BACKOFFICE + `/auth/create-account`,
		data
	);
	return result;
};

export {
	Logout,
	Login,
	ForgotPassword,
	ResetPassword,
	ResendActivation,
	Register,
	Activate,
	UpdateAccount,
	AddAccount,
	RemoveAccount,
	UploadAccountImage,
	UpdateAccountName,
	Subscribe,
	CancelSubscription,
	RemoveCreditCard,
	SetDefaultCreditCard,
	ChangePassword,
	UpdateMe,
	ChangeAccountStatus,
	ChangeCurrentAccount,
	CreateSetupIntent,
	UpdateUser,
	DeleteUser,
	CreateCustomerPortalSession,
	CreateCustomerCheckoutSession,
	LogEntryDelete,
	LogEntryStar,
	LogEntryUnstar,
	CreateGroup,
	DeleteGroup,
	UpdateGroup,
	RemoveGroupUser,
	AddUserGroup,
	CreateChannel,
	DeleteChannel,
	UpdateChannel,
	RemoveCustomHeader,
	UpdateCustomer,
	SearchProperties,
	CreateInvitation,
	DeleteInvitaton,
	SendInvitation,
	CreateProject,
	DeleteProject,
	UpdateProject,
	RegisterWithinvitation,
	ActivateTrial,
	ProjectsExternalUsers,
	ChangeProjectStatus,
	CreateNewAccount,
};
