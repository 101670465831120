import React, { useState } from 'react';
import { Group, Users } from 'api/queries';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'app/components/Loader';
import 'intro.js/introjs.css';
import { useTranslation } from 'react-i18next';
import UserClass from 'models/user';
import useGroupQuery from 'hoc/useGroupQuery';
import { Stack, TextField, Button as ButtonMUI } from '@mui/material';

export default function GroupPage({ user }) {
	const { t } = useTranslation();

	const { groupid } = useParams();

	const [modal, setModal] = useState(false);

	const [groupName, setGroupName] = useState(null);

	const [sortbyList, setSortbyList] = useState({
		name: true,
		email: true,
		role: true,
	});

	const { removeUser, addUser, updateGroup } = useGroupQuery(user);

	const { isLoading, data } = useQuery(
		['group', user.currentAccount],
		() => Group(groupid),
		{
			enabled: !!groupid,
			onSuccess: (data) => {
				setGroupName(data.data.name);
			},
		}
	);

	const { isLoading: loadingUsers, data: users } = useQuery(
		['users', user.currentAccount],
		Users,
		{
			onSuccess: (data) => {
				const newArr = [];
				data.data.forEach((element) => {
					let userModel = new UserClass(element, user.currentAccount);
					newArr.push(userModel);
				});
				data.data = newArr;
			},
		}
	);

	const sortBy = (by) => {
		switch (by) {
			case 'name':
				if (sortbyList.name) {
					users.data.sort((a, b) => {
						const titleA = a.name.toUpperCase();
						const titleB = b.name.toUpperCase();
						if (titleA < titleB) {
							return -1;
						}
						if (titleA > titleB) {
							return 1;
						}
						return 0;
					});
				} else {
					users.data.sort((a, b) => {
						const titleA = a.name.toUpperCase();
						const titleB = b.name.toUpperCase();
						if (titleA > titleB) {
							return -1;
						}
						if (titleA < titleB) {
							return 1;
						}
						return 0;
					});
				}
				setSortbyList({ ...sortbyList, name: !sortbyList.name });
				break;

			case 'email':
				if (sortbyList.email) {
					users.data.sort((a, b) => {
						const titleA = a.email.toUpperCase();
						const titleB = b.email.toUpperCase();
						if (titleA < titleB) {
							return -1;
						}
						if (titleA > titleB) {
							return 1;
						}
						return 0;
					});
				} else {
					users.data.sort((a, b) => {
						const titleA = a.email.toUpperCase();
						const titleB = b.email.toUpperCase();
						if (titleA > titleB) {
							return -1;
						}
						if (titleA < titleB) {
							return 1;
						}
						return 0;
					});
				}
				setSortbyList({ ...sortbyList, email: !sortbyList.email });
				break;

			case 'role':
				if (sortbyList.role) {
					users.data.sort((a, b) => {
						const titleA = a.role.toUpperCase();
						const titleB = b.role.toUpperCase();
						if (titleA < titleB) {
							return -1;
						}
						if (titleA > titleB) {
							return 1;
						}
						return 0;
					});
				} else {
					users.data.sort((a, b) => {
						const titleA = a.role.toUpperCase();
						const titleB = b.role.toUpperCase();
						if (titleA > titleB) {
							return -1;
						}
						if (titleA < titleB) {
							return 1;
						}
						return 0;
					});
				}
				setSortbyList({ ...sortbyList, role: !sortbyList.role });
				break;

			default:
				break;
		}
	};

	if (isLoading && loadingUsers) {
		return <Loader />;
	}

	return (
		<>
			<Modal show={modal} centered onHide={() => setModal(false)}>
				<div className='delete-popup'>
					<div className='text-center m-3'>{t('groupPage.changesSaved')}</div>
					<div className='d-flex justify-content-center gap-5 mb-3'>
						<Link
							to={'/groups'}
							className='group-cancel-button d-flex justify-content-center align-items-center text-decoration-none'
						>
							{t('groupPage.goGroupsPage')}
						</Link>
						<div
							onClick={() => {
								setModal(false);
							}}
							className='group-cancel-button d-flex justify-content-center align-items-center'
						>
							ok
						</div>
					</div>
				</div>
			</Modal>
			<div className='max-width m-0-auto'>
				{data && (
					<>
						<h1 className='title-font'>{t('groupsPage.title')}</h1>
						<div className='mt-2 font-20 trash-color'>
							{t('groupsPage.title')} - {data.data.name}
						</div>
						<div className='d-flex flex-column flex-lg-row gap-4'>
							<div className='dashboard-box mt-3'>
								<div className='d-flex justify-content-end mb-4'>
									<ButtonMUI
										variant='contained'
										sx={{ mt: '15px', minWidth: '100px' }}
										onClick={() => setModal(true)}
									>
										{t('groupPage.save')}
									</ButtonMUI>
									{/* <Link
										to={'#'}
										onClick={() => setModal(true)}
										className='table-row-color mt-3 create-group-button d-flex justify-content-center align-items-center text-decoration-none'
									>
										{t('groupPage.save')}
									</Link> */}
								</div>
								<Table responsive bordered>
									<thead>
										<tr className='d-none d-md-table-row'>
											<th className='col-1 font-20'>
												<div className='unchecked-user' />
											</th>
											<th className='col-1 text-start font-20'>#</th>
											<th className='col-1 text-start font-20'>
												<span
													className='pointer-hover'
													onClick={() => sortBy('name')}
												>
													{t('groupPage.user')}
												</span>
											</th>
											<th className='col-1 text-start font-20'>
												<span
													className='pointer-hover'
													onClick={() => sortBy('email')}
												>
													Email
												</span>
											</th>
											<th className='col-1 text-start font-20'>
												<span
													className='pointer-hover'
													onClick={() => sortBy('role')}
												>
													{t('userEdit.role')}
												</span>
											</th>
										</tr>
									</thead>
									{users && (
										<tbody>
											{users.data.map((user, id) => (
												<tr
													key={id}
													className='table-row-color font-14 d-flex flex-column d-md-table-row '
												>
													{/* {user.role !== "external" && */}
													<>
														{data.data.users.find(
															(userAccount) => userAccount.id === user.id
														) ? (
															<td className='d-flex justify-content-start align-items-center'>
																<div
																	className='d-flex justify-content-center align-items-center check-user'
																	onClick={() =>
																		removeUser({
																			userId: user.id,
																			groupId: groupid,
																		})
																	}
																>
																	<FontAwesomeIcon
																		className='text-white'
																		icon={faCheck}
																	/>
																</div>
															</td>
														) : (
															<td>
																<div className='d-flex justify-content-start align-items-center'>
																	<div
																		className='unchecked-user'
																		onClick={() =>
																			addUser({
																				userId: user.id,
																				groupId: groupid,
																			})
																		}
																	/>
																</div>
															</td>
														)}
														<div className='d-md-none font-16 fw-bold pb-0'>
															#:
														</div>
														<td className='text-start vertical-align'>
															{id + 1}
														</td>
														<div className='d-md-none font-16 fw-bold pb-0'>
															{t('groupPage.user')}:
														</div>
														<td className='text-start vertical-align'>
															{user.name}
														</td>
														<div className='d-md-none font-16 fw-bold pb-0'>
															Email:
														</div>
														<td className='text-start vertical-align'>
															{user.email}
														</td>
														<div className='d-md-none font-16 fw-bold pb-0'>
															{t('userEdit.role')}:
														</div>
														<td className='text-start vertical-align'>
															{user.owner ? 'Manager' : user.role}
														</td>
													</>
													{/* }  */}
												</tr>
											))}
										</tbody>
									)}
								</Table>
							</div>
							<div
								className='dashboard-box mt-3 group-box-rename pt-3 pb-4'
								style={{ height: 'max-content' }}
							>
								<Form
									onSubmit={(e) => {
										e.preventDefault();
										updateGroup({
											data: { name: groupName },
											groupId: data.data.id,
										});
									}}
								>
									<Stack gap={3} marginTop={1}>
										<TextField
											value={groupName ? groupName : ''}
											label={t('createGroup.groupName')}
											variant='outlined'
											fullWidth
											required
											onChange={(e) => setGroupName(e.target.value)}
											type={'text'}
										/>
										<TextField
											variant='outlined'
											fullWidth
											label='Code'
											disabled
											value={data.data.code}
										/>
									</Stack>
									<div className='d-flex justify-content-end'>
										<ButtonMUI
											type='submit'
											variant='contained'
											sx={{ mt: '25px', minWidth: '100px' }}
										>
											{t('groupPage.save')}
										</ButtonMUI>
										{/* <Button
											type='submit'
											className='table-row-color mt-4 create-group-button d-flex justify-content-center align-items-center text-decoration-none'
										>
											{t('groupPage.save')}
										</Button> */}
									</div>
								</Form>
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
}
