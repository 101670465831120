import {
  AddUserGroup,
  CreateGroup,
  DeleteGroup,
  RemoveGroupUser,
  UpdateGroup,
} from "api/mutations";
import confirmAlert from "libs/confirmAlert";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Groups } from "api/queries";

function useGroupQuery(user) {
  const { t } = useTranslation();
  const history = useHistory();
  const queryClient = useQueryClient();

  // Get Groups List
  const { isLoading, data } = useQuery(["groups", user.currentAccount], () =>
    Groups()
  );

  // Delete Group
  const deleteGroupMutation = useMutation(DeleteGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries(["groups", user.currentAccount]);
    },
  });

  const deleteGroup = async (groupId) => {
    const data = groupId;
    try {
      const response = await deleteGroupMutation.mutateAsync(data);
      if (response) {
        confirmAlert.success(t("groupsPage.groupDeleted"));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        confirmAlert.error(error.response.data);
        return;
      }
    }
  };

  // Create Group
  const createGroupMutate = useMutation(CreateGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries(["groups", user.currentAccount]);
    },
  });

  const createGroup = async (data) => {
    try {
      const response = await createGroupMutate.mutateAsync(data);
      return response;
    } catch (error) {
      if (error.response && error.response.data) {
        confirmAlert.error(error.response.data);
        return;
      }
    }
  };

  // Remove User from Group
  const removeGroupUserMutation = useMutation(RemoveGroupUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(["group", user.currentAccount]);
      queryClient.invalidateQueries(["users", user.currentAccount]);
      queryClient.invalidateQueries(["groups", user.currentAccount]);
    },
  });

  const removeUser = async ({ groupId, userId }) => {
    try {
      const response = await removeGroupUserMutation.mutateAsync({
        groupId: groupId,
        userId: userId,
      });
    } catch (error) {
      if (error.response && error.response.data) {
        confirmAlert.error(error.response.data);
        return;
      }
    }
  };

  // Add User to Group
  const addUserMutation = useMutation(AddUserGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries(["group", user.currentAccount]);
      queryClient.invalidateQueries(["users", user.currentAccount]);
      queryClient.invalidateQueries(["groups", user.currentAccount]);
    },
  });

  const addUser = async ({ userId, groupId }) => {
    try {
      const response = await addUserMutation.mutateAsync({
        groupId: groupId,
        userId: userId,
      });
    } catch (error) {
      if (error.response && error.response.data) {
        confirmAlert.error(error.response.data);
        return;
      }
    }
  };

  // Update Group
  const updateGroupMutation = useMutation(UpdateGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries(["group", user.currentAccount]);
      queryClient.invalidateQueries(["users", user.currentAccount]);
    },
  });

  const updateGroup = async ({ groupId, data }) => {
    try {
      const response = await updateGroupMutation.mutateAsync({
        id: groupId,
        data: data,
      });
      console.log(response);
      if (response) {
        confirmAlert.success(t("groupPage.groupUpdated"));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        confirmAlert.error(error.response.data);
        return;
      }
    }
  };

  return {
    createGroup,
    deleteGroup,
    removeUser,
    groupsList: { isLoading, data },
    addUser,
    updateGroup,
  };
}

export default useGroupQuery;
