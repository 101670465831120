import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { User } from 'api/queries';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import Loader from 'app/components/Loader';
import {
	faBell,
	faBellSlash,
	faCheck,
	faUser,
	faUserSlash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserClass from 'models/user';
import useGroupQuery from 'hoc/useGroupQuery';
import useUserQuery from 'hoc/useUserQuery';
import {
	InputLabel,
	MenuItem,
	Select,
	TextField,
	FormControl,
} from '@mui/material';
import { Button as ButtonMUI } from '@mui/material';

export default function EditUser(props) {
	const { t } = useTranslation();

	const [modal, setModal] = useState(false);

	const [user, setUser] = useState(undefined);
	const [userData, setUserData] = useState({
		role: null,
		active: false,
	});

	const [sortbyList, setSortbyList] = useState({
		name: true,
		code: true,
		users: true,
	});

	const { userId } = useParams();

	const { isLoading, data } = useQuery(
		['User', props.user.currentAccount],
		() => User(userId),
		{
			retry: false,
			onSuccess: (data) => {
				console.log('Data: ', data);
				const user = new UserClass(data.data, props.user.currentAccount);
				data.data = user;
				if (data.data.accountOwner) {
					props.history.push('/users');
				} else {
					setUser(data.data);
					setUserData({
						role: data.data.role,
						active: data.data.active,
					});
				}
			},
		}
	);

	const { updateUser } = useUserQuery({ user: props.user });

	const { removeUser, groupsList, addUser } = useGroupQuery(props.user);
	const { isLoading: loadingGroups, data: groups } = groupsList;

	const sortBy = (by) => {
		switch (by) {
			case 'name':
				if (sortbyList.name) {
					groups.data.sort((a, b) => {
						const titleA = a.name.toUpperCase();
						const titleB = b.name.toUpperCase();
						if (titleA < titleB) {
							return -1;
						}
						if (titleA > titleB) {
							return 1;
						}
						return 0;
					});
				} else {
					groups.data.sort((a, b) => {
						const titleA = a.name.toUpperCase();
						const titleB = b.name.toUpperCase();
						if (titleA > titleB) {
							return -1;
						}
						if (titleA < titleB) {
							return 1;
						}
						return 0;
					});
				}
				setSortbyList({ ...sortbyList, name: !sortbyList.name });
				break;
			case 'code':
				if (sortbyList.code) {
					groups.data.sort((a, b) => {
						const titleA = a.code.toUpperCase();
						const titleB = b.code.toUpperCase();
						if (titleA < titleB) {
							return -1;
						}
						if (titleA > titleB) {
							return 1;
						}
						return 0;
					});
				} else {
					groups.data.sort((a, b) => {
						const titleA = a.code.toUpperCase();
						const titleB = b.code.toUpperCase();
						if (titleA > titleB) {
							return -1;
						}
						if (titleA < titleB) {
							return 1;
						}
						return 0;
					});
				}
				setSortbyList({ ...sortbyList, code: !sortbyList.code });
				break;

			case 'users':
				if (sortbyList.users) {
					groups.data.sort((a, b) => {
						const titleA = a.users.length;
						const titleB = b.users.length;
						if (titleA < titleB) {
							return -1;
						}
						if (titleA > titleB) {
							return 1;
						}
						return 0;
					});
				} else {
					groups.data.sort((a, b) => {
						const titleA = a.users.length;
						const titleB = b.users.length;
						if (titleA > titleB) {
							return -1;
						}
						if (titleA < titleB) {
							return 1;
						}
						return 0;
					});
				}
				setSortbyList({ ...sortbyList, users: !sortbyList.users });
				break;

			default:
				break;
		}
	};

	if ((isLoading, loadingGroups)) {
		return <Loader />;
	}

	return (
		<>
			<Modal show={modal} centered onHide={() => setModal(false)}>
				<div className='delete-popup '>
					<div className='text-center m-3'>{t('userEdit.confirmSave')}</div>
					<div className='d-flex justify-content-center gap-5 mb-3'>
						<div
							onClick={() => {
								updateUser({ data: userData, userId: userId });
								setModal(false);
							}}
							className='group-cancel-button d-flex justify-content-center align-items-center'
						>
							{t('indexUsersPage.yes')}
						</div>
						<div
							onClick={() => {
								setModal(false);
							}}
							className='group-cancel-button d-flex justify-content-center align-items-center'
						>
							{t('indexUsersPage.no')}
						</div>
					</div>
				</div>
			</Modal>
			{data && data.data && (
				<div className='max-width m-0-auto'>
					<h1 className='title-font'>{t('indexUsersPage.users')}</h1>

					{/* user profile */}
					<div className='dashboard-box mt-5 py-4 px-5'>
						<h2 className='type-title mt-2'>User profile</h2>

						<div className='d-flex flex-column flex-sm-row gap-3 justify-content-between align-items-center mt-5'>
							{/* name */}
							<TextField
								label={t('userEdit.name')}
								fullWidth
								disabled
								value={data.data.name}
								type={'text'}
							/>
							{/* surname */}
							<TextField
								label={t('userEdit.surname')}
								fullWidth
								disabled
								value={data.data.surname}
								type={'text'}
							/>

							{/* language */}
							<TextField
								label={t('userEdit.language')}
								fullWidth
								disabled
								value={data.data.language}
								type={'text'}
							/>
							{/* role */}
							<FormControl fullWidth>
								<InputLabel id='select-role'>{t('usersForm.role')}</InputLabel>
								<Select
									disabled={data.data.owner}
									labelId='select-role'
									required
									label='role'
									value={
										data.data.owner
											? 'Manager'
											: userData.role
											? userData.role
											: 'Role'
									}
									onChange={(event) =>
										setUserData({ ...userData, role: event.target.value })
									}
								>
									<MenuItem hidden value={'Manager'}>
										Manager
									</MenuItem>
									<MenuItem value={'admin'}>Admin</MenuItem>
									<MenuItem value={'collaborator'}>Collaborator</MenuItem>
									<MenuItem value={'external'}>External</MenuItem>
								</Select>
							</FormControl>
							{/* active */}
							<ButtonMUI
								disabled={data.data.owner}
								fullWidth
								sx={{
									height: '57px',
									border: `solid 1px ${
										userData.active ? '#72d830' : '#ff3131'
									}`,
									color: userData.active ? '#72d830' : '#ff3131',
									'&:hover': {
										border: `solid 2px ${
											userData.active ? '#72d830' : '#ff3131'
										}`,
									},
								}}
								variant='outlined'
								onClick={() =>
									setUserData({
										...userData,
										active: !userData.active,
									})
								}
								startIcon={
									<FontAwesomeIcon
										icon={userData.active ? faUser : faUserSlash}
									/>
								}
							>
								{t('editUserPage.active')}
							</ButtonMUI>
						</div>
					</div>

					{/* save button */}
					<div className='d-flex justify-content-end mb-3 mt-2 me-3'>
						<ButtonMUI
							variant='contained'
							sx={{ mt: 2, width: '100px' }}
							onClick={() => setModal(true)}
						>
							{t('userEdit.save')}
						</ButtonMUI>
						{/* <Button
							style={{ color: 'white', backgroundColor: '#72d830' }}
							onClick={() => setModal(true)}
							className='mt-3 create-group-button d-flex justify-content-center align-items-center text-decoration-none trash-color'
						>
							{t('userEdit.save')}
						</Button> */}
					</div>

					{/* groups */}
					{groups &&
						groups.data.length !== 0 &&
						data.data.role !== 'external' && (
							<div className='dashboard-box mt-5 py-4 px-5'>
								<h2 className='type-title mt-2'>User groups</h2>
								<Table responsive bordered>
									<thead>
										<tr className='d-none d-md-table-row'>
											<th className='col-1 font-20'>
												<div className='unchecked-user' />
											</th>
											<th className='col-1 text-start font-20'>
												<span
													className='pointer-hover'
													onClick={() => sortBy('name')}
												>
													{t('userEdit.group')}
												</span>
											</th>
											<th className='col-1 text-start font-20'>
												<span
													className='pointer-hover'
													onClick={() => sortBy('users')}
												>
													{t('userEdit.users')}
												</span>
											</th>
											<th className='col-1 text-start font-20'>
												<span
													className='pointer-hover'
													onClick={() => sortBy('code')}
												>
													Code
												</span>
											</th>
										</tr>
									</thead>
									{groups && (
										<tbody>
											{groups.data.map((element, i) => (
												<tr
													key={`group-${i}`}
													className='table-row-color font-14 d-flex flex-column d-md-table-row'
												>
													{element.users.some(
														(check) => check.id === userId
													) ? (
														<td>
															<div className='d-flex justify-content-start align-content-center'>
																<div
																	className='d-flex justify-content-center align-items-center check-user'
																	onClick={() => {
																		removeUser({
																			groupId: element.id,
																			userId: userId,
																		});
																	}}
																>
																	<FontAwesomeIcon
																		className='text-white'
																		icon={faCheck}
																	/>
																</div>
															</div>
														</td>
													) : (
														<td>
															<div className='d-flex justify-content-start align-items-center '>
																<div
																	className='unchecked-user'
																	onClick={() => {
																		addUser({
																			groupId: element.id,
																			userId: userId,
																		});
																	}}
																/>
															</div>
														</td>
													)}
													<div className='d-md-none font-16 fw-bold pb-0'>
														{t('userEdit.group')}:
													</div>
													<td className='text-start'>{element.name}</td>
													<div className='d-md-none font-16 fw-bold pb-0'>
														{t('userEdit.users')}:
													</div>
													<td className='text-start'>{element.users.length}</td>
													<div className='d-md-none font-16 fw-bold pb-0'>
														Code:
													</div>
													<td className='text-start'>{element.code}</td>
												</tr>
											))}
										</tbody>
									)}
								</Table>
							</div>
						)}
				</div>
			)}
		</>
	);
}
