import {
	BackOfficeProject,
	LogEntriesProjectByTags,
	LogEntryId,
	LogEntryProject,
	SearchInSummary,
	SearchInTitleSummaryBody,
	StarredLogEntries,
	TagsByProject,
} from 'api/queries';
import React, { useEffect, useRef, useState } from 'react';
import { Modal, Table, Button, FormControl, Form } from 'react-bootstrap';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import {
	faStar,
	faTrash,
	faMagnifyingGlass,
	faChevronRight,
	faChevronLeft,
	faXmark,
	faSort,
	faTrashCan,
	faPenToSquare,
	faBell,
	faBellSlash,
	faEllipsisVertical,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	ChangeProjectStatus,
	LogEntryDelete,
	LogEntryStar,
	LogEntryUnstar,
	SearchProperties,
} from 'api/mutations';
import confirmAlert from 'libs/confirmAlert';
import '../../../css/privateLayout/private.css';
import Loader from 'app/components/Loader';
import { useTranslation } from 'react-i18next';
import { activeMenuState } from 'libs/atoms';
import { useRecoilState } from 'recoil';
import useOnClickOutside from 'hoc/onClickOutsude';
import Spinner from 'react-bootstrap/Spinner';
import useProjectQuery from '../../../hoc/useProjectQuery';
import ModalEditProject from '../../../hoc/ModalEditProject';
import ModalConfirm from '../../../hoc/ModalConfirm';
import { ENTERPRISE } from 'config';
import { ENTERPRISE_IMAGE_MOBILE } from 'config';
import moment from 'moment';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import DOMPurify from 'dompurify';
import {
	IconButton,
	InputAdornment,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Paper,
	TextField,
	Typography,
} from '@mui/material';
import { Button as ButtonMUI } from '@mui/material';
import { theme } from 'libs/theme';
import { useDialog } from 'hoc/useDialog';

export default function LogEntriesProject({ user }) {
	const { t } = useTranslation();

	const [activeMenu, setActiveMenu] = useRecoilState(activeMenuState);

	useEffect(() => {
		setActiveMenu('projects');
	}, []);

	const { projectIdParams, projectId } = useParams(); //prova

	const baseFilter = {
		page: 1,
		limit: 25,
		projectId: `$eq:${projectIdParams}`,
		sort: '-created_at',
		tag: `eq:`,
		logEntryId: '',
		key: null,
		value: null,
	};

	const [tag, setTag] = useState('All');

	const [inputVaule, setInputVaule] = useState('');

	const [pages, setPages] = useState([]);

	const [page, setPage] = useState(1);

	const [tagType, setTagType] = useState('data');

	const [limitPage, setLimitPage] = useState(25);

	const [show, setShow] = useState(false);

	const [deletePopup, setDeletePopup] = useState(false);

	const refModal = useRef();

	const refConfirmDelete = useRef();

	const history = useHistory();

	const [isSorted, setIsSorted] = useState(true);

	const [sortedByTime, setSortedByTime] = useState(true);

	const [spinner, setSpinner] = useState({ search: false, properties: false });

	const [showSearch, setShowSearch] = useState(false);

	const [propToAdd, setPropToAdd] = useState({ key: '', value: '' });

	const [properties, setProperties] = useState({});

	const [propertiesResult, setpropertiesResult] = useState(null);

	const [openDeleteProjectModal, setOpenDeleteProjectModal] = useState(false);

	const queryClient = useQueryClient();

	const editProjectModal = useDialog();

	const projectMenuRef = useRef();

	const [displayMenu, setDisplayMenu] = useState('none');

	const removeProperties = (key) => {
		const copy = { ...properties };
		delete copy[key];
		setProperties(copy);
	};

	// Get Project
	const { isLoading: loadingProject, data: project } = useQuery(
		['backofficeProject', user.currentAccount],
		() => BackOfficeProject(projectId)
	);

	const { deleteProject, ChangeStatus } = useProjectQuery(user);

	/* all */
	const {
		isLoading,
		data,
		refetch: refetchAll,
	} = useQuery(
		['log-entries-projectId', user.currentAccount],
		() => LogEntryProject(projectIdParams, baseFilter.page, baseFilter.limit),
		{
			onSuccess: (data) => {
				totPages(data.data.meta);
			},
		}
	);

	/* selected project tags*/
	const { data: tags } = useQuery(
		['tags-by-ProjectId', user.currentAccount],
		() => TagsByProject(projectIdParams)
	);

	/* starred */
	const {
		isLoading: loadingStarred,
		data: starred,
		refetch: refetchStarred,
	} = useQuery(
		['starred-entries', user.currentAccount],
		() =>
			StarredLogEntries(
				user.id,
				projectIdParams,
				baseFilter.page,
				baseFilter.limit
			),
		{
			enable: false,
			onSuccess: (data) => {
				totPages(data.data.meta);
			},
		}
	);

	/* selected tag */
	const {
		isLoading: loadingTag,
		data: filter,
		refetch: refetchWithTag,
	} = useQuery(
		['logEntries-ByTag', user.currentAccount],
		() =>
			LogEntriesProjectByTags(
				projectIdParams,
				baseFilter.tag,
				baseFilter.page,
				baseFilter.limit
			),
		{
			enable: false,
			onSuccess: (data) => {
				totPages(data.data.meta);
			},
		}
	);

	/* collapse query */
	const { data: collapseInfo, refetch: refetchCollapse } = useQuery(
		['collapseInfo', user.currentAccount],
		() => LogEntryId(baseFilter.logEntryId),
		{
			enable: false,
		}
	);

	/* search in summary */
	const { data: searchSum, refetch: refetchSum } = useQuery(
		['search-summary', user.currentAccount],
		() =>
			SearchInSummary(
				projectIdParams,
				inputVaule,
				baseFilter.page,
				baseFilter.limit
			),
		{
			enable: false,
			onSuccess: (data) => {
				totPages(data.data.meta);
			},
		}
	);

	const { data: searchBody, refetch: refetchSearch } = useQuery(
		['search-body', user.currentAccount],
		() =>
			inputVaule ? SearchInTitleSummaryBody(projectIdParams, inputVaule) : null,
		{
			enable: false,
			onSuccess: (data) => {
				if (data) {
					totPages(data.data.meta);
				}
			},
		}
	);

	const searchProp = useMutation(SearchProperties, {
		onSuccess: (data) => {
			totPages(data.data.meta);
		},
	});

	const changeTag = (tag) => {
		baseFilter.tag = `eq:${tag}`;
		baseFilter.page = 1;
		setTag(tag);
		setTagType('filter');
		setPage(1);
		refetchWithTag();
	};

	/* on click All */
	const allTags = () => {
		setTagType('data');
		setTag('All');
		setPage(1);
		refetchAll();
	};

	/* on click Starred */
	const starredEntries = () => {
		setTagType('starred');
		setTag('Starred');
		setPage(1);
		baseFilter.limit = limitPage;
		baseFilter.page = 1;
		refetchStarred();
	};

	/* delete logEntry mutation */
	const deleteLogEntryMutation = useMutation(LogEntryDelete, {
		onSuccess: () => {
			switch (tagType) {
				case 'data':
					queryClient.invalidateQueries([
						'log-entries-projectId',
						user.currentAccount,
					]);
					if (data.data.records.length > 1) {
						queryClient.invalidateQueries([
							'tags-by-ProjectId',
							user.currentAccount,
						]);
						break;
					} else {
						history.push('/projects');
					}
				case 'starred':
					queryClient.invalidateQueries([
						'log-entries-projectId',
						user.currentAccount,
					]);
					if (data.data.records.length > 1) {
						queryClient.invalidateQueries([
							'starred-entries',
							user.currentAccount,
						]);
						queryClient.invalidateQueries([
							'tags-by-ProjectId',
							user.currentAccount,
						]);
						break;
					} else {
						history.push('/projects');
					}
				case 'filter':
					queryClient.invalidateQueries([
						'log-entries-projectId',
						user.currentAccount,
					]);
					if (data.data.records.length > 1) {
						queryClient.invalidateQueries([
							'logEntries-ByTag',
							user.currentAccount,
						]);
						if (filter.data.records.length > 1) {
							break;
						} else {
							queryClient.invalidateQueries([
								'tags-by-ProjectId',
								user.currentAccount,
							]);
							setTagType('data');
							setTag('All');
							setPage(1);
							refetchAll();
						}
						break;
					} else {
						history.push('/projects');
					}
				case 'searchSum':
					queryClient.invalidateQueries([
						'log-entries-projectId',
						user.currentAccount,
					]);
					if (data.data.records.length > 1) {
						queryClient.invalidateQueries([
							'search-summary',
							user.currentAccount,
						]);
						queryClient.invalidateQueries([
							'tags-by-ProjectId',
							user.currentAccount,
						]);
						break;
					} else {
						history.push('/projects');
					}
				default:
					break;
			}
		},
	});

	/* onClick delete function */
	const deleteLogEntry = async (id) => {
		try {
			const response = await deleteLogEntryMutation.mutateAsync(id);
		} catch (error) {
			if (error.response && error.response.data) {
				confirmAlert.error(error.response.data);
				return;
			}
		}
	};

	/* star mutation */
	const logEntryStar = useMutation(LogEntryStar, {
		onSuccess: () => {
			queryClient.invalidateQueries(['collapseInfo', user.currentAccount]);
			queryClient.invalidateQueries(['starred-entries', user.currentAccount]);
		},
	});

	/* unstar mutation */
	const logEntryUnstar = useMutation(LogEntryUnstar, {
		onSuccess: () => {
			queryClient.invalidateQueries(['collapseInfo', user.currentAccount]);
			queryClient.invalidateQueries(['starred-entries', user.currentAccount]);
		},
	});

	/* onclick add/remove star function */
	const removeAddStar = async (data) => {
		if (data.starred.includes(user.id)) {
			try {
				const response = await logEntryUnstar.mutateAsync(data.id);
			} catch (error) {
				if (error.response && error.response.data) {
					confirmAlert.error(error.response.data);
					return;
				}
			}
		} else {
			try {
				const response = await logEntryStar.mutateAsync(data.id);
			} catch (error) {
				if (error.response && error.response.data) {
					confirmAlert.error(error.response.data);
					return;
				}
			}
		}
	};

	const searchInSummery2 = (event) => {
		setSpinner({ ...spinner, search: true });
		event.preventDefault();
		if (inputVaule === '') {
			setTagType('data');
			setTag('All');
			setPage(1);
			refetchAll();
		} else {
			setTag('');
			setTagType('searchBody');
			refetchSearch();
		}
		setSpinner({ ...spinner, search: false });
	};

	const searchProperties = async () => {
		setSpinner({ ...spinner, properties: true });
		setTag('');
		const result = await searchProp.mutateAsync({
			projectId: projectIdParams,
			page: baseFilter.page,
			limit: baseFilter.limit,
			data: properties,
		});
		setpropertiesResult(result);
		setShowSearch(false);
		setPropToAdd({ key: '', value: '' });
		setProperties({});
		setTagType('propertiesResult');
		setSpinner({ ...spinner, properties: false });
	};

	const totPages = (data) => {
		let pags = 0;
		if (data.count % data.limit === 0) {
			pags = Math.floor(data.count / data.limit);
		} else {
			pags = Math.floor(data.count / data.limit) + 1;
		}
		let arrPags = [];
		for (let i = 0; i < pags; i++) {
			arrPags.push(i);
		}
		setPages(arrPags);
	};

	const goPage = (nr) => {
		setPage(nr);
		baseFilter.page = nr;
		baseFilter.limit = limitPage;
		switch (tagType) {
			case 'data':
				refetchAll();
				break;
			case 'starred':
				refetchStarred();
				break;
			case 'filter':
				baseFilter.tag = `eq:${tag}`;
				refetchWithTag();
				break;
			case 'searchSum':
				refetchSum();
				break;
			default:
				break;
		}
	};

	const changeLimitPage = (nr) => {
		setLimitPage(nr);
		baseFilter.limit = nr;
		switch (tagType) {
			case 'data':
				refetchAll();
				break;
			case 'starred':
				refetchStarred();
				break;
			case 'filter':
				baseFilter.tag = `eq:${tag}`;
				refetchWithTag();
				break;
			case 'searchSum':
				refetchSum();
				break;
			default:
				break;
		}
	};

	const selectedtag = (data) => {
		if (data === tag) {
			return 'selected-tag';
		}
		return;
	};

	function timeSince(createdAt) {
		return moment(createdAt).format('YYYY-MM-DD HH:MM');
	}

	const showModal = (id) => {
		baseFilter.logEntryId = id;
		refetchCollapse();
		setShow(true);
	};

	const sortBy = () => {
		if (isSorted) {
			eval(tagType).data.records.sort((a, b) => {
				const titleA = a.title.toUpperCase();
				const titleB = b.title.toUpperCase();
				if (titleA < titleB) {
					return -1;
				}
				if (titleA > titleB) {
					return 1;
				}
				return 0;
			});
		} else {
			eval(tagType).data.records.sort((a, b) => {
				const titleA = a.title.toUpperCase();
				const titleB = b.title.toUpperCase();
				if (titleA > titleB) {
					return -1;
				}
				if (titleA < titleB) {
					return 1;
				}
				return 0;
			});
		}
		setIsSorted(!isSorted);
	};

	const sortByTime = () => {
		if (sortedByTime) {
			eval(tagType).data.records.sort((a, b) => {
				return new Date(a.createdAt) - new Date(b.createdAt);
			});
		} else {
			eval(tagType).data.records.sort((a, b) => {
				return new Date(b.createdAt) - new Date(a.createdAt);
			});
		}
		setSortedByTime(!sortedByTime);
	};

	useOnClickOutside(refModal, () => {
		setShow(false);
	});

	useOnClickOutside(refConfirmDelete, () => {
		setDeletePopup(false);
		setShow(true);
	});

	useOnClickOutside(projectMenuRef, () => {
		setDisplayMenu('none');
	});

	if (isLoading || loadingStarred || loadingTag || loadingProject) {
		return <Loader />;
	}

	if (isLoading || loadingStarred || loadingTag) {
		return <Loader />;
	}

	return (
		<div className='max-width m-0-auto'>
			{/* display details */}
			<Modal show={show} centered size='lg'>
				{collapseInfo && (
					<div className='popup-dettagli p-4 rounded-2' ref={refModal}>
						<div className='d-flex justify-content-between mb-2'>
							<div className='text-blue font-16'>
								{timeSince(collapseInfo.data.createdAt)}
							</div>
							<FontAwesomeIcon
								onClick={() => setShow(false)}
								icon={faXmark}
								className='trash-color pointer-hover'
							/>
						</div>
						{/* title */}
						<div className='d-flex align-items-center mb-2 mt-4'>
							<div className='icon-modal d-flex justify-content-center align-items-center fs-3 me-3'>
								{collapseInfo.data.icon ? (
									collapseInfo.data.icon
								) : (
									<img src='/images/everylog-16.png' />
								)}
							</div>
							<div className='fw-bold font-18'>{collapseInfo.data.title}</div>
						</div>
						<div className='w-100-perc separate-modal' />
						{/* summary */}
						<div className='font-15 fw-bold subtitle-modal mt-3 text-uppercase'>
							{t('projectsPage.summary')}
						</div>
						<div
							className='font-15 mt-3 font-18'
							style={{ whiteSpace: 'pre-line' }}
						>
							{collapseInfo.data.summary}
						</div>
						<div className='w-100-perc separate-modal' />
						{/* body */}
						<div className='font-15 fw-bold subtitle-modal mt-3 text-uppercase'>
							{t('notifyPage.body')}
						</div>
						<div
							className='font-15 mt-3 font-18'
							style={{ whiteSpace: 'pre-line' }}
							dangerouslySetInnerHTML={{
								__html: DOMPurify.sanitize(collapseInfo.data.body, {
									ALLOWED_TAGS: [
										'h1',
										'h2',
										'h3',
										'h4',
										'h5',
										'h6',
										'p',
										'b',
										'br',
										'i',
										'u',
										'a',
										'img',
										'ul',
										'li',
										'ol',
									],
									ALLOWED_ATTR: ['style', 'src', 'href'],
								}),
							}}
						/>
						<div className='w-100-perc separate-modal' />
						{/* properties */}
						<div className='font-15 fw-bold subtitle-modal mt-3 text-uppercase'>
							{t('projectsPage.properties')}
						</div>
						{collapseInfo.data.properties && (
							<div>
								{Object.keys(collapseInfo.data.properties).map((key, i) => (
									<div
										key={i}
										className='d-flex flex-column gap-1 flex-md-row mb-3'
									>
										<div className='properties-item-modal p-1 mb-1'>{key}</div>
										<div className='properties-item-modal p-1 mb-1'>
											{collapseInfo.data.properties[key]}
										</div>
									</div>
								))}
							</div>
						)}
						<div className='w-100-perc separate-modal' />
						{/* tags */}
						<div className='font-15 fw-bold subtitle-modal mt-3'>TAGS</div>
						{collapseInfo.data.tags && (
							<div className='tags-grid mt-4'>
								{collapseInfo.data.tags.map((tag, i) => (
									<div key={i} className='tag-inpopup me-2 font-14'>
										{tag}
									</div>
								))}
							</div>
						)}
						<div className='w-100-perc separate-modal' />
						{collapseInfo.data.starred && (
							<div className='d-flex justify-content-between mt-4'>
								<FontAwesomeIcon
									icon={faTrash}
									onClick={() => {
										setDeletePopup(true);
										setShow(false);
									}}
									title={t('projectsPage.delete')}
									className='pointer-hover trash-color'
								/>
								{collapseInfo.data.starred.some(
									(findUser) => findUser === user.id
								) ? (
									<FontAwesomeIcon
										className='text-blue pointer-hover'
										icon={faStar}
										onClick={() => removeAddStar(collapseInfo.data)}
										title={t('projectsPage.unstar')}
									/>
								) : (
									<FontAwesomeIcon
										className='trash-color pointer-hover'
										icon={faStar}
										onClick={() => removeAddStar(collapseInfo.data)}
										title={t('projectsPage.star')}
									/>
								)}
							</div>
						)}
					</div>
				)}
			</Modal>
			{/* confirm delete logentry */}
			<ModalConfirm
				message={`${t('projectsPage.modalDelete')}?`}
				show={deletePopup}
				setShow={setDeletePopup}
				onConfirm={() => {
					setDeletePopup(false);
					setShow(false);
					deleteLogEntry(collapseInfo.data.id);
				}}
			/>
			{/* search by properties */}
			<Modal
				show={showSearch}
				centered
				onHide={() => {
					setShowSearch(false);
					setPropToAdd({ key: '', value: '' });
					setProperties({});
				}}
			>
				<Modal.Header closeButton>
					<h3>{t('projectsPage.searchByProperties')}</h3>
				</Modal.Header>
				<Modal.Body>
					<Form
						onSubmit={(e) => {
							e.preventDefault();
							setProperties({
								...properties,
								[propToAdd.key]: propToAdd.value,
							});
							setPropToAdd({ key: '', value: '' });
						}}
						className='d-flex flex-column flex-sm-row gap-2 pt-1 align-items-center '
					>
						{/* <div className='d-flex flex-column w-100-perc'>
							<div className='fw-bold'>{t('channelPage.key')}</div>
							<FormControl
								required
								value={propToAdd.key}
								onChange={(e) =>
									setPropToAdd({ ...propToAdd, key: e.target.value })
								}
							/>
						</div> */}
						<TextField
							label={t('channelPage.key')}
							required
							fullWidth
							value={propToAdd.key}
							onChange={(e) =>
								setPropToAdd({ ...propToAdd, key: e.target.value })
							}
						/>
						{/* <div className='d-flex flex-column w-100-perc'>
							<div className='w-100 fw-bold'>{t('channelPage.value')}</div>
							<FormControl
								required
								value={propToAdd.value}
								onChange={(e) =>
									setPropToAdd({ ...propToAdd, value: e.target.value })
								}
							/>
						</div> */}
						<TextField
							label={t('channelPage.value')}
							fullWidth
							required
							value={propToAdd.value}
							onChange={(e) =>
								setPropToAdd({ ...propToAdd, value: e.target.value })
							}
						/>
						<ButtonMUI type='submit' variant='contained' color='secondary'>
							{t('channelPage.add')}
						</ButtonMUI>
					</Form>
					<div className='mt-4'>
						{Object.keys(properties).map((key, i) => (
							<div key={i} className='d-flex flex-column flex-sm-row  mt-3'>
								<div className='w-400 overflow-auto fw-bold'>{key}</div>
								<div className='d-flex align-items-center justify-content-between pe-4 gap-3 w-100-perc'>
									<div>{properties[key]}</div>
									<FontAwesomeIcon
										className='text-danger'
										icon={faXmark}
										onClick={() => removeProperties(key)}
									/>
								</div>
							</div>
						))}
					</div>
				</Modal.Body>
				<Modal.Footer className='d-flex justify-content-between'>
					<ButtonMUI
						variant='contained'
						color='error'
						onClick={() => {
							setShowSearch(false);
							setPropToAdd({ key: '', value: '' });
							setProperties({});
						}}
					>
						{t('createUsersPage.cancel')}
					</ButtonMUI>
					<ButtonMUI
						sx={{ width: '100px' }}
						variant='contained'
						onClick={() => searchProperties()}
					>
						{t('groupPage.search')}
					</ButtonMUI>
				</Modal.Footer>
			</Modal>
			{/* confirm delete Project Modal */}
			<ModalConfirm
				message={t('projects.confirmDeleteProject')}
				show={openDeleteProjectModal}
				setShow={setOpenDeleteProjectModal}
				onConfirm={() => deleteProject(project.data.id)}
			/>
			{project.data.id == projectId && (
				<ModalEditProject
					show={editProjectModal.open}
					setShow={editProjectModal.handleClose}
					user={user}
					projectID={projectId}
					projectData={project.data}
				/>
			)}
			<div className='d-flex flex-column flex-md-row justify-content-between'>
				<div>
					<h1
						className='title-font mb-0'
						style={{ color: `${project.data.color}` }}
					>
						{project.data.name ? project.data.name : projectIdParams}
					</h1>
					<h5 className='text-secondary'>ProjectId: {projectIdParams}</h5>
					<Typography className='text-secondary' fontSize={16}>
						{t('projects.description')}: {project.data.description}
					</Typography>
				</div>
				{/* <div
					className='dashboard-box m-0  overflow-auto '
					style={{ maxWidth: '400px', height: '100px' }}
				>
					{project.data.description}
				</div> */}
			</div>
			<div className='dashboard-box mt-3 py-4 px-5'>
				{eval(tagType) && (
					<>
						<div className='d-flex justify-content-end'>
							<div className='d-flex mb-3 gap-2'>
								{user.role != 'external' && (
									<Stack ref={projectMenuRef}>
										<IconButton
											onClick={() => {
												if (displayMenu === 'none') {
													setDisplayMenu('block');
												} else {
													setDisplayMenu('none');
												}
											}}
											sx={{
												width: '40px',
												border: `solid 1px ${theme.palette.primary.main}`,
												position: 'relative',
											}}
										>
											<FontAwesomeIcon icon={faEllipsisVertical} />
										</IconButton>
										<Paper
											sx={{
												display: `${displayMenu}`,
												position: 'absolute',
												top: '69px',
												right: '68px',
												backgroundColor: 'white',
												zIndex: 200,
											}}
											ref={projectMenuRef}
										>
											<MenuItem
												onClick={() => {
													ChangeStatus(project.data.id);
												}}
												sx={{
													color: project.data.status
														? theme.palette.primary.main
														: theme.palette.error.main,
												}}
											>
												<ListItemIcon
													sx={{
														color: project.data.status
															? theme.palette.primary.main
															: theme.palette.error.main,
													}}
												>
													<FontAwesomeIcon
														icon={project.data.status ? faBell : faBellSlash}
													/>
												</ListItemIcon>
												<ListItemText>
													{t('projects.projectActive')}
												</ListItemText>
											</MenuItem>
											<MenuItem
												onClick={() => {
													editProjectModal.handleOpen();
													setDisplayMenu('none');
												}}
												sx={{ color: theme.palette.secondary.main }}
											>
												<ListItemIcon
													sx={{ color: theme.palette.secondary.main }}
												>
													<FontAwesomeIcon icon={faPenToSquare} />
												</ListItemIcon>
												<ListItemText>{t('projects.editProject')}</ListItemText>
											</MenuItem>
											<MenuItem
												onClick={() => {
													setOpenDeleteProjectModal(true);
													setDisplayMenu('none');
												}}
												sx={{ color: theme.palette.error.main }}
											>
												<ListItemIcon sx={{ color: theme.palette.error.main }}>
													<FontAwesomeIcon icon={faTrashCan} />
												</ListItemIcon>
												<ListItemText>Delete Project</ListItemText>
											</MenuItem>
										</Paper>
									</Stack>
								)}
							</div>
						</div>
						<div className='d-flex gap-2 flex-wrap'>
							<div
								className={`tag-item ${selectedtag('All')} font-14`}
								onClick={() => allTags()}
							>
								{t('projectsPage.all')}
							</div>
							<div
								className={`tag-item ${selectedtag('Starred')} font-14`}
								onClick={() => starredEntries()}
							>
								{t('projectsPage.starred')}
							</div>
							{tags &&
								tags.data.map((element, i) => (
									<div
										className={` tag-item ${selectedtag(element)} font-14`}
										key={`tag-${i}`}
										onClick={() => changeTag(element)}
									>
										{element}{' '}
									</div>
								))}
						</div>
						{/* Search */}
						<div className='d-flex flex-column flex-md-row align-items-end gap-3 w-100-perc  my-5'>
							{/* search in summary */}
							<div className='w-100-perc'>
								<div className='fs-5 fw-bold'>
									{t('projectsPage.searchInSummary')}
								</div>
								<form
									onSubmit={(event) => searchInSummery2(event)}
									className='mt-3 position-relative w-100-perc'
								>
									{/* <input
                    className="tag-search-input font-12"
                    placeholder={t("projectsPage.searchInSummary")}
                    onChange={(event) => setInputVaule(event.target.value)}
                  />
                  <FontAwesomeIcon
                    className="search-button position-absolute"
                    icon={faMagnifyingGlass}
                  />
                  <Spinner
                    className="search-spinner position-absolute"
                    size="sm"
                    as="span"
                    animation={spinner.search ? "border" : "none"}
                  /> */}
									<TextField
										fullWidth
										// label={t('projectsPage.searchInSummary')}
										onChange={(event) => setInputVaule(event.target.value)}
										InputProps={{
											startAdornment: (
												<InputAdornment position='start'>
													<FontAwesomeIcon icon={faMagnifyingGlass} />
												</InputAdornment>
											),
										}}
									/>
								</form>
							</div>
							{/* search by properties */}
							<div className='w-100-perc'>
								<div className='fs-5 fw-bold'>
									{t('projectsPage.searchByProperties')}
								</div>
								<ButtonMUI
									variant='outlined'
									color='secondary'
									onClick={() => setShowSearch(true)}
									startIcon={
										<FontAwesomeIcon
											className='me-2'
											icon={faMagnifyingGlass}
										/>
									}
									sx={{ mt: '1rem', height: '56px', width: '200px' }}
								>
									{t('groupPage.search')}
								</ButtonMUI>
							</div>
						</div>
						<div>
							<Table responsive className='mt-2' bordered>
								<thead>
									<tr className='d-none d-md-table-row'>
										<th className='col-0 '></th>
										<th className='col-2 text-start font-20'>
											{t('projectsPage.projectTitle')}
											<FontAwesomeIcon
												className='ms-1 fs-5 trash-color pointer-hover'
												icon={faSort}
												onClick={() => sortBy()}
											/>
										</th>
										<th className='col-4 text-start font-20'>
											{t('projectsPage.summary')}{' '}
										</th>
										<th className='col-2 text-start font-20'>
											{t('projectsPage.tags')}
										</th>
										<th
											className='col-2 text-start font-20'
											onClick={() => sortByTime()}
										>
											{t('projectsPage.time')}
											<FontAwesomeIcon
												className='ms-1 fs-5 trash-color pointer-hover'
												icon={faSort}
												onClick={() => sortByTime()}
											/>
										</th>
										<th className='col-2 text-start font-20'>
											{t('projectsPage.actions')}
										</th>
									</tr>
								</thead>
								<tbody>
									{eval(tagType).data.records.map((logEntry, i) => (
										<tr
											key={`logEntry-${i}`}
											className='table-row-color font-14 d-flex flex-column d-md-table-row'
										>
											<td>
												{logEntry.icon && logEntry.icon.length !== 0 ? (
													<div className='icon-project d-flex align-items-center justify-content-center'>
														{logEntry.icon}
													</div>
												) : (
													<div className='icon-project d-flex align-items-center justify-content-center'>
														<img
															width={'40px'}
															src={`${
																ENTERPRISE
																	? ENTERPRISE_IMAGE_MOBILE
																	: '/images/everylog-16.png'
															}`}
														/>
													</div>
												)}
											</td>
											<div className='d-md-none font-16 fw-bold pb-0'>
												{t('projectsPage.projectTitle')}:
											</div>
											<td className='text-start vertical-align border-0'>
												{logEntry.title}
											</td>
											<div className='d-md-none font-16 fw-bold pb-0'>
												{t('projectsPage.summary')}:
											</div>
											<td className='vertical-align border-0'>
												{logEntry.summary}
											</td>
											<div className='d-md-none font-16 fw-bold pb-0'>
												{t('projectsPage.tags')}:
											</div>
											<td className='text-start vertical-align border-0'>
												{logEntry.tags.length >= 2 ? (
													<span className='vertical-align border-0'>{`${
														logEntry.tags[0]
													}, +${logEntry.tags.length - 1}`}</span>
												) : (
													<span className='vertical-align border-0'>
														{logEntry.tags[0]}
													</span>
												)}
											</td>
											<div className='d-md-none font-16 fw-bold pb-0'>
												{t('projectsPage.time')}:
											</div>
											<td className='text-start vertical-align border-0'>
												{timeSince(logEntry.createdAt)}
											</td>
											<div className='d-md-none font-16 fw-bold pb-0'>
												{t('projectsPage.actions')}:
											</div>
											<td className='border-0'>
												<ButtonMUI
													variant='contained'
													color='secondary'
													onClick={() => showModal(logEntry.id)}
												>
													{t('projectsPage.display')}
												</ButtonMUI>
												{/* <div
													onClick={() => showModal(logEntry.id)}
													className='pointer-hover details'
												>
													{t('projectsPage.display')}
												</div> */}
											</td>
										</tr>
									))}
								</tbody>
							</Table>
							<div className='d-flex justify-content-between'>
								<select
									className='select-limit-page'
									onChange={(event) => changeLimitPage(event.target.value)}
								>
									<option value={25}>25</option>
									<option value={50}>50</option>
									<option value={100}>100</option>
								</select>
								<div className='d-flex gap-2 align-items-center'>
									<Stack>
										<Pagination
											color='secondary'
											shape='rounded'
											count={pages.length}
											size='large'
											onChange={(e, value) => {
												goPage(value);
											}}
										/>
									</Stack>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
}
