import AuthLayout from 'app/components/layout/AuthLayout';
import PrivateLayout from 'app/components/layout/PrivateLayout';
import ActivateAccountPage from 'app/pages/auth/ActivateAccountPage';
import ForgotPasswordPage from 'app/pages/auth/ForgotPasswordPage';
import LoginPage from 'app/pages/auth/LoginPage';
import RegisterPage from 'app/pages/auth/RegisterPage';
import ResendActivationPage from 'app/pages/auth/ResendActivationPage';
import ResetPasswordPage from 'app/pages/auth/ResetPasswordPage';
import PlanPage from 'app/pages/plan/PlanPage';
import SubscribePlanPage from 'app/pages/plan/SubscribePlanPage';
import AddCardPage from 'app/pages/user/AddCardPage';
import EditAccountPage from 'app/pages/user/EditAccountPage';
import EditUserPage from 'app/pages/user/EditUserPage';
import IndexUsersPage from 'app/pages/users/IndexUsersPage';
import withCurrentUser from 'hoc/withCurrentUser';
import { Switch } from 'react-router-dom';
import OnlyPublicRoute from 'routes/OnlyPublicRoute';
import PrivateActiveRoute from 'routes/PrivateActiveRoute';
import PrivateRoute from 'routes/PrivateRoute';
import ProjectsPage from 'app/pages/projects/Projects';
import LogEntriesProject from 'app/pages/projects/LogEntriesProject';
import GroupPage from 'app/pages/groups/GroupPage';
import GroupsPage from 'app/pages/groups/GroupsPage';
import DashboardPage from 'app/pages/dashboard/DashboardPage';
import EditUser from 'app/pages/users/EditUser';
import NewLogEntryPage from 'app/pages/projects/NewLogEntryPage';
import ChannelsPage from 'app/pages/externalChannels/ChannelsPage';
import ChannelPage from 'app/pages/externalChannels/ChannelPage';
import DashboardUser from 'app/pages/dashboard/DashboardUser';
import IndexInvitationsPage from 'app/pages/invitations/IndexInvitationsPage';
import RegisterWithInvitationPage from 'app/pages/auth/RegisterWithInvitationPage';

const Private = withCurrentUser(PrivateRoute);
const PrivateActive = withCurrentUser(PrivateActiveRoute);
const OnlyPublic = withCurrentUser(OnlyPublicRoute);

const ApplicationRouter = () => {
	return (
		<Switch>
			<OnlyPublic exact path='/' component={LoginPage} layout={AuthLayout} />
			<OnlyPublic
				exact
				path='/auth/login'
				component={LoginPage}
				layout={AuthLayout}
			/>
			<OnlyPublic
				exact
				path='/auth/forgot-password'
				component={ForgotPasswordPage}
				layout={AuthLayout}
			/>
			<OnlyPublic
				exact
				path='/auth/resend-activation'
				component={ResendActivationPage}
				layout={AuthLayout}
			/>
			<OnlyPublic
				exact
				path='/auth/reset-password/:email'
				component={ResetPasswordPage}
				layout={AuthLayout}
			/>
			<OnlyPublic
				exact
				path='/auth/activate/:email'
				component={ActivateAccountPage}
				layout={AuthLayout}
			/>
			<OnlyPublic
				exact
				path='/auth/register'
				component={RegisterPage}
				layout={AuthLayout}
			/>
			<OnlyPublic
				exact
				path='/auth/register-with-invitation'
				component={RegisterWithInvitationPage}
				layout={AuthLayout}
			/>
			<PrivateActive
				exact
				path='/dashboard'
				layout={PrivateLayout}
				allowedRoles={['admin', 'collaborator', 'external']}
				component={DashboardUser}
			/>
			<Private
				exact
				path='/plan/:planId/subscribe'
				layout={PrivateLayout}
				allowedRoles={['admin']}
				component={SubscribePlanPage}
			/>
			<Private
				exact
				path='/plan'
				layout={PrivateLayout}
				allowedRoles={['admin']}
				component={PlanPage}
				planType={['unlimited', 'premium', 'basic', 'starter']}
			/>
			<PrivateActive
				exact
				path='/card/add'
				layout={PrivateLayout}
				allowedRoles={['admin']}
				component={AddCardPage}
			/>
			<Private
				exact
				path='/user/edit'
				layout={PrivateLayout}
				allowedRoles={['admin', 'collaborator', 'external']}
				component={EditUserPage}
			/>
			<Private
				exact
				path='/account/edit'
				layout={PrivateLayout}
				allowedRoles={['admin']}
				planType={['unlimited', 'premium', 'basic', 'starter']}
				component={EditAccountPage}
			/>
			<Private
				exact
				path='/user/:userId'
				layout={PrivateLayout}
				allowedRoles={['admin', 'collaborator']}
				planType={['unlimited', 'enterprise', 'premium', 'basic']}
				component={EditUser}
			/>
			<PrivateActive
				exact
				path='/users'
				layout={PrivateLayout}
				allowedRoles={['admin', 'collaborator']}
				planType={['unlimited', 'enterprise', 'premium', 'basic']}
				component={IndexUsersPage}
			/>
			<PrivateActive
				exact
				path='/projects'
				layout={PrivateLayout}
				allowedRoles={['admin', 'collaborator', 'external']}
				component={ProjectsPage}
			/>
			<PrivateActive
				exact
				path='/project/logentries/:projectIdParams/:projectId'
				layout={PrivateLayout}
				allowedRoles={['admin', 'collaborator', 'external']}
				component={LogEntriesProject}
			/>
			<PrivateActive
				exact
				path='/groups'
				layout={PrivateLayout}
				allowedRoles={['admin', 'collaborator']}
				// planType={["unlimited","enterprise", "premium", "basic"]}
				component={GroupsPage}
			/>
			<PrivateActive
				exact
				path='/groups/:groupid'
				layout={PrivateLayout}
				allowedRoles={['admin', 'collaborator']}
				planType={['unlimited', 'enterprise', 'premium', 'basic']}
				component={GroupPage}
			/>
			<PrivateActive
				exact
				path='/subscription'
				planType={['unlimited', 'starter', 'premium', 'basic']}
				layout={PrivateLayout}
				allowedRoles={['admin']}
				component={DashboardPage}
			/>
			<PrivateActive
				exact
				path='/send-notification'
				layout={PrivateLayout}
				allowedRoles={['admin', 'collaborator']}
				component={NewLogEntryPage}
			/>
			<PrivateActive
				exact
				path='/channels'
				layout={PrivateLayout}
				allowedRoles={['admin', 'collaborator']}
				planType={['unlimited', 'enterprise', 'premium']}
				component={ChannelsPage}
			/>
			<PrivateActive
				exact
				path='/channels/:channelId'
				layout={PrivateLayout}
				allowedRoles={['admin', 'collaborator']}
				planType={['unlimited', 'enterprise', 'premium']}
				component={ChannelPage}
			/>
			<PrivateActive
				exact
				path='/invitations'
				layout={PrivateLayout}
				allowedRoles={['admin']}
				planType={['unlimited', 'basic', 'enterprise', 'premium']}
				component={IndexInvitationsPage}
			/>

			{/* TODO: 404 component */}
			<Private
				path='*'
				layout={PrivateLayout}
				allowedRoles={['admin', 'collaborator', 'external']}
				component={DashboardUser}
			/>
		</Switch>
	);
};

export default ApplicationRouter;
