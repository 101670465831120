import React, { useEffect, useState } from 'react';
import { activeMenuState } from 'libs/atoms';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Badge, Button, Form, Modal, Table } from 'react-bootstrap';
import { faCircleQuestion, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Invitations } from 'api/queries';
import {
	CreateInvitation,
	DeleteInvitaton,
	SendInvitation,
} from 'api/mutations';
import confirmAlert from 'libs/confirmAlert';
import Loader from 'app/components/Loader';
import { Steps } from 'intro.js-react';
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Button as ButtonMUI,
} from '@mui/material';

export default function IndexInvitationsPage({ user }) {
	const [activeMenu, setActiveMenu] = useRecoilState(activeMenuState);
	useEffect(() => {
		setActiveMenu('invitations');
	}, []);
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const [createInvitationModal, setCreateInvitationModal] = useState(false);
	const [newInvitation, setNewinvitation] = useState({
		userEmail: null,
		role: 'admin',
		projects: [],
	});
	const [confirmDeleteInvitation, setConfirmDeleteInvitation] = useState({
		modal: false,
		id: null,
	});
	const [help, setHelp] = useState(false);
	const [projectInput, setProjectInput] = useState('');

	// get all Invitations
	const { isLoading, data } = useQuery(
		['Invitations', user.currentAccount],
		Invitations,
		{
			retry: false,
			onSuccess: (data) => {
				console.log('data: ', data);
			},
		}
	);

	// Delete Invitation
	const deleteInvitationMutation = useMutation(DeleteInvitaton, {
		onSuccess: () => {
			queryClient.invalidateQueries(['Invitations', user.currentAccount]);
		},
	});

	const deleteInvitation = async (id) => {
		try {
			const response = await deleteInvitationMutation.mutateAsync(id);
			if (response) {
				confirmAlert.success(t('invitationsPage.invitationDeleted'));
			}
		} catch (error) {
			if (error.response && error.response.data) {
				confirmAlert.error(error.response.data);
				return;
			}
		}
	};

	// Create Invitation
	const createInvitationMutation = useMutation(CreateInvitation, {
		onSuccess: () => {
			queryClient.invalidateQueries(['Invitations', user.currentAccount]);
		},
	});

	const createInvitation = async (data) => {
		try {
			const response = await createInvitationMutation.mutateAsync(data);
			if (response) {
				confirmAlert.success(t('invitationsPage.invitationCreated'));
			}
		} catch (error) {
			if (error.response && error.response.data) {
				confirmAlert.error(error.response.data);
				return;
			}
		}
	};

	// Send Invitation
	const sendInvitationMutation = useMutation(SendInvitation, {
		onSuccess: (resp) => {
			queryClient.invalidateQueries(['Invitations', user.currentAccount]);
		},
	});

	const sendInvitation = async (id) => {
		try {
			const response = await sendInvitationMutation.mutateAsync(id);
			if (response) {
				confirmAlert.success(t('invitationsPage.invitationSent'));
			}
		} catch (error) {
			if (error.response && error.response.data) {
				confirmAlert.error(error.response.data);
				return;
			}
		}
	};

	const getDate = (data) => {
		const stringData = new Date(data).toDateString();
		if (stringData === 'Mon Jan 01 0001') {
			return '';
		}
		return stringData;
	};

	const options = {
		nextLabel: t('guideButtons.next'),
		prevLabel: t('guideButtons.prev'),
		doneLabel: t('guideButtons.done'),
	};

	let stepsUsers = [
		{
			element: '#create-invitation',
			intro: (
				<div>
					<h5 style={{ fontSize: '20px', fontWeight: '700' }}>
						{t('invitationGuide.firstStepTitle')}
					</h5>
					<div className='pb-0'>{t('invitationGuide.firstStep')}</div>
				</div>
			),
		},
		{
			element: '#send-invitation-0',
			intro: (
				<div>
					<h5 style={{ fontSize: '20px', fontWeight: '700' }}>
						{t('invitationGuide.secondStepTitle')}
					</h5>
					<div className='pb-0'>{t('invitationGuide.secondStep')}</div>
				</div>
			),
		},
		{
			element: '#delete-invitation-0',
			intro: (
				<div>
					<h5 style={{ fontSize: '20px', fontWeight: '700' }}>
						{t('invitationGuide.thirdStepTitle')}
					</h5>
					<div className='pb-0'>{t('invitationGuide.thirdStep')}</div>
				</div>
			),
		},
	];

	const onExit = () => {
		setHelp(false);
	};

	if (isLoading) {
		return <Loader />;
	}

	return (
		<div className='m-0-auto max-width'>
			<Steps
				enabled={help}
				steps={stepsUsers}
				onExit={onExit}
				initialStep={0}
				options={options}
			/>
			<Modal
				show={createInvitationModal}
				onHide={() => {
					setCreateInvitationModal(false);
					setNewinvitation({ role: 'admin', userEmail: '', projects: [] });
				}}
				centered
			>
				<Form
					onReset={(e) => {
						e.preventDefault();
						setNewinvitation({ role: 'admin', userEmail: '', projects: [] });
						setCreateInvitationModal(false);
					}}
					onSubmit={(e) => {
						e.preventDefault();
						createInvitation(newInvitation);
						setNewinvitation({ role: 'admin', userEmail: '', projects: [] });
						setCreateInvitationModal(false);
					}}
				>
					<div className='popup-crea-gruppo p-4'>
						<TextField
							onChange={(event) => {
								setNewinvitation({
									...newInvitation,
									userEmail: event.target.value,
								});
							}}
							label='Email'
							variant='outlined'
							type={'email'}
							value={newInvitation.userEmail}
							required
						/>
						<FormControl fullWidth>
							<InputLabel id='select-role'>{t('usersForm.role')}</InputLabel>
							<Select
								labelId='select-role'
								required
								label='role'
								onChange={(event) => {
									setNewinvitation({
										...newInvitation,
										role: event.target.value,
									});
								}}
								value={newInvitation.role}
							>
								<MenuItem value={'admin'}>Admin</MenuItem>
								<MenuItem value={'collaborator'}>Collaborator</MenuItem>
								<MenuItem value={'external'}>External</MenuItem>
							</Select>
						</FormControl>

						<div className='d-flex justify-content-center gap-5'>
							<ButtonMUI type='reset' variant='contained' color='error'>
								{t('createUsersPage.cancel')}
							</ButtonMUI>
							{/* <Button
								type='reset'
								className='group-cancel-button d-flex justify-content-center align-items-center'
							>
								{t('createUsersPage.cancel')}
							</Button> */}
							<ButtonMUI
								type='submit'
								variant='contained'
								sx={{ width: '100px' }}
							>
								{t('createUsersPage.save')}
							</ButtonMUI>
							{/* <Button
								type='submit'
								className='create-group-button d-flex justify-content-center align-items-center'
							>
								{t('createUsersPage.save')}
							</Button> */}
						</div>
					</div>
				</Form>
			</Modal>
			<Modal
				show={confirmDeleteInvitation.modal}
				centered
				onHide={() => setConfirmDeleteInvitation({ modal: false, id: null })}
			>
				<div className='delete-popup font-18'>
					<div className='text-center m-3'>
						{t('invitationsPage.deleteInvitation')}?
					</div>
					<div className='d-flex justify-content-center gap-5 mb-3'>
						<div
							onClick={() => {
								deleteInvitation(confirmDeleteInvitation.id);
								setConfirmDeleteInvitation({ modal: false, id: null });
							}}
							className='group-cancel-button d-flex justify-content-center align-items-center'
						>
							{t('groupsPage.yes')}
						</div>
						<div
							onClick={() =>
								setConfirmDeleteInvitation({ modal: false, id: null })
							}
							className='group-cancel-button d-flex justify-content-center align-items-center'
						>
							{t('groupsPage.no')}
						</div>
					</div>
				</div>
			</Modal>
			<div className='d-flex gap-2 '>
				<h1 className='title-font'>{t('invitationsPage.invitations')}</h1>
				<FontAwesomeIcon
					icon={faCircleQuestion}
					className='text-blue pointer-hover fs-5'
					onClick={() => {
						setHelp(true);
					}}
				/>
			</div>
			<div className='dashboard-box mt-5 py-4 px-5'>
				<div className='d-flex justify-content-between align-items-center mb-4'>
					<div>
						{data && data.data.length === 0 && (
							<h2 className='font-20 fw-bold'>
								0 {t('invitationsPage.invitations')}
							</h2>
						)}
					</div>
					<ButtonMUI
						onClick={() => setCreateInvitationModal(true)}
						id='create-invitation'
						variant='contained'
					>
						{t('invitationsPage.createInvitation')}
					</ButtonMUI>
					{/* <div
						id='create-invitation'
						style={{ color: 'white', backgroundColor: '#72d830' }}
						className='mt-3 create-group-button d-flex justify-content-center align-items-center table-row-color'
						onClick={() => setCreateInvitationModal(true)}
					>
						{t('invitationsPage.createInvitation')}
					</div> */}
				</div>
				{data.data.length !== 0 && (
					<Table responsive bordered>
						<thead>
							<tr className='d-none d-md-table-row'>
								<th className='col-1 text-start font-20'>Email</th>
								<th className='col-1 text-start font-20'>
									{t('invitationsPage.role')}
								</th>
								<th className='col-1 text-start font-20'>
									{t('invitationsPage.sent')}
								</th>
								<th className='col-1 text-start font-20'>
									{t('invitationsPage.actions')}
								</th>
							</tr>
						</thead>
						<tbody>
							{data &&
								data.data.map((element, i) => (
									<tr
										key={`element.name-${i}`}
										className='table-row-color font-14 d-flex flex-column d-md-table-row '
									>
										<div className='d-md-none font-16 fw-bold pb-0'>Email:</div>
										<td className='text-start vertical-align border-0 text-break'>
											{element.userEmail}
										</td>
										<div className='d-md-none font-16 fw-bold pb-0'>
											{t('invitationsPage.role')}:
										</div>
										<td className='text-start vertical-align border-0 text-break'>
											{element.role}
										</td>
										<div className='d-md-none font-16 fw-bold pb-0'>
											{t('invitationsPage.actions')}:
										</div>
										<td className='text-start vertical-align border-0 text-break'>
											{getDate(element.sentAt)}
										</td>
										<td className='text-start vertical-align border-0 text-break'>
											<div className='d-flex justify-content-start align-items-center gap-3'>
												<ButtonMUI
													id={`send-invitation-${i}`}
													onClick={() => {
														sendInvitation(element.id);
													}}
													variant='contained'
												>
													{t('invitationsPage.send')}
												</ButtonMUI>
												{/* <div
													id={`send-invitation-${i}`}
													className='group-button-edit text-white'
													style={{ backgroundColor: '#72d830' }}
													onClick={() => {
														sendInvitation(element.id);
													}}
												>
													{t('invitationsPage.send')}
												</div> */}
												<ButtonMUI
													variant='contained'
													color='error'
													id={`delete-invitation-${i}`}
													onClick={() => {
														setConfirmDeleteInvitation({
															modal: true,
															id: element.id,
														});
													}}
												>
													{t('invitationsPage.delete')}
												</ButtonMUI>
												{/* <div
													className='group-button-delete'
													id={`delete-invitation-${i}`}
													onClick={() => {
														setConfirmDeleteInvitation({
															modal: true,
															id: element.id,
														});
													}}
												>
													{t('invitationsPage.delete')}
												</div> */}
											</div>
										</td>
									</tr>
								))}
						</tbody>
					</Table>
				)}
			</div>
		</div>
	);
}
