import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DeleteUser } from 'api/mutations';
import Loader from 'app/components/Loader';
import { useEffect, useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import ConfirmAlert from 'libs/confirmAlert';
import { activeMenuState } from 'libs/atoms';
import { useRecoilState } from 'recoil';
import { Steps } from 'intro.js-react';
import ModalConfirm from 'hoc/ModalConfirm';
import useUserQuery from 'hoc/useUserQuery';
import { Avatar, Badge, Button, Tooltip, styled } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { stringAvatar } from 'hoc/avatarColor';
import { getInitials } from 'hoc/getInitials';
import { theme } from 'libs/theme';

const IndexUsersPage = ({ user }) => {
	const { t } = useTranslation();
	const history = useHistory();

	const [activeMenu, setActiveMenu] = useRecoilState(activeMenuState);

	useEffect(() => {
		setActiveMenu('users');
	}, []);

	const [deleteUserPopup, setDeleteUserPopup] = useState(false);
	const [userId, setUserId] = useState(undefined);
	const idUser = null;

	const [errorCreate, setErrorCreate] = useState(false);

	const [errorMsg, setErrorMsg] = useState();

	const [help, setHelp] = useState(false);

	const [sortbyList, setSortbyList] = useState({
		name: true,
		surname: true,
		email: true,
		role: true,
	});

	const queryClient = useQueryClient();

	// const deleteUserMutate = useMutation(DeleteUser, {
	//   onSuccess: () => {
	//     queryClient.invalidateQueries(["Users", user.currentAccount]);
	//   },
	// });

	// const deleteUser = async () => {
	//   try {
	//     const response = await deleteUserMutate.mutateAsync(userId);
	//     if (response) {
	//       ConfirmAlert.success(t("indexUsersPage.userDeleted"));
	//     }
	//   } catch (error) {
	//     if (error.response && error.response.data) {
	//       ConfirmAlert.error(error.response.data);
	//       return;
	//     }
	//   }
	// };

	const { users, deleteUser } = useUserQuery({ user: user });
	const { data, isLoading } = users;

	// const { isLoading, data } = useQuery(["Users", user.currentAccount], Users, {
	//   retry: false,
	//   onSuccess: (data) => {
	//     const newArr = [];
	//     data.data.forEach((element) => {
	//       let userModel = new UserClass(element, user.currentAccount);
	//       newArr.push(userModel);
	//     });
	//     data.data = newArr;
	//     console.log(newArr);
	//   },
	// });

	const options = {
		nextLabel: t('guideButtons.next'),
		prevLabel: t('guideButtons.prev'),
		doneLabel: t('guideButtons.done'),
	};

	let stepsUsers = [
		{
			element: '#invite-new-user',
			intro: (
				<div>
					<h5 style={{ fontSize: '20px', fontWeight: '700' }}>
						{t('usersGuide.inviteNewUserStepTitle')}
					</h5>
					<div className='pb-0'>{t('usersGuide.inviteNewUserStep')}</div>
				</div>
			),
		},
		{
			element: '#edit-user',
			intro: (
				<div>
					<h5 style={{ fontSize: '20px', fontWeight: '700' }}>
						{t('usersGuide.editUserStepTitle')}
					</h5>
					<div className='pb-0'>{t('usersGuide.editUserStep')}</div>
				</div>
			),
		},
		{
			element: '#delete-user-1',
			intro: (
				<div>
					<h5 style={{ fontSize: '20px', fontWeight: '700' }}>
						{t('usersGuide.deleteUserStepTitle')}
					</h5>
					<div className='pb-0'>{t('usersGuide.deleteUserStep')}</div>
				</div>
			),
		},
	];

	const onExit = () => {
		setHelp(false);
	};

	const sortBy = (by) => {
		switch (by) {
			case 'name':
				if (sortbyList.name) {
					data.data.sort((a, b) => {
						const titleA = a.name.toUpperCase();
						const titleB = b.name.toUpperCase();
						if (titleA < titleB) {
							return -1;
						}
						if (titleA > titleB) {
							return 1;
						}
						return 0;
					});
				} else {
					data.data.sort((a, b) => {
						const titleA = a.name.toUpperCase();
						const titleB = b.name.toUpperCase();
						if (titleA > titleB) {
							return -1;
						}
						if (titleA < titleB) {
							return 1;
						}
						return 0;
					});
				}
				setSortbyList({ ...sortbyList, name: !sortbyList.name });
				break;

			case 'surname':
				if (sortbyList.surname) {
					data.data.sort((a, b) => {
						const titleA = a.surname.toUpperCase();
						const titleB = b.surname.toUpperCase();
						if (titleA < titleB) {
							return -1;
						}
						if (titleA > titleB) {
							return 1;
						}
						return 0;
					});
				} else {
					data.data.sort((a, b) => {
						const titleA = a.surname.toUpperCase();
						const titleB = b.surname.toUpperCase();
						if (titleA > titleB) {
							return -1;
						}
						if (titleA < titleB) {
							return 1;
						}
						return 0;
					});
				}
				setSortbyList({ ...sortbyList, surname: !sortbyList.surname });
				break;

			case 'email':
				if (sortbyList.email) {
					data.data.sort((a, b) => {
						const titleA = a.email.toUpperCase();
						const titleB = b.email.toUpperCase();
						if (titleA < titleB) {
							return -1;
						}
						if (titleA > titleB) {
							return 1;
						}
						return 0;
					});
				} else {
					data.data.sort((a, b) => {
						const titleA = a.email.toUpperCase();
						const titleB = b.email.toUpperCase();
						if (titleA > titleB) {
							return -1;
						}
						if (titleA < titleB) {
							return 1;
						}
						return 0;
					});
				}
				setSortbyList({ ...sortbyList, email: !sortbyList.email });
				break;

			case 'role':
				if (sortbyList.role) {
					data.data.sort((a, b) => {
						const titleA = a.role.toUpperCase();
						const titleB = b.role.toUpperCase();
						if (titleA < titleB) {
							return -1;
						}
						if (titleA > titleB) {
							return 1;
						}
						return 0;
					});
				} else {
					data.data.sort((a, b) => {
						const titleA = a.role.toUpperCase();
						const titleB = b.role.toUpperCase();
						if (titleA > titleB) {
							return -1;
						}
						if (titleA < titleB) {
							return 1;
						}
						return 0;
					});
				}
				setSortbyList({ ...sortbyList, role: !sortbyList.role });

				break;

			default:
				break;
		}
	};

	const StyledBadge = styled(Badge)(({ theme }) => ({
		'& .MuiBadge-badge': {
			backgroundColor: '#44b700',
			color: '#44b700',
			boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
			'&::after': {
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				borderRadius: '50%',
				animation: 'ripple 1.2s infinite ease-in-out',
				border: '1px solid currentColor',
				content: '""',
			},
		},
		'@keyframes ripple': {
			'0%': {
				transform: 'scale(.8)',
				opacity: 1,
			},
			'100%': {
				transform: 'scale(2.4)',
				opacity: 0,
			},
		},
	}));

	const SmallAvatar = styled(Avatar)(({ theme }) => ({
		width: 22,
		height: 22,
		border: `2px solid ${theme.palette.background.paper}`,
	}));

	if (isLoading) {
		return <Loader />;
	}

	return (
		<>
			<Steps
				enabled={help}
				steps={stepsUsers}
				onExit={onExit}
				initialStep={0}
				options={options}
			/>
			<div className='m-0-auto max-width'>
				<ModalConfirm
					show={deleteUserPopup}
					message={`${t('indexUsersPage.deleteUser')}?`}
					setShow={setDeleteUserPopup}
					onConfirm={() => {
						setDeleteUserPopup(false);
						deleteUser({ userId: userId });
						setUserId('');
					}}
				/>
				<Modal
					show={errorCreate}
					className='mt-5'
					centered
					onHide={() => setErrorCreate(false)}
				>
					<div className='d-flex flex-column align-content-center'>
						<div className='text-center p-3 font-18'>{errorMsg}</div>
						<div
							className=' text-center p-1 pointer-hover ok-alert-button m-auto mb-3 '
							onClick={() => {
								setErrorCreate(false);
							}}
						>
							ok
						</div>
					</div>
				</Modal>

				<div className='d-flex gap-2 p-0 '>
					<h1 className='title-font'>{t('indexUsersPage.users')}</h1>
					<FontAwesomeIcon
						icon={faCircleQuestion}
						className='text-blue pointer-hover fs-5'
						onClick={() => setHelp(true)}
					/>
				</div>
				<div className='dashboard-box mt-4 py-4 px-5'>
					<div className='d-flex justify-content-end mb-4'>
						<Button
							variant='contained'
							sx={{ mb: 1 }}
							onClick={() => history.push('/invitations')}
						>
							{t('indexUsersPage.inviteUser')}
						</Button>
						{/* <Link
							style={{
								color: 'white',
								backgroundColor: '#72d830',
								textDecoration: 'none',
							}}
							id='invite-new-user'
							className='mt-3 create-group-button d-flex justify-content-center align-items-center table-row-color'
							to='/invitations'
						>
							{t('indexUsersPage.inviteUser')}
						</Link> */}
					</div>
					<Table responsive bordered>
						<thead>
							<tr className='d-none d-md-table-row'>
								<th className='col-1 text-start font-20'></th>
								<th
									className='col-2 text-start font-20'
									onClick={() => {
										sortBy('name');
									}}
								>
									<span className='pointer-hover'>
										{t('indexUsersPage.name')}
									</span>
								</th>
								<th
									className='col-2 text-start font-20'
									onClick={() => {
										sortBy('surname');
									}}
								>
									<span className='pointer-hover'>
										{t('indexUsersPage.surname')}
									</span>
								</th>
								<th
									className='col-2 text-start font-20'
									onClick={() => {
										sortBy('email');
									}}
									id='code-group'
								>
									<span className='pointer-hover'>
										{t('indexUsersPage.email')}
									</span>
								</th>
								<th
									className='col-1 text-start font-20'
									onClick={() => {
										sortBy('role');
									}}
									id='delete-group'
								>
									<span className='pointer-hover'>
										{t('indexUsersPage.role')}
									</span>
								</th>
								<th className='col-1 text-start font-20' id='delete-group'>
									<span className='pointer-hover'>
										{t('indexUsersPage.actions')}
									</span>
								</th>
							</tr>
						</thead>
						<tbody>
							{data && (
								<>
									{data.data.map((element, i) => (
										<tr
											key={`element.name-${i}`}
											className='table-row-color font-14 d-flex flex-column d-md-table-row '
										>
											<td>
												<StyledBadge
													overlap='circular'
													anchorOrigin={{
														vertical: 'bottom',
														horizontal: 'right',
													}}
													variant='dot'
													sx={{
														'& .MuiBadge-badge': {
															backgroundColor: `${
																element.active
																	? theme.palette.primary.main
																	: theme.palette.error.main
															}`,
															color: `${
																element.active
																	? theme.palette.primary.main
																	: theme.palette.error.main
															}`,
														},
													}}
												>
													<Tooltip
														title={
															element.active
																? `${t('editUserPage.notifications')} ON`
																: `${t('editUserPage.notifications')} OFF`
														}
													>
														<Avatar
															{...stringAvatar(
																`${element.name} ${element.surname}`,
																'40px'
															)}
														>
															{getInitials(
																`${element.name} ${element.surname}`
															)}
														</Avatar>
													</Tooltip>
												</StyledBadge>
											</td>
											<div className='d-md-none font-16 fw-bold pb-0'>
												{t('indexUsersPage.name')}:
											</div>
											<td className='text-start vertical-align border-0 text-break'>
												{element.name}
											</td>
											<div className='d-md-none font-16 fw-bold pb-0'>
												{t('indexUsersPage.surname')}:
											</div>
											<td className='text-start vertical-align border-0 text-break'>
												{element.surname}
											</td>
											<div className='d-md-none font-16 fw-bold pb-0'>
												{t('indexUsersPage.email')}:
											</div>
											<td className='text-start vertical-align border-0 text-break'>
												{element.email}
											</td>
											<div className='d-md-none font-16 fw-bold pb-0'>
												{' '}
												{t('indexUsersPage.role')}:
											</div>
											<td className='text-start vertical-align border-0 text-break'>
												{element.owner ? 'Manager' : element.role}
											</td>
											<div className='d-md-none font-16 fw-bold pb-0'>
												{t('indexUsersPage.actions')}:
											</div>
											<td className='border-0'>
												<div className='d-flex justify-content-start align-items-center gap-3'>
													{/* <Button
														variant='outlined'
														color={
															element.active ? 'primary' : 'error'
														}
														sx={{ width: '100px' }}
													>
														{element.active
															? t('channelsPage.enabled')
															: t('channelsPage.disabled')}
													</Button> */}
													{/* {element.active ? (
														<div
															className='group-button-edit text-white'
															style={{
																backgroundColor: '#72d830',
																cursor: 'default',
															}}
															onClick={() => {}}
														>
															{t('channelsPage.enabled')}
														</div>
													) : (
														<div
															className='channel-button-disabled text-white'
															style={{ cursor: 'default' }}
															onClick={() => {}}
														>
															{t('channelsPage.disabled')}
														</div>
													)} */}
													{user.email !== element.email ? (
														<Button
															variant='contained'
															id='edit-user'
															color='secondary'
															onClick={() =>
																history.push(`/user/${element.id}`)
															}
														>
															{t('buttonUsers.edit')}
														</Button>
													) : (
														// <Link
														// 	id='edit-user'
														// 	className='me-2 group-button-edit'
														// 	to={`/user/${element.id}`}
														// >
														// 	{t('buttonUsers.edit')}
														// </Link>
														<Button
															onClick={() => history.push(`/user/edit`)}
															variant='contained'
															color='secondary'
														>
															{t('buttonUsers.edit')}
														</Button>
														// <Link
														// 	className='me-2 group-button-edit'
														// 	to={`/user/edit`}
														// >
														// 	{t('buttonUsers.edit')}
														// </Link>
													)}
													{/* {user.email !== element.email && ( */}
													<Button
														disabled={
															element.owner || user.email == element.email
														}
														// hidden={
														// 	element.owner || user.email == element.email
														// }
														variant='contained'
														color='error'
														onClick={() => {
															setUserId(element.id);
															setDeleteUserPopup(true);
														}}
													>
														{t('buttonUsers.delete')}
													</Button>
													{/* )} */}
												</div>
											</td>
										</tr>
									))}
								</>
							)}
						</tbody>
					</Table>
				</div>
			</div>
		</>
	);
};
export default IndexUsersPage;
