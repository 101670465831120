export const API_HOST = process.env.REACT_APP_API_HOST;
export const API_PATH = process.env.REACT_APP_API_PATH;
export const API_URL = `${API_HOST}${API_PATH}`;
export const JWT_TOKEN = process.env.REACT_APP_JWT_TOKEN_NAME;
export const SIGNUP_WITH_ACTIVATE =
  process.env.REACT_APP_SIGNUP_WITH_ACTIVATE === "true";
export const LOG_ENTRY_CREATE_URL = process.env.REACT_APP_LOG_ENTRY_CREATE_URL;
export const ENABLE_SIGNUP = process.env.REACT_APP_ENABLE_SIGNUP === "true";
export const ENABLE_CUSTOMER_PORTAL =
  process.env.REACT_APP_ENABLE_CUSTOMER_PORTAL === "true";
export const ACCOUNT_STATUSES = {
  subscriptionTrial: "trial",
  subscriptionPaymentFailed: "payment_failed",
  subscriptionDeactivated: "deactivated",
  subscriptionActive: "active",
};
export const BACKOFFICE = "/backoffice";
export const ENTERPRISE = process.env.REACT_APP_ENTERPRISE_ACCOUNT === "true";
export const ENTERPRISE_IMAGE = process.env.REACT_APP_ENTERPRISE_IMAGE;
export const ENTERPRISE_IMAGE_MOBILE =
  process.env.REACT_APP_ENTERPRISE_IMAGE_MOBILE;
export const ENTERPRISE_NAME = process.env.REACT_APP_ENTERPRISE_NAME;
