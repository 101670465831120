import { createTheme, tooltipClasses } from '@mui/material';
import {} from '@mui/material/colors';

const blue = '#00c2ef';
const green = '#72d830';
const lightGreen = '#e6f5dc';
const orange = ' #ffce00';
const red = '#ff0074';
const lightRed = '#f7dce9';

export const theme = createTheme({
	palette: {
		primary: {
			main: green,
			light: lightGreen,
		},
		secondary: {
			main: blue,
		},
		error: {
			main: red,
			light: lightRed,
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					...(ownerState.variant === 'outlined' && {
						'&:hover': {
							outline: `1px solid`,
							transition: '0.1s linear',
						},
						textAlign: 'center',
						transition: '0.1s linear',
					}),
					...(ownerState.variant === 'contained' && {
						color: 'white',
						textAlign: 'center',
						transition: '0.1s linear',
					}),
					...{
						height: '36px',
					},
				}),
			},
		},
		MuiChip: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					...{
						backgroundColor: theme.palette.primary.light,
					},
				}),
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					...(ownerState.variant === 'outlined' && {
						'& .MuiOutlinedInput-root:hover': {
							'& > fieldset': {
								border: `2px solid  ${theme.palette.primary.main}`,
							},
						},
					}),
				}),
			},
		},
		MuiFormControl: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					...{
						'& .MuiOutlinedInput-root:hover': {
							'& > fieldset': {
								border: `2px solid  ${theme.palette.primary.main}`,
							},
						},
					},
				}),
			},
		},
		MuiPagination: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					...{
						'& .Mui-selected': {
							color: 'white !important',
						},
						'& .MuiPaginationItem-page': {
							color: 'balck',
						},
					},
				}),
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: () => ({
					backgroundColor: theme.palette.common.white,
					color: 'rgba(0, 0, 0, 0.87)',
					boxShadow: theme.shadows[1],
					fontSize: 11,
				}),
			},
		},
	},
});
