import { BACKOFFICE } from 'config';
import Axios from 'libs/axios';

const Me = async (key) => {
	const result = await Axios.authenticated().get('/users/me');
	return result;
};

const Account = async (accountId) => {
	const result = await Axios.authenticated().get(
		BACKOFFICE + `/accounts/${accountId}`
	);
	return result;
};

const Customer = async () => {
	const result = await Axios.authenticated().get(
		BACKOFFICE + '/stripe/customers/me'
	);
	return result;
};

const CustomerInvoices = async () => {
	const result = await Axios.authenticated().get(
		BACKOFFICE + '/stripe/customers/me/invoices'
	);
	return result;
};

const CustomerCards = async () => {
	const result = await Axios.authenticated().get(
		BACKOFFICE + '/stripe/customers/me/cards'
	);
	return result;
};

const Plans = async () => {
	const result = await Axios.base().get('/stripe/plans');
	return result;
};

const Users = async () => {
	const result = await Axios.authenticated().get(BACKOFFICE + '/users/');
	return result;
};

const User = async (userId) => {
	const result = await Axios.authenticated().get(
		BACKOFFICE + `/users/${userId}`
	);
	return result;
};

const LogEntries = async () => {
	const result = await Axios.authenticated().get(
		'/log-entries/?sort=-created_at'
	);
	return result;
};

const AllLogEntries = async (page, limit) => {
	const result = await Axios.authenticated().get(
		`/log-entries?limit=${limit}&page=${page}&sort=-created_at`
	);
	return result;
};

const Projects = async () => {
	const result = await Axios.authenticated().get('/projects');
	return result;
};

const ProjectsMobile = async () => {
	const result = await Axios.authenticated().get(
		BACKOFFICE + '/projects-mobile'
	);
	return result;
};

const BackOfficeProjects = async () => {
	const result = await Axios.authenticated().get(BACKOFFICE + '/projects');
	return result;
};

const BackOfficeProject = async (id) => {
	const result = await Axios.authenticated().get(
		BACKOFFICE + `/projects/${id}`
	);
	return result;
};

const LogEntryProject = async (projectId, page, limit) => {
	const result = await Axios.authenticated().get(
		`/log-entries?filter=projectId:$eq:${projectId}&limit=${limit}&page=${page}&sort=-created_at`
	);
	return result;
};

const TagsByProject = async (projectId) => {
	const result = await Axios.authenticated().get(`/projects/${projectId}/tags`);
	return result;
};

const LogEntriesProjectByTags = async (projectId, tag, page, limit) => {
	const result = await Axios.authenticated().get(
		`/log-entries?filter=projectId:$eq:${projectId},tags:$${tag}&limit=${limit}&page=${page}&sort=-created_at`
	);
	return result;
};

const StarredLogEntries = async (accountId, projectId, page, limit) => {
	const result = await Axios.authenticated().get(
		`/log-entries?limit=${limit}&sort=-created_at&page=${page}&filter=projectId:$eq:${projectId},starred:$eq:${accountId}`
	);
	return result;
};

const AllStarredLogEntries = async (accountId, page, limit) => {
	const result = await Axios.authenticated().get(
		`/log-entries?limit=${limit}&sort=-created_at&page=${page}&filter=starred:$eq:${accountId}`
	);
	return result;
};

const Groups = async () => {
	const result = await Axios.authenticated().get(BACKOFFICE + `/groups`);
	return result;
};

const Group = async (groupId) => {
	const result = await Axios.authenticated().get(
		BACKOFFICE + `/groups/${groupId}`
	);
	return result;
};

const LogEntryId = async (id) => {
	const result = await Axios.authenticated().get(`/log-entries/${id}`);
	return result;
};

const SearchInSummary = async (projectId, string, page, limit) => {
	const result = await Axios.authenticated().get(
		`/log-entries?limit=${limit}&sort=-created_at&page=${page}&filter=projectId:$eq:${projectId},summary:$regex:${string}`
	);
	return result;
};

const Channels = async () => {
	const result = await Axios.authenticated().get(BACKOFFICE + `/channels`);
	return result;
};

const Channel = async (channelId) => {
	const result = await Axios.authenticated().get(
		BACKOFFICE + `/channels/${channelId}`
	);
	return result;
};

const SearchInTitleSummaryBody = async (projectId, search) => {
	const result = await Axios.authenticated().get(
		`/log-entries/${projectId}/${search}?sort=-created_at&limit=10000`
	);
	return result;
};

const Invitations = async () => {
	const result = await Axios.authenticated().get(BACKOFFICE + `/invitation`);
	return result;
};

const AuthToken = async () => {
	const result = await Axios.authenticated().get(`/users/me/account-token`);
	return result;
};

const NewAuthToken = async () => {
	const result = await Axios.authenticated().get(`/users/me/account-new-token`);
	return result;
};

export {
	Me,
	Account,
	Customer,
	CustomerInvoices,
	CustomerCards,
	Plans,
	Users,
	User,
	LogEntries,
	Projects,
	LogEntryProject,
	TagsByProject,
	LogEntriesProjectByTags,
	StarredLogEntries,
	Groups,
	Group,
	LogEntryId,
	SearchInSummary,
	Channels,
	Channel,
	SearchInTitleSummaryBody,
	AllStarredLogEntries,
	AllLogEntries,
	Invitations,
	AuthToken,
	NewAuthToken,
	BackOfficeProjects,
	BackOfficeProject,
	ProjectsMobile,
};
