import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { activeMenuState } from 'libs/atoms';
import { useRecoilState } from 'recoil';
import { Button, Form, Modal } from 'react-bootstrap';
import { ChromePicker } from 'react-color';
import useProjectQuery from 'hoc/useProjectQuery';
import {
	Avatar,
	Box,
	Card,
	CardActionArea,
	CardContent,
	Grid,
	IconButton,
	Paper,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { Button as ButtonMUI } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faBellSlash } from '@fortawesome/free-solid-svg-icons';

export default function ProjectsPage({ user }) {
	const [activeMenu, setActiveMenu] = useRecoilState(activeMenuState);

	const [open, setOpen] = useState(false);

	const history = useHistory();

	const [newProject, setNewProject] = useState({
		name: '',
		projectId: '',
		color: '#ea7018',
		description: '',
	});

	const { createProject, projects, externalprojects } = useProjectQuery(user);

	const userProjects = user.role === 'external' ? externalprojects : projects;

	console.log('UserProjects: ', userProjects);

	useEffect(() => {
		setActiveMenu('projects');
	}, []);

	const { t } = useTranslation();

	return (
		<div className='max-width m-0-auto'>
			<Modal
				show={open}
				onHide={() => {
					setOpen(false);
					setNewProject({
						name: '',
						projectId: '',
						color: '#ea7018',
						description: '',
					});
				}}
				centered
			>
				<Form
					onReset={(e) => {
						setOpen(false);
						setNewProject({
							name: '',
							projectId: '',
							color: '#ea7018',
							description: '',
						});
					}}
					onSubmit={(e) => {
						e.preventDefault();
						setOpen(false);
						setNewProject({
							name: '',
							projectId: '',
							color: '#ea7018',
							description: '',
						});
						createProject(newProject);
					}}
				>
					<div className='popup-crea-gruppo p-4'>
						{/* name */}
						<div>
							<TextField
								inputProps={{ maxLength: 20 }}
								variant='outlined'
								label={t('projects.name')}
								fullWidth
								onChange={(event) => {
									setNewProject({ ...newProject, name: event.target.value });
								}}
								value={newProject.name}
								required
							/>
						</div>
						{/* projectID */}
						<div>
							<TextField
								inputProps={{ maxLength: 20 }}
								variant='outlined'
								label='ProjectId'
								fullWidth
								onChange={(event) => {
									setNewProject({
										...newProject,
										projectId: event.target.value,
									});
								}}
								value={newProject.projectId}
								required
							/>
						</div>
						{/* color  */}
						<div>
							<div className='fw-bold mb-2'>{t('projects.color')}</div>
							<div className='d-flex gap-2'>
								<ChromePicker
									onChange={(color) => {
										setNewProject({ ...newProject, color: color.hex });
									}}
									color={newProject.color}
									disableAlpha
								/>
								<div
									className={`d-flex justify-content-center align-items-center ms-5 me-4 text-white `}
									style={{
										backgroundColor: `${newProject.color}`,
										width: '50px',
										height: '50px',
										borderRadius: '50%',
										fontSize: '20px',
									}}
								>
									{newProject.name.charAt(0)}
								</div>
							</div>
						</div>
						<div>
							<TextField
								fullWidth
								variant='outlined'
								label={t('projects.description')}
								inputProps={{ maxLength: 150 }}
								multiline
								onChange={(event) => {
									setNewProject({
										...newProject,
										description: event.target.value,
									});
								}}
								rows={3}
								value={newProject.description}
							/>
						</div>
						<div className='d-flex justify-content-center gap-5'>
							<ButtonMUI type='reset' variant='contained' color='error'>
								{t('createUsersPage.cancel')}
							</ButtonMUI>
							{/* <Button
								type='reset'
								className='group-cancel-button d-flex justify-content-center align-items-center'
							>
								{t('createUsersPage.cancel')}
							</Button> */}
							<ButtonMUI variant='contained' type='submit'>
								{t('createUsersPage.save')}
							</ButtonMUI>
							{/* <Button
								type='submit'
								className='create-group-button d-flex justify-content-center align-items-center'
							>
								{t('createUsersPage.save')}
							</Button> */}
						</div>
					</div>
				</Form>
			</Modal>
			<h1 className='title-font'>{t('projectsPage.title')}</h1>
			{userProjects && (
				<>
					<div className='dashboard-box mt-4 pb-4'>
						{user.role != 'external' && (
							<div className='d-flex justify-content-end me-4'>
								<ButtonMUI
									sx={{ mt: 2 }}
									variant='contained'
									onClick={() => {
										setOpen(true);
									}}
								>
									{t('projects.createProject')}
								</ButtonMUI>
							</div>
						)}
						{/* <div className=' projects-grid'>
							{userProjects.data.length === 0 ? (
								<div className={`position-absolute mt-3 ms-3`}>
									<h3 className='font-20 fw-bold'>
										{t('projectsPage.noProject')}
									</h3>
								</div>
							) : (
								<>
									{Array.isArray(userProjects.data) &&
										userProjects.data.map((project, i) => (
											<Link
												key={`project-${i}`}
												to={`/project/logentries/${project.projectId}/${project.id}`}
												className='w-100-perc project-item d-flex justify-content-start align-items-center mt-3'
											>
												<div className='d-flex align-items-center'>
													<div
														className={`d-flex justify-content-center align-items-center ms-5 me-4 ${
															project.color != '' ? 'text-white' : 'text-black'
														} project-logo font-20`}
														style={{ backgroundColor: `${project.color}` }}
													>
														{project.name.charAt(0)}
													</div>
													<div className='d-flex flex-column'>
														<span className='font-18 text-break'>
															{project.name}
														</span>
														<span className='font-12 text-secondary text-break'>
															{project.projectId}
														</span>
													</div>
												</div>
											</Link>
										))}
								</>
							)}
						</div> */}
						<Box
							sx={{
								display: 'grid',
								gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
								gridTemplateRows: 'auto',
								gap: 3,
								mt: '30px',
							}}
						>
							{userProjects.data.length === 0 ? (
								<div className={`position-absolute mt-3 ms-3`}>
									<h3 className='font-20 fw-bold'>
										{t('projectsPage.noProject')}
									</h3>
								</div>
							) : (
								<>
									{Array.isArray(userProjects.data) &&
										userProjects.data.map((project, i) => (
											<Card
												elevation={4}
												sx={{
													height: '100px',
													boxShadow:
														'1px 2px 3px rgba(0, 0, 0, 0.09803921568627451)',
													'&:hover': {
														transition: '0.5s',
														boxShadow:
															'2px 8px 6px rgba(0, 0, 0, 0.09803921568627451);',
													},
												}}
											>
												<CardActionArea>
													<CardContent
														sx={{ height: '100px' }}
														onClick={() =>
															history.push(
																`/project/logentries/${project.projectId}/${project.id}`
															)
														}
													>
														<Stack
															direction={'row'}
															gap={2}
															marginLeft={5}
															mt={2}
															alignItems={'center'}
															justifyContent={'space-between'}
														>
															<Stack
																direction={'row'}
																gap={2}
																alignItems={'center'}
															>
																<Avatar
																	sx={{ backgroundColor: `${project.color}` }}
																>
																	P
																</Avatar>
																<Typography fontSize={20}>
																	{project.name}
																</Typography>
															</Stack>
															<Tooltip
																title={
																	project.status
																		? t('projectsPage.projectActive')
																		: t('projectsPage.projectNotActive')
																}
															>
																<span>
																	<IconButton size='medium' disabled>
																		<FontAwesomeIcon
																			icon={
																				project.status ? faBell : faBellSlash
																			}
																		/>
																	</IconButton>
																</span>
															</Tooltip>
														</Stack>
													</CardContent>
												</CardActionArea>
											</Card>
										))}
								</>
							)}
						</Box>
					</div>
				</>
			)}
		</div>
	);
}
