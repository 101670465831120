function stringToColor(string) {
	let hash = 0;
	let i;

	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let r = (((hash >> 16) & 0xff) % 128) + 100;
	let g = (((hash >> 8) & 0xff) % 128) + 100;
	let b = ((hash & 0xff) % 128) + 100;

	const colorCode = `rgb(${r}, ${g}, ${b})`;
	return colorCode;
}

export function stringAvatar(name, size, styles) {
	const nameUppercase = name?.toUpperCase();
	return {
		sx: {
			color: 'white',
			background: `linear-gradient(120deg, ${stringToColor(
				name
			)} 0%, ${stringToColor(name + 'ak')} 100%)`,
			width: size ? size : '32px',
			height: size ? size : '32px',
			fontSize: '13px',
			border: '0 !important',
			...styles,
		},
		children: `${nameUppercase.split(' ')[0][0]}${
			nameUppercase.split(' ')[1][0]
		}`,
	};
}
