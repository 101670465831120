import React, { useEffect } from "react";
import { AllLogEntries, AllStarredLogEntries, LogEntries } from "api/queries";
import moment from "moment";
import { useState } from "react";
import { Col, Form, Modal, Row, Table, Tabs, Tab } from "react-bootstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link, useHistory } from "react-router-dom";
import {
  faEye,
  faEyeSlash,
  faCopy,
  faXmark,
  faTrash,
  faStar,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import confirmAlert from "libs/confirmAlert";
import Loader from "app/components/Loader";
import { LOG_ENTRY_CREATE_URL } from "config";
import { LogEntryDelete, LogEntryStar, LogEntryUnstar } from "api/mutations";
import axios from "libs/axios";
import { ENTERPRISE } from "config";
import { ENTERPRISE_IMAGE_MOBILE } from "config";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import DOMPurify from "dompurify";
import { Button, IconButton, TextField } from "@mui/material";

// /dashboard => trial users
export default function TrialDa({ user }) {
  const { t } = useTranslation();

  const { isLoading, error, data } = useQuery(
    ["logEntries", user.currentAccount],
    LogEntries
  );

  const history = useHistory();

  const apiToken = user.account.apiToken;

  const [key, setKey] = useState("password");

  const [logIDState, setLogIDState] = useState(null);

  const [show, setShow] = useState(false);

  const [modalData, setmodalData] = useState(null);

  const [pages, setPages] = useState([]);

  const [starredPages, setStarredPages] = useState([]);

  const baseFilter = { page: 1, limit: 10 };

  const [logEntriesPL, setLogEntriesPL] = useState({ page: 1, limit: 10 });

  const starPageLimit = { page: 1, limit: 10 };

  const [starredPL, setStarredPL] = useState({ page: 1, limit: 10 });

  const [tab, setTab] = useState(false);

  const { data: ultimeNotifiche, refetch: refetchLogEntries } = useQuery(
    ["logEntries", user.currentAccount],
    () => AllLogEntries(baseFilter.page, baseFilter.limit),
    {
      onSuccess: (data) => {
        totPages(data.data.meta, true);
      },
    }
  );

  const { data: starredLogEnties, refetch: refetchStarred } = useQuery(
    ["starredEntries", user.currentAccount],
    () =>
      AllStarredLogEntries(user.id, starPageLimit.page, starPageLimit.limit),
    {
      onSuccess: (data) => {
        console.log(data);
        totPages(data.data.meta, false);
      },
    }
  );

  useEffect(() => {
    refetchStarred();
  }, [starredPL]);

  useEffect(() => {
    refetchLogEntries();
  }, [logEntriesPL]);

  const totPages = (data, log) => {
    let pags = 0;
    if (data.count % data.limit === 0) {
      pags = Math.floor(data.count / data.limit);
    } else {
      pags = Math.floor(data.count / data.limit) + 1;
    }
    let arrPags = [];
    for (let i = 0; i < pags; i++) {
      arrPags.push(i);
    }

    if (log) {
      setPages(arrPags);
    } else {
      setStarredPages(arrPags);
    }
  };

  const queryClient = useQueryClient();

  const showModal = (id) => {
    setLogIDState(id);
    refetch(id);
    setShow(true);
  };

  const [deletePopup, setDeletePopup] = useState(false);

  const mostraKey = () => {
    if (key === "password") {
      setKey("text");
    } else {
      setKey("password");
    }
  };

  const refetch = async (id) => {
    const collapseInfo = await axios.authenticated().get(`/log-entries/${id}`);
    setmodalData(collapseInfo);
    refetchStarred();
  };

  /* star mutation */
  const logEntryStar = useMutation(LogEntryStar, {
    onSuccess: () => {
      refetch(logIDState);
    },
  });

  /* unstar mutation */
  const logEntryUnstar = useMutation(LogEntryUnstar, {
    onSuccess: () => {
      refetch(logIDState);
    },
  });

  const removeAddStar = async (data) => {
    if (data.starred.includes(user.id)) {
      try {
        const response = await logEntryUnstar.mutateAsync(data.id);
      } catch (error) {
        if (error.response && error.response.data) {
          confirmAlert.error(error.response.data);
          return;
        }
      }
    } else {
      try {
        const response = await logEntryStar.mutateAsync(data.id);
      } catch (error) {
        if (error.response && error.response.data) {
          confirmAlert.error(error.response.data);
          return;
        }
      }
    }
  };

  const deleteLogEntryMutation = useMutation(LogEntryDelete, {
    onSuccess: () => {
      queryClient.invalidateQueries(["logEntries", user.currentAccount]);
    },
  });

  const deleteLogEntry = async (id) => {
    try {
      const response = await deleteLogEntryMutation.mutateAsync(id);
    } catch (error) {
      if (error.response && error.response.data) {
        confirmAlert.error(error.response.data);
        return;
      }
    }
  };

  const copyApiKey = () => {
    navigator.clipboard.writeText(`${apiToken}`);
    confirmAlert.success(`${t("dashboardPageNew.copyKeyAlert")}`);
  };

  const copyEverylogUrl = () => {
    navigator.clipboard.writeText(`${LOG_ENTRY_CREATE_URL}`);
    confirmAlert.success(`${t("dashboardPageNew.copyKeyAlert")}`);
  };

  if (isLoading) {
    return <Loader />;
  }

  function timeSince(createdAt) {
    return moment(createdAt).format("YYYY-MM-DD HH:MM");
    const date = new Date(createdAt);

    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + t("projectsPage.timeSince.yearsAgo");
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + t("projectsPage.timeSince.monthsAgo");
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + t("projectsPage.timeSince.daysAgo");
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + t("projectsPage.timeSince.hoursAgo");
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + t("projectsPage.timeSince.minutesAgo");
    }
    return Math.floor(seconds) + t("projectsPage.timeSince.secondsAgo");
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div className="max-width m-0-auto">
      <Modal show={show} centered size={"lg"} onHide={() => setShow(false)}>
        {modalData && (
          <div className="popup-dettagli p-4 rounded-2">
            <div className="d-flex justify-content-between mb-2">
              <div className="text-blue font-16">
                {timeSince(modalData.data.createdAt)}
              </div>
              <FontAwesomeIcon
                onClick={() => setShow(false)}
                icon={faXmark}
                className="trash-color pointer-hover"
              />
            </div>
            {/* title */}
            <div className="d-flex align-items-center mb-2 mt-4">
              <div className="icon-modal d-flex justify-content-center align-items-center fs-3 me-3">
                {modalData.data.icon ? (
                  modalData.data.icon
                ) : (
                  <img
                    width={"40px"}
                    src={`${
                      ENTERPRISE
                        ? ENTERPRISE_IMAGE_MOBILE
                        : "/images/everylog-16.png"
                    }`}
                  />
                )}
              </div>
              <div className="fw-bold font-18">{modalData.data.title}</div>
            </div>
            {/* summary */}
            <div className="font-15 fw-bold subtitle-modal mt-3 text-uppercase">
              {t("projectsPage.summary")}
            </div>
            <div
              className="font-15 mt-3 font-18"
              style={{ whiteSpace: "pre-line" }}
            >
              {modalData.data.summary}
            </div>
            <div className="w-100-perc separate-modal" />
            {/* body */}
            <div className="font-15 fw-bold subtitle-modal mt-3 text-uppercase">
              {t("notifyPage.body")}
            </div>
            <div
              className="font-15 mt-3 font-18"
              style={{ whiteSpace: "pre-line" }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(modalData.data.body, {
                  ALLOWED_TAGS: [
                    "h1",
                    "h2",
                    "h3",
                    "h4",
                    "h5",
                    "h6",
                    "p",
                    "b",
                    "br",
                    "i",
                    "u",
                    "a",
                    "img",
                    "ul",
                    "li",
                    "ol",
                  ],
                  ALLOWED_ATTR: ["style", "src", "href"],
                }),
              }}
            />
            <div className="w-100-perc separate-modal" />
            {/* properties */}
            <div className="font-15 fw-bold subtitle-modal mt-3 text-uppercase">
              {t("projectsPage.properties")}
            </div>
            {modalData.data.properties && (
              <div>
                {Object.keys(modalData.data.properties).map((key, i) => (
                  <div
                    key={i}
                    className="d-flex flex-column gap-1 flex-md-row mb-3"
                  >
                    <div className="properties-item-modal p-1 mb-1">{key}</div>
                    <div className="properties-item-modal p-1 mb-1">
                      {modalData.data.properties[key]}
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="w-100-perc separate-modal" />
            {/* tags */}
            <div className="font-15 fw-bold subtitle-modal mt-3">TAGS</div>
            {modalData.data.tags && (
              <div className="tags-grid mt-4">
                {modalData.data.tags.map((tag, i) => (
                  <div key={i} className="tag-inpopup me-2">
                    {tag}
                  </div>
                ))}
              </div>
            )}
            <div className="w-100-perc separate-modal" />
            {/* star / delete */}
            {modalData.data.starred && (
              <div className="d-flex justify-content-between mt-4">
                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={() => {
                    setDeletePopup(true);
                    setShow(false);
                  }}
                  title={t("projectsPage.delete")}
                  className="pointer-hover trash-color"
                />
                {modalData.data.starred.some(
                  (findUser) => findUser === user.id
                ) ? (
                  <FontAwesomeIcon
                    className="text-blue pointer-hover"
                    icon={faStar}
                    onClick={() => {
                      removeAddStar(modalData.data);
                    }}
                    title={t("projectsPage.unstar")}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="trash-color pointer-hover"
                    icon={faStar}
                    onClick={() => {
                      removeAddStar(modalData.data);
                    }}
                    title={t("projectsPage.star")}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </Modal>
      <Modal show={deletePopup} centered onHide={() => setDeletePopup(false)}>
        <div className="delete-popup ">
          <div className="text-center m-3">
            {t("projectsPage.modalDelete")}?
          </div>
          <div className="d-flex justify-content-center gap-5 mb-3">
            <div
              onClick={() => {
                setDeletePopup(false);
                setShow(false);
                deleteLogEntry(modalData.data.id);
              }}
              className="group-cancel-button d-flex justify-content-center align-items-center"
            >
              {t("indexUsersPage.yes")}
            </div>
            <div
              onClick={() => {
                setDeletePopup(false);
                setShow(true);
              }}
              className="group-cancel-button d-flex justify-content-center align-items-center"
            >
              {t("indexUsersPage.no")}
            </div>
          </div>
        </div>
      </Modal>

      {user.role !== "external" && (
        <Row className="">
          {user.owner && (
            <Col className="col-12 col-md-6 mt-3 mt-md-0 ">
              <div className="dashboard-leftbox d-flex flex-column flex-xl-row min-height h-100">
                <img
                  className="d-none d-xl-block w-50"
                  src="/images/box-2-dashboard.jpg"
                />
                <div className="d-flex flex-column justify-content-between p-3 h-100">
                  <p className="pt-3 fw-bold">
                    {t("dashboardPageNew.trialMsg")}
                  </p>
                  {user.account.planType != "starter" && (
                    <p className="text-danger fw-bold">
                      {t("dashboardPageNew.trialPeriodEndAt")}{" "}
                      {moment(user.account.trialPeriodEndsAt).format(
                        "DD/MM/YYYY"
                      )}
                    </p>
                  )}
                  <Button
                    variant="contained"
                    onClick={() => history.push("/plan")}
                  >
                    {t("dashboardPageNew.planBtn")}
                  </Button>
                  {/* <Link
									className=' btn btn-large green w-75 text-white font-16 fw-bold'
									to={'/plan'}
								>
									{t('dashboardPageNew.planBtn')}
								</Link> */}
                </div>
              </div>
            </Col>
          )}
          <Col className="col-12 col-md-6 mt-3 mt-md-0">
            <div className="dashboard-leftbox d-flex flex-column  flex-xl-row min-height w-100-perc h-100">
              {/* <img
								className='d-none d-xl-block w-50'
								src='/images/box-1-dashboard.jpg'
							/> */}
              <div className="d-flex  flex-column justify-content-between py-3 px-4 h-100 w-100-perc">
                <TextField
                  sx={{ mt: "10px" }}
                  label="API KEY"
                  fullWidth
                  disabled
                  type={key}
                  value={user.account.apiToken}
                  InputProps={{
                    sx: { height: "40px" },
                    endAdornment: (
                      <>
                        <IconButton size="small">
                          <FontAwesomeIcon
                            icon={key === "password" ? faEye : faEyeSlash}
                            onClick={() => mostraKey()}
                            title={t("dashboardPageNew.showKey")}
                          />
                        </IconButton>
                        <IconButton size="small">
                          <FontAwesomeIcon
                            icon={faCopy}
                            onClick={() => copyApiKey()}
                            title={t("dashboardPageNew.copyKey")}
                          />
                        </IconButton>
                      </>
                    ),
                  }}
                />
                <TextField
                  sx={{ mt: "10px" }}
                  label="EVERYLOG_URL"
                  disabled
                  fullWidth
                  value={LOG_ENTRY_CREATE_URL}
                  InputProps={{
                    sx: { height: "40px" },
                    endAdornment: (
                      <IconButton size="small">
                        <FontAwesomeIcon
                          className="copy-apikey pointer-hover"
                          icon={faCopy}
                          onClick={() => copyEverylogUrl()}
                          title={t("dashboardPageNew.copyKey")}
                        />
                      </IconButton>
                    ),
                  }}
                />
                <span className="fw-bold mt-2 mb-1 font-16">
                  {t("dashboardPageNew.guide")}
                </span>
                <Button
                  variant="contained"
                  onClick={() => openInNewTab("https://docs.everylog.io/")}
                  sx={{ maxWidth: "200px" }}
                >
                  {t("dashboardPageNew.guideButton")}
                </Button>
                {/* <Link
									to='#'
									className=' btn btn-large green w-75 text-white font-16 fw-bold'
									onClick={() => openInNewTab('https://docs.everylog.io/')}
								>
									{t('dashboardPageNew.guideButton')}
								</Link> */}
              </div>
            </div>
          </Col>
        </Row>
      )}
      {user.role === "external" && <h1 className="title-font">Dashboard</h1>}
      <Row className="mx-0 mt-3">
        <div className="dashboard-box">
          {/* table */}
          <>
            <Tabs>
              <Tab
                eventKey="latestNotifications"
                title={t("dashboardPageNew.latestNotifications")}
                onClick={() => setTab(false)}
              >
                {data && data.data.records.length === 0 ? (
                  <div className="position-absolute mt-3 ms-3">
                    <h3 className="font-20 fw-bold">
                      {t("dashboardPage.noNotifications")}
                    </h3>
                  </div>
                ) : (
                  <Table responsive bordered className="mt-3">
                    <thead>
                      <tr className="d-none d-md-table-row">
                        <th className="col-0 "></th>
                        <th className="col-2 text-start font-20">
                          {t("dashboardPageNew.project")}
                        </th>
                        <th className="col-3 text-start font-20">
                          {t("dashboardPageNew.title")}
                        </th>
                        <th className="col-3 text-start font-20">
                          {t("dashboardPageNew.summary")}
                        </th>
                        <th className="col-2 text-start font-20">
                          {t("projectsPage.time")}
                        </th>
                        <th className="col-2 text-start font-20">
                          {t("projectsPage.actions")}
                        </th>
                      </tr>
                    </thead>
                    {data && (
                      <tbody>
                        {data.data.records.map((logEntry, i) => (
                          <tr
                            key={i}
                            className="table-row-color font-14 d-flex flex-column d-md-table-row"
                          >
                            <td>
                              {logEntry.icon && logEntry.icon.length !== 0 ? (
                                <div className="icon-project d-flex align-items-center justify-content-center">
                                  {logEntry.icon}
                                </div>
                              ) : (
                                <div className="icon-project d-flex align-items-center justify-content-center">
                                  <img
                                    width={"40px"}
                                    src={`${
                                      ENTERPRISE
                                        ? ENTERPRISE_IMAGE_MOBILE
                                        : "/images/everylog-16.png"
                                    }`}
                                  />
                                </div>
                              )}
                            </td>
                            <div className="d-md-none font-16 fw-bold pb-0">
                              {t("dashboardPageNew.project")}:
                            </div>
                            <td
                              onClick={() => {
                                history.push(
                                  `/project/logentries/${logEntry.projectId}`
                                );
                              }}
                              className="text-start vertical-align pointer-hover"
                            >
                              {logEntry.projectId}
                            </td>
                            <div className="d-md-none font-16 fw-bold pb-0">
                              {t("dashboardPageNew.title")}:
                            </div>
                            <td
                              onClick={() => {
                                history.push(
                                  `/project/logentries/${logEntry.projectId}`
                                );
                              }}
                              className="text-start vertical-align pointer-hover"
                            >
                              {logEntry.title}
                            </td>
                            <div className="d-md-none font-16 fw-bold pb-0">
                              {t("dashboardPageNew.summary")}:
                            </div>
                            <td
                              onClick={() => {
                                history.push(
                                  `/project/logentries/${logEntry.projectId}`
                                );
                              }}
                              className="text-start vertical-align pointer-hover"
                            >
                              {logEntry.summary}
                            </td>
                            <div className="d-md-none font-16 fw-bold pb-0">
                              {t("projectsPage.time")}:
                            </div>
                            <td
                              onClick={() => {
                                history.push(
                                  `/project/logentries/${logEntry.projectId}`
                                );
                              }}
                              className="text-start vertical-align pointer-hover"
                            >
                              {timeSince(logEntry.createdAt)}
                            </td>
                            <div className="d-md-none font-16 fw-bold pb-0">
                              {t("projectsPage.actions")}:
                            </div>
                            <td className="text-start vertical-align">
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => showModal(logEntry.id)}
                              >
                                {t("projectsPage.display")}
                              </Button>
                              {/* <div
																onClick={() => showModal(logEntry.id)}
																className='text-center details'
															>
																{t('projectsPage.display')}
															</div> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </Table>
                )}
                {/* pages */}
                {data.data.records.length !== 0 && (
                  <div className="d-flex justify-content-between">
                    <select
                      className="select-limit-page"
                      onChange={(e) => {
                        setLogEntriesPL({
                          ...logEntriesPL,
                          limit: e.target.value,
                        });
                        baseFilter.limit = e.target.value;
                        refetchLogEntries();
                      }}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                    <div className="d-flex gap-2 align-items-center">
                      <Stack>
                        <Pagination
                          color="secondary"
                          shape="rounded"
                          count={pages.length}
                          size="large"
                          onChange={(e, value) => {
                            setLogEntriesPL({ ...logEntriesPL, page: value });
                            baseFilter.page = value;
                            baseFilter.limit = logEntriesPL.limit;
                            refetchLogEntries();
                          }}
                        />
                      </Stack>
                    </div>
                  </div>
                )}
              </Tab>
              <Tab
                eventKey="starredNotifications"
                title="Starred notifications"
                onClick={() => setTab(true)}
              >
                <>
                  {starredLogEnties &&
                  starredLogEnties.data.records.length === 0 ? (
                    <div className="position-absolute mt-3 ms-3">
                      <h3 className="font-20 fw-bold">
                        {t("dashboardPage.noNotifications")}
                      </h3>
                    </div>
                  ) : (
                    <Table responsive bordered className="mt-3">
                      <thead>
                        <tr className="d-none d-md-table-row">
                          <th className="col-0 "></th>
                          <th className="col-2 text-start font-20">
                            {t("dashboardPageNew.project")}
                          </th>
                          <th className="col-3 text-start font-20">
                            {t("dashboardPageNew.title")}
                          </th>
                          <th className="col-3 text-start font-20">
                            {t("dashboardPageNew.summary")}
                          </th>
                          <th className="col-2 text-start font-20">
                            {t("projectsPage.time")}
                          </th>
                          <th className="col-2 text-start font-20">
                            {t("projectsPage.actions")}
                          </th>
                        </tr>
                      </thead>
                      {starredLogEnties && (
                        <tbody>
                          {starredLogEnties.data.records.map((logEntry, i) => (
                            <tr
                              className="table-row-color font-14 d-flex flex-column d-md-table-row"
                              key={`project-${i}`}
                            >
                              <td>
                                {logEntry.icon && logEntry.icon.length !== 0 ? (
                                  <div className="icon-project d-flex align-items-center justify-content-center">
                                    {logEntry.icon}
                                  </div>
                                ) : (
                                  <div className="icon-project d-flex align-items-center justify-content-center">
                                    <img
                                      width={"40px"}
                                      src={`${
                                        ENTERPRISE
                                          ? ENTERPRISE_IMAGE_MOBILE
                                          : "/images/everylog-16.png"
                                      }`}
                                    />
                                  </div>
                                )}
                              </td>
                              <div className="d-md-none font-16 fw-bold pb-0">
                                {t("dashboardPageNew.project")}:
                              </div>
                              <td
                                onClick={() => {
                                  history.push(
                                    `/project/logentries/${logEntry.projectId}`
                                  );
                                }}
                                className="text-start vertical-align pointer-hover border-0"
                              >
                                {logEntry.projectId}
                              </td>
                              <div className="d-md-none font-16 fw-bold pb-0">
                                {t("dashboardPageNew.title")}:
                              </div>
                              <td
                                onClick={() => {
                                  history.push(
                                    `/project/logentries/${logEntry.projectId}`
                                  );
                                }}
                                className="text-start vertical-align pointer-hover"
                              >
                                {logEntry.title}
                              </td>
                              <div className="d-md-none font-16 fw-bold pb-0">
                                {t("dashboardPageNew.summary")}:
                              </div>
                              <td
                                onClick={() => {
                                  history.push(
                                    `/project/logentries/${logEntry.projectId}`
                                  );
                                }}
                                className="text-start vertical-align pointer-hover"
                              >
                                {logEntry.summary}
                              </td>
                              <div className="d-md-none font-16 fw-bold pb-0">
                                {t("projectsPage.time")}:
                              </div>
                              <td
                                onClick={() => {
                                  history.push(
                                    `/project/logentries/${logEntry.projectId}`
                                  );
                                }}
                                className="text-start vertical-align pointer-hover"
                              >
                                {timeSince(logEntry.createdAt)}
                              </td>
                              <div className="d-md-none font-16 fw-bold pb-0">
                                {t("projectsPage.actions")}:
                              </div>
                              <td className="text-start vertical-align">
                                <Button
                                  onClick={() => showModal(logEntry.id)}
                                  variant="contained"
                                  color="secondary"
                                >
                                  {t("projectsPage.display")}
                                </Button>
                                {/* <div
																	onClick={() => showModal(logEntry.id)}
																	className='text-center details'
																>
																	{t('projectsPage.display')}
																</div> */}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </Table>
                  )}
                </>
                {/* pages */}
                {starredLogEnties.data.records.length !== 0 && (
                  <div className="d-flex justify-content-between">
                    <select
                      className="select-limit-page"
                      onChange={(e) => {
                        setStarredPL({ ...starredPL, limit: e.target.value });
                        starPageLimit.limit = e.target.value;
                        refetchStarred();
                      }}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                    <div className="d-flex gap-2 align-items-center">
                      <Stack>
                        <Pagination
                          color="secondary"
                          shape="rounded"
                          count={starredPages.length}
                          size="large"
                          onChange={(e, value) => {
                            setStarredPL({ ...starredPL, page: value });
                            starPageLimit.page = value;
                            starPageLimit.limit = starredPL.limit;
                            refetchStarred();
                          }}
                        />
                      </Stack>
                    </div>
                  </div>
                )}
              </Tab>
            </Tabs>
          </>
        </div>
      </Row>
    </div>
  );
}
