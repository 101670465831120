import { atom } from "recoil";

export const selectedPlanState = atom({
  key: "selectedPlanState", // unique ID (with respect to other atoms/selectors)
  default: undefined, // default value (aka initial value)
});

export const id = atom({
  key: "projectId",
  default: "",
});

export const groupId = atom({
  key: "groupId",
  default: undefined,
});

export const activeMenuState = atom({
  key: "activeMenu",
  default: "",
});
